
import React, { useRef, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import * as yup from 'yup';
import { switchOffGooglePopOneModal } from '../../utils/ModalSlice/modalToggleSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Cookies from 'js-cookie';
import { baseUrl } from '../../config';

function GoogleAddOne() {
  const dispatch = useDispatch();
  const getName = useRef(null);
  const getNumber = useRef(null);
  // const [notValidNum,setNotValidNum] = useState(false);
  // const [notValidName,setNotValidName] = useState(false);
  const [show, setShow] = useState(false);

  function openTerms() {
      setShow(true);
  }

  function closeTerms() {
      setShow(false);
      dispatch(switchOffGooglePopOneModal());
      Cookies.set("googlePopModal", true, { expires: 0.5 });

  }

  // const phoneRegExp = /^[0-9]{10}$/; // Modify this regex according to your phone number format
  // const nameRegExp = /^[A-Za-z ]+$/; // Only letters and spaces allowed, modify as needed

  // const validationForm = yup.object().shape({
  //   name: yup.string().required('Name is required').matches(nameRegExp, 'Invalid Name').min(2, 'Name must be at least 2 characters').max(50, 'Name must be at most 50 characters'),
  //   number: yup.string().matches(phoneRegExp, 'Invalid Phone Number').required('Number is required'),
  // });

  const handleSaveForm = async () => {
    try {
      const values = {
        name: getName.current.value,
        number: getNumber.current.value,
      };
      

      // const statusOfData = await validationForm.validate(values, { abortEarly: false });
      // if(statusOfData){
     
        let data = JSON.stringify({
          "name": getName.current.value,
          "phoneNumber": getNumber.current.value
        });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/query/googlePopup`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          dispatch(switchOffGooglePopOneModal());
          Cookies.set("googlePopModal", true, { expires: 0.5 });
        })
        .catch((error) => {
          console.log(error);
        });
        
      // }else{

      // }
      // Validation successful, you can proceed with further processing or form submission
      
    } catch (error) {
      // Validation failed, handle errors
      // console.error('Validation error:', error.errors);
      // for(let i=0;i<error.errors.length;i++){
      //   if(error.errors[i]=="Invalid Phone Number"){
      //       setNotValidNum(true);
      //   }else{  
      //     setNotValidName(true);
      //   }
      // }
    }
  };

  return (
    <div className="fixed overflow-y-auto overflow-x-hidden inset-0 pt-10 max-sm:pt-0 bg-black  z-50 outline-none  bg-opacity-70 flex justify-center items-center">
    <div className='w-full h-fit flex justify-center item-center my-8 bg-transparent max-sm:mt-0 '>
      <div className='w-[80%] flex justify-center item-center max-sm:w-full max-sm:block '>
     
        <div className='w-[70%] bg-gradient-to-b flex-col justify-center from-[#110d2e] via-[#211d33] to-[#36364d] max-sm:w-full shadow-[0px_13px_40px_-15px_black] relative rounded-xl'>
         <span className='absolute top-2 right-4 text-white cursor-pointer z-50 ' onClick={closeTerms}><CancelIcon /></span>
          <h1 className='text-[30px] text-white font-bold px-20 py-4 drop-shadow-lg leading-tight max-sm:px-5'>Need Assistance?</h1>
          <p className='text-[18px] text-white px-20 max-sm:px-5'>Provide your Name & Number for a personalized call-back</p>
          <div className=' px-20 mt-3 max-sm:px-5 '>
            <input ref={getName} type="text" placeholder='Name' className={`w-full p-3 border-2 bg-transparent text-white placeholder-white rounded outline-none`} maxLength={20}/>
          
            <input ref={getNumber} type="text" placeholder='Phone Number' className={`w-full p-3 my-3 border-2 bg-transparent text-white placeholder-white rounded outline-none`} maxLength={15} />
            
            <div className='flex justify-center item-center'>
              <button onClick={handleSaveForm} className='w-[50%] bg-white mb-6 p-2 rounded text-[25px] shadow-md font-bold'>Arrange a call back!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default GoogleAddOne;