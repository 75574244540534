import React from 'react'
import TestimonialCard from './TestimonialCard'
import TestimonialSlider from './TestimonialSlider'

const Testimonial = () => {
    return (
        <div className='py-[4rem] px-3 bg-[#EFEFEF]'>
            <div className='w-full xl:w-[1200px] mx-auto h-full'>
                <div className='flex flex-col items-center w-full gap-8'>
                    <div className='bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-4 rounded-[8px]'>
                        <span className='text-[#fff] font-medium leading-[20px]'>Testimonials</span>
                    </div>
                    <div className='flex flex-col items-center gap-4'>
                        <h2 className='text-[32px] text-center md:text-[40px] text-[#222222] leading-[40px] font-semibold'>Our Customer Reviews</h2>
                        <div>
                            <p className='text-[#6A6A6A] text-center text-[16px] leading-[24px] font-normal'>
                                Driving your dreams to reality with an exquisite fleet of versatile vehicles for <br />
                                unforgettable journeys.
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-wrap w-full'>
                        <TestimonialSlider />
                        {/* <TestimonialCard />
                        <TestimonialCard />
                        <TestimonialCard /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
