import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopularBrand1 from "./brands logo/mazda.png";
import PopularBrand2 from "./brands logo/Kia.png";
import PopularBrand3 from "./brands logo/Volkswagen.png";
import PopularBrand4 from "./brands logo/Mercedes.png";
import PopularBrand5 from "./brands logo/Gac.png";
import PopularBrand6 from "./brands logo/Hyundai.png";
import PopularBrand7 from "./brands logo/Audi.png";
import PopularBrand8 from "./brands logo/Toyota.png";
import PopularBrand9 from "./brands logo/GMC.png";
import PopularBrand10 from "./brands logo/Chevrolet.png";
import PopularBrand11 from "./brands logo/Skoda.png";
import PopularBrand12 from "./brands logo/Exeed.png";
import PopularBrand13 from "./brands logo/MG.png";
import PopularBrand14 from "./brands logo/Fiat.png";
import PopularBrand15 from "./brands logo/Geely.png";
import PopularBrand16 from "./brands logo/Alfa Romeo.png";

let popularBrandArray = [
    {
        brandImg :  PopularBrand1,
        alt:"Mazda_logo"
    },
    {
        brandImg :  PopularBrand2,
        alt:"Kia_logo"
    },
    {
        brandImg :  PopularBrand3,
        alt:"volkswagen_logo"
    },
    {
        brandImg :  PopularBrand4,
        alt:"Mercedes_logo"
    },
    {
        brandImg :  PopularBrand5,
        alt:"Gac_logo"
    },
    {
        brandImg :  PopularBrand6,
        alt:"Hyundai_logo"
    },
    {
        brandImg :  PopularBrand7,
        alt:"Audi_logo"
    },
    {
        brandImg :  PopularBrand8,
        alt:"Toyota_logo"
    },
    {
        brandImg :  PopularBrand9,
        alt:"GMC_logo"
    },
    {
        brandImg :  PopularBrand10,
        alt:"Chevrolet_logo"
    },
    {
        brandImg :  PopularBrand11,
        alt:"Skoda_logo"
    },
    {
        brandImg :  PopularBrand12,
        alt:"Exeed_logo"
    },
    {
        brandImg :  PopularBrand13,
        alt:"MG_logo"
    },
    {
        brandImg :  PopularBrand14,
        alt:"Fiat_logo"
    },
    {
        brandImg :  PopularBrand15,
        alt:"Geely_logo"
    },
    {
        brandImg :  PopularBrand16,
        alt:"Alfa_Romeo_logo"
    },
];


const PopularBrandsSlider = () => {


  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {popularBrandArray?.map((brand, index) => {
          return (
            <div key={index} className="p-3 flex justify-center sm:h-auto h-[160px]">
              <img src={brand.brandImg} className="rounded-[10px] shadow-[0_10px_50px_rgba(0,0,0,0.1)] w-full sm:w-auto h-full"  alt={brand.alt} />
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default PopularBrandsSlider;
