import React, { useEffect, useState } from "react";
import { openAddSliceOne } from "../../utils/addSlices/addSliceOne";
import { useDispatch } from "react-redux";
import NewSearchCarResult from "./NewSearchCarResult";
import { switchOnAddQueryModalOne } from "../../utils/ModalSlice/modalToggleSlice";
import Cookies from "js-cookie";

function AddLandingOne({CarRentalFew}) {
  const dispatch = useDispatch();
  const [limitedCarCard, setLimitedCarCard] = useState(6);

  useEffect(() => {
    dispatch(openAddSliceOne());
  }, []);

  useEffect(() => {
    const hasModalClosed = Cookies.get("modalClosed");

    if (!hasModalClosed) {
      const timer = setTimeout(() => {
        dispatch(switchOnAddQueryModalOne());
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <div>
      <div className="w-full">
        <div className="w-full pl-[11%] pr-[10.27%] my-8">
          <h2 className=" font-semibold text-3xl text-[#0F0A34]">
            Best prices for daily, weekly, and monthly car rentals
          </h2>
        </div>
        <div className="w-full pl-[10.27%] pr-[10.27%] my-8">
          <NewSearchCarResult CarRentalFew={CarRentalFew}/>
        </div>
      </div>
    </div>
  );
}

export default AddLandingOne;
