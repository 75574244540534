import React, { useRef, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";

function RfpPage(props) {
  const [formErrors, setFormErrors] = useState({});
  const userName = useRef(null);
  const userEmail = useRef(null);
  const userPhone = useRef(null);
  const userLocation = useRef(null);
  const userMessage = useRef(null);
  const [open, setOpen] = React.useState(false);
  const navigate =useNavigate()

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const validateForm = () => {
    const errors = {};

    if (!userName.current.value.trim()) {
      errors.name = "Name is required";
    }

    if (!userEmail.current.value.trim()) {
      errors.email = "Email is required";
    } else if (
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
        userEmail.current.value
      )
    ) {
      errors.email = "Invalid email address";
    }

    if (!userPhone.current.value.trim()) {
      errors.phone = "Phone is required";
    }

    if (!userLocation.current.value.trim()) {
      errors.location = "Location is required";
    }

    if (!userMessage.current.value.trim()) {
      errors.message = "Message is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = () => {
    const isValid = validateForm();

    if (isValid) {
      const formSubmit = {
        name: userName.current.value,
        workEmail: userEmail.current.value,
        phone: userPhone.current.value,
        location: userLocation.current.value,
        message: userMessage.current.value,
      };
      let data = {
        fullName: userName.current.value,
        emailID: userEmail.current.value,
        phoneNumber: parseInt(userPhone.current.value),
        location: userLocation.current.value,
        message: userMessage.current.value,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/query/allQuery/${props.apiValue}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          handleClick();
          userName.current.value = null;
          userEmail.current.value = null;
          userPhone.current.value = null;
          userLocation.current.value = null;
          userMessage.current.value = null;
          navigate("/contact/thankyou")
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="w-full  flex justify-center items-center my-8 ">
        <div className="w-[85%] flex max-sm:w-full max-sm:block">
          <div className="w-[65%] max-sm:w-full ">
            <div className=" max-sm:px-4 ">
              <h3 className="text-[25px] font-bold text-black pb-2 pt-8">
                Send us a message
              </h3>
              <div>
                Please fill in the form and we shall send the experts to discuss
                your requirement.
              </div>
            </div>
            <div className=" mt-4  max-sm:px-4">
              <div className="flex justify-center items-center gap-6 ">
                <div className=" w-[50%] flex flex-col items-start mt-6 h-10">
                  <input
                    type="text"
                    ref={userName}
                    className="bg-slate-100 w-full py-2 px-[16px]  outline-gray-200 rounded "
                    placeholder="Name*"
                  />
                  {formErrors.name && (
                    <div className="text-red-500 ">{formErrors.name}</div>
                  )}
                </div>
                <div className=" w-[50%] flex flex-col items-start mt-6 h-10">
                  <input
                    type="text"
                    ref={userEmail}
                    className="bg-slate-100 w-full py-2 px-[16px] outline-gray-200 rounded   "
                    placeholder="Email"
                  />
                  {formErrors.email && (
                    <div className="text-red-500 ">{formErrors.email}</div>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center mt-8 gap-6  ">
                <div className=" w-[50%]  flex flex-col items-start h-10">
                  <input
                    type="text"
                    ref={userPhone}
                    className="bg-slate-100 w-full py-2 px-[16px] outline-gray-200 rounded  "
                    placeholder="Phone*"
                    maxLength={15}
                  />
                  {formErrors.phone && (
                    <div className="text-red-500 ">{formErrors.phone}</div>
                  )}
                </div>
                <div className=" w-[50%]  flex flex-col items-start h-10">
                  <input
                    type="text"
                    ref={userLocation}
                    className="bg-slate-100 w-full py-2 px-[16px] outline-gray-200 rounded  "
                    placeholder="Location"
                  />
                  {formErrors.location && (
                    <div className="text-red-500 ">{formErrors.location}</div>
                  )}
                </div>
              </div>
              <div className="flex justify-center mt-8 items-center">
                <div className="relative w-full ">
                  <input
                    type="text"
                    ref={userMessage}
                    className="bg-slate-100 w-full px-[16px] py-6 outline-gray-200 rounded"
                    placeholder="Your message to us with requirements*"
                  />
                  {formErrors.message && (
                    <div className="absolute left-0 top-12 text-red-500 mt-6 ">
                      {formErrors.message}
                    </div>
                  )}
                </div>
              </div>

              <div className=" mt-2 pb-8">
                <button
                  onClick={handleSubmit}
                  className="py-2 px-6 rounded bg-[#0F0A34] text-white mt-6"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <div className="w-[35%]  flex justify-center items-center pl-8 max-sm:w-full max-sm:pl-0 ">
            <div className="w-full border-l border-slate-500 text-center p-2 max-sm:mb-5 max-sm:border-none ">
              <div>
                {" "}
                <LocationOnIcon className="text-[#0F0A34]" />
              </div>
              <div className="font-semibold">Address</div>
              <div className="text-slate-800">
                Smark 9, Zabeel Business Center{" "}
              </div>
              <div className="text-slate-800"> Building, 302-3 Dubai</div>
              <div className="text-slate-800">United Arab Emirates</div>

              <div className="pt-5">
                <LocalPhoneIcon className="text-[#0F0A34]" />
              </div>
              <div className="font-semibold">Phone</div>
              <div className="text-slate-800">+971 56 909 7524</div>
              <div className="text-slate-800">+971 50 264 1477</div>

              <div className="pt-5 ">
                <EmailIcon className="text-[#0F0A34]" />
              </div>
              <div className="font-semibold">Email</div>
              <div className="text-slate-800">info@wtimobility.ae</div>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%", color: "white" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="primary"
            sx={{ width: "100%", color: "white" }}
          >
            Submitted Successfully
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default RfpPage;
