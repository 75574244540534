import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import TextFieldInput from "../bookingPanel/textInputs/TextFieldInput";
import NewTextFieldInput from "./NewTextFieldInput";
import NewDropFieldInput from "./NewDropFieldInput";
import NewPickDateTime from "./NewPickDateTime";
import NewDropDateTime from "./NewDropDateTime";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import {
  currentDatePlusOne,
  function2,
} from "../../utils/commonFunction/dateTime";
import { saveBookingInfo } from "../../utils/BookingPageSlices/bookingInfoSlice";
import { useDispatch } from "react-redux";
import { switchOnStepTwo } from "../../utils/stepperSlice";
import { setTotalDays } from "../../utils/BookingPageSlices/bookingInfoSlice";
import { pickPlaceFinder } from "../../utils/commonFunction/placeFinder";
import { dropPlaceFinder } from "../../utils/commonFunction/placeFinder";
//-->all redux  clearing imports
import { toggleUpdateItinerary } from "../../utils/ModalSlice/modalToggleSlice";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

import { closeCalender } from "../../utils/BookingPageSlices/formControlSlice";
import format from "date-fns/format";
import {
  savePickDate,
  saveDropDate,
} from "../../utils/BookingPageSlices/bookingInfoSlice";
import { switchOnLoader, switchOffLoader } from "../../utils/loaderSlice";
import RadioBtnCont from "../bookingPanel/RadioBtnCont";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//add dispatches
import { switchOnSearchOnAdd } from "../../utils/addSlices/addSliceOne";
import { checkIfLocationInDubai } from "../../utils/commonFunction/checkBounds";
import { updateCarPickLocation,updateCarDropLocation } from "../../utils/picDropSlices/pickDropSlice";
import { addCarDeliveryCharge } from "../../utils/invoice/invoiceSlice";
import axios from "axios";
import { baseUrl } from "../../config";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function NewInputContainer() {
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [open, setOpen] = React.useState(false);
  const mainPickCity =
  "Zabeel Business Centre (Smark 9) - Umm Hurair Road - Dubai - United Arab Emirates";
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    // Function to update screen size state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add event listener to update screen size on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const locationStatus = useSelector(
    (store) => store.formControlSlice.differentLocation
  );

  const isItiModal = useSelector(
    (store) => store.modalToggleSlice.updateItinerary
  );

  //--> this use effect will disable all the stepper other than one
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();
  const calenderStatus = useSelector(
    (store) => store.formControlSlice.openCalender
  );
  const [pickValue, setPickValue] = useState("");
  const [dropValue, setDropValue] = useState("");
  const todayTimerMinDate = dayjs(currentDatePlusOne());
  const pickupMMDDYYYY = useSelector(
    (store) => store.bookingInfoSlice.pickupMMDDYYYY
  );
  const dropMMDDYYYY = useSelector(
    (store) => store.bookingInfoSlice.dropMMDDYYYY
  );

  const [selectedDateTime, setSelectedDateTime] = useState(
    dayjs(currentDatePlusOne())
  );
  const [selectedDropDateTime, setSelectedDropDateTime] = useState(
    dayjs(function2(todayTimerMinDate))
  );
  const [count, setCount] = useState(0);

  const [selectPickTime, SetSelectPickTime] = useState("");
  const [selectDropTime, SetSelectDropTime] = useState("");

  // ));

  const currentDate = new Date();
  const nextDay = new Date(currentDate);
  const nextToNextDay = new Date(currentDate);
  nextDay.setDate(currentDate.getDate() + 1);
  nextToNextDay.setDate(currentDate.getDate() + 2);

  const [date, setDate] = useState({
    startDate: nextDay,
    endDate: nextToNextDay,
    key: "selection",
  });

  const [pickSelected, setPickSelected] = useState(false);
  const [dropSelected, setDropSelected] = useState(false);

  const pickUpDropStatus = useSelector(
    (store) => store.bookingInfoSlice.needCarDeliveryAndPickupSerivce
  );

  useEffect(() => {
    if (date.startDate != date.endDate) {
      dispatch(savePickDate(format(date.startDate, "MMM,dd,yyyy")));
      dispatch(saveDropDate(format(date.endDate, "MMM,dd,yyyy")));
    } else {
      dispatch(savePickDate(format(date.startDate, "MMM,dd,yyyy")));
      dispatch(saveDropDate(format(date.endDate, "MMM,dd,yyyy")));
    }
  }, [date.startDate, date.endDate]);

  //select options
  const options = [
    { label: "00:30", value: "00:30" },
    { label: "01:00", value: "01:00" },
    { label: "01:30", value: "01:30" },
    { label: "02:00", value: "02:00" },
    { label: "02:30", value: "02:30" },
    { label: "03:00", value: "03:00" },
    { label: "03:30", value: "03:30" },
    { label: "04:00", value: "04:00" },
    { label: "04:30", value: "04:30" },
    { label: "05:00", value: "05:00" },
    { label: "05:30", value: "05:30" },
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
    { label: "09:00", value: "09:00" },
    { label: "09:30", value: "09:30" },
    { label: "10:00", value: "10:00" },
    { label: "10:30", value: "10:30" },
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },
    { label: "13:00", value: "13:00" },
    { label: "13:30", value: "13:30" },
    { label: "14:00", value: "14:00" },
    { label: "14:30", value: "14:30" },
    { label: "15:00", value: "15:00" },
    { label: "15:30", value: "15:30" },
    { label: "16:00", value: "16:00" },
    { label: "16:30", value: "16:30" },
    { label: "17:00", value: "17:00" },
    { label: "17:30", value: "17:30" },
    { label: "18:00", value: "18:00" },
    { label: "18:30", value: "18:30" },
    { label: "19:00", value: "19:00" },
    { label: "19:30", value: "19:30" },
    { label: "20:00", value: "20:00" },
    { label: "20:30", value: "20:30" },
    { label: "21:00", value: "21:00" },
    { label: "21:30", value: "21:30" },
    { label: "22:00", value: "22:00" },
    { label: "22:30", value: "22:30" },
    { label: "23:00", value: "23:00" },
    { label: "23:30", value: "23:30" },
  ];

  const [couponValue, setCouponValue] = useState("");
  const handlePickValue = (value) => {
    setPickValue(value);
  };

  const handleDropValue = (value) => {
    setDropValue(value);
  };

  const handleCoupon = (value) => {
    setCouponValue(value);
  };

  const setSelectedDateTimeFunction = (dt) => {
    const newDate = dayjs(function2(dt));
    setSelectedDropDateTime(newDate);
  };

  const storeRateInfo = async()=>{
    try{
      const reponse = await axios.get(`${baseUrl}/app/v1/rates/getDeliveryCollectionRates`);
    return reponse.data.result[0];

    }catch(error){
      console.log(error);
    }

  }

  const handleBookingSubmission = async () => {

    if(locationStatus==true){
      if(pickValue=="" || dropValue==""){
        handleClick();
        return;
      }
     
    }else{

      if(pickValue==""){
        handleClick();
        return;
      }
    }

    if (pickUpDropStatus == false) {
      dispatch(
        updateCarPickLocation({
          location: pickValue,
          locationRate: 0,
        })
      );
      dispatch(
        updateCarDropLocation({
          location: dropValue,
          locationRate: 0,
        })
      );
      dispatch(addCarDeliveryCharge(0));
    } else {
      const rateObj = await storeRateInfo();
      if (locationStatus == false) {
        //same pickup drop location
        const pickDropLocKeyWord = await checkIfLocationInDubai(pickValue);
        const apiRate = pickValue==mainPickCity?0:rateObj[pickDropLocKeyWord];

        let apiObj = {
          location: pickDropLocKeyWord,
          locationRate: apiRate,
        }

      
        dispatch(updateCarPickLocation(apiObj));
        dispatch(updateCarDropLocation(apiObj));
       
        dispatch(addCarDeliveryCharge(apiRate * 2));
      } else {
        //different pickup drop location
        const pickLocObjKeyWord = await checkIfLocationInDubai(pickValue);
        const dropLocObjKeyWord = await checkIfLocationInDubai(dropValue);
        const pickApiRate = pickValue==mainPickCity?0: rateObj[pickLocObjKeyWord];
        const dropApiRate = dropValue==mainPickCity?0: rateObj[dropLocObjKeyWord];
        let pickRateObj = {
          location: pickLocObjKeyWord,
          locationRate: pickApiRate,
        }
        let dropRateObj = {
          location: dropLocObjKeyWord,
          locationRate: dropApiRate,
        }

        dispatch(updateCarPickLocation(dropRateObj));
        dispatch(updateCarDropLocation(pickRateObj));
        dispatch(
          addCarDeliveryCharge(
            pickApiRate+dropApiRate
          )
        );
      }
    }

    // if(locationStatus==false){
    //   //same pickup drop location
    //   const pickDropLocObj = await checkIfLocationInDubai(pickValue);
    //   dispatch(updateCarPickLocation(pickDropLocObj));
    //   dispatch(updateCarDropLocation(pickDropLocObj));
    //   dispatch(addCarDeliveryCharge(pickDropLocObj.locationRate*2))


    // }else{
    //   //different pickup drop location
    //   const pickLocObj = await checkIfLocationInDubai(pickValue);
    //   const dropLocObj = await checkIfLocationInDubai(dropValue);
    //   dispatch(updateCarPickLocation(dropLocObj));
    //   dispatch(updateCarDropLocation(pickLocObj));
    //   dispatch(addCarDeliveryCharge(pickLocObj.locationRate +dropLocObj.locationRate))
    // }



    let isPickCorrect = await pickPlaceFinder(pickValue);
    if(!isPickCorrect){
      handleClick();
      return;
    }
    let incNoDays = false;
    const prePickTime = selectPickTime.split(":")[0];
    const afterPickTime = selectPickTime.split(":")[1];
    const preDropTime = selectDropTime.split(":")[0];
    const afterDropTime = selectDropTime.split(":")[1];
    parseInt(prePickTime);
    parseInt(preDropTime);
    parseInt(afterPickTime);
    parseInt(afterDropTime);
    if (prePickTime < preDropTime) {
      incNoDays = true;
    } else if (prePickTime == preDropTime) {
      if (afterPickTime < afterDropTime) {
        incNoDays = true;
      }
    }
    //let beenToModal = false; //this tells if the modal is open or not
    let flag = false; // this flag tells weather we want to save the data or not
    let moveToNextPage = false; // this tells weather we want to go the next page or not

    //-------> 1 //// - > this case will only run if the modal is open
    if (isItiModal) {
      //-- if modal is open
      if (locationStatus == true) {
        // if the location is different
        if (pickValue.length > 0 && dropValue.length > 0) {
          // if pick and drop string length are greater than 0
          let res1 = await pickPlaceFinder(pickValue);
          //
          let res2 = await dropPlaceFinder(dropValue);
          //
          if (res1 && res2) {
            // if pick up and drop locations are valid, this validity is checked via gooogle places api
            dispatch(toggleUpdateItinerary()); //--> close the modal
            document.documentElement.style.overflow = "visible"; // make the page scrollable
            flag = true;
          } else {
            // alert("Please enter Correct Pickup and Drop Location");
            handleClick();
          }
        } else {
          // alert("Please enter Pickup and Drop Location");
          handleClick();
        }
      } else {
        if (pickValue.length > 0) {
          // if pick up and drop address are same
          let res1 = await pickPlaceFinder(pickValue);
          //

          //
          if (res1) {
            dispatch(toggleUpdateItinerary()); //--> check if location enters is valid or not
            document.documentElement.style.overflow = "visible";
            flag = true;
          } else {
            // alert("Please enter Correct Pickup and Drop Location");
            handleClick();
          }
        } else {
          // alert("Please enter Pickup and Drop Location");
          handleClick();
        }
      }

      // dispatch(toggleUpdateItinerary());
      // document.documentElement.style.overflow='visible';
      // return;
    }



    //------> 2 // this run one's the home page, no connection with the modal

    if (locationStatus == true && isItiModal == false) {
      // if pick and drop are different
      if (pickValue.length > 0 && dropValue.length > 0) {
        // // if pick and drop string length are greater than 0
        let res1 = await pickPlaceFinder(pickValue);
        //
        let res2 = await dropPlaceFinder(dropValue);
        //
        if (res1 && res2) {
          // if pick up and drop locations are valid, this validity is checked via gooogle places api
          // navigate("/reservation/vehicles");
          flag = true;
          moveToNextPage = true;
        } else {
          // alert("Please enter Correct Pickup and Drop Location");
          handleClick();
        }
      } else {
        // alert("Please enter Pickup and Drop Location");
        handleClick();
      }
    } else if (locationStatus == false && isItiModal == false) {
      if (pickValue.length > 0) {
        let res1 = await pickPlaceFinder(pickValue);
        //

        //
        if (res1) {
          // navigate("/reservation/vehicles");
          flag = true;
          moveToNextPage = true;
        } else {
          // alert("Please enter Correct Pickup and Drop Location");
          handleClick();
        }
      } else {
        // alert("Please enter Pickup and Drop Location");
        handleClick();
      }
    }

    //------> 2
    if (flag) {
      const dValue = new Date(selectedDateTime["$d"]);
      const pValue = new Date(selectedDropDateTime["$d"]);

      // Extract day, date, time, and year
      // Extract month, day, date, time, and year
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthIndex = dValue.getMonth();
      const month = months[monthIndex];
      const day = dValue.toLocaleDateString("en-US", { weekday: "long" }); // Full day name
      const date = dValue.getDate(); // Day of the month (1-31)
      const time = dValue.toLocaleTimeString("en-US", { timeStyle: "short" }); // Short time format
      const year = dValue.getFullYear(); // Full year (e.g., 2023)

      const obj = {
        pickupLocation: pickValue,
        pickupTime: selectPickTime,
        dropLocation: locationStatus == false ? pickValue : dropValue,
        dropTime: selectDropTime,
      };

      dispatch(saveBookingInfo(obj));

      let nDays = getDaysInBetween(pickupMMDDYYYY, dropMMDDYYYY);
      if (incNoDays) {
        nDays++;
      }
      //
      // nDays += 1;

      //set number of days
      dispatch(setTotalDays(parseInt(nDays)));
      // navigate("/reservation/vehicles");
      //state an navigate ---> navigate routes the page to car selection and state holds the data of this page so that
      //on refreshing the data is not lost
    }

    //no use of this in add page, as dont want to move on the next page
    // if (moveToNextPage) {
    //   dispatch(switchOnStepTwo());
    //   navigate("/reservation/vehicles");
    //   dispatch(switchOnLoader());
    // }

    dispatch(switchOnSearchOnAdd());
     //now i have to run a false loader for 1.5 secs
    timerFunction();
  };

  // handleBookingSubmission END



  function timerFunction() {
    // Dispatch switchOnLoader immediately
    dispatch(switchOnLoader());

    // Dispatch switchOffLoader after 1.5 seconds
    setTimeout(function () {
      dispatch(switchOffLoader());
    }, 900);
  }

  function getDaysInBetween(startDateStr, endDateStr) {
    // Parse the start and end dates from their string representations
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;

    // Calculate the difference in days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Round to the nearest whole number to get the number of days
    return Math.round(daysDifference);
  }

  const showInfo = () => {};

  const handleRangeChange = (ranges) => {
    setCount(count + 1);
    if (pickSelected) {
      if (
        new Date(ranges.selection.startDate).getTime() ==
        new Date(ranges.selection.endDate).getTime()
      ) {
        const newDate = new Date(ranges.selection.endDate);
        newDate.setDate(newDate.getDate() + 1);

        ranges.selection.endDate = newDate;
      } else {
        ranges = ranges;
      }
    } else if (dropSelected) {
      if (
        new Date(ranges.selection.startDate).getTime() >
        new Date(date.startDate).getTime()
      ) {
        const newStartDate = new Date(date.startDate);
        const newEndDate = new Date(ranges.selection.startDate);

        ranges.selection.startDate = newStartDate;
        ranges.selection.endDate = newEndDate;
      } else if (
        new Date(ranges.selection.startDate).getTime() <
        new Date(date.startDate).getTime()
      ) {
        const newStartDate = new Date(ranges.selection.startDate);
        const newEndDate = new Date(date.startDate);

        ranges.selection.startDate = newStartDate;
        ranges.selection.endDate = newEndDate;
      } else {
        const newDate = new Date(ranges.selection.endDate);
        newDate.setDate(newDate.getDate() + 1);

        ranges.selection.endDate = newDate;
      }
      dispatch(closeCalender());
      setCount(0);
      setPickSelected(false);
      setDropSelected(false);
    }
    setDate(ranges.selection);
    if (count == 1) {
      dispatch(closeCalender());
      setCount(0);
    }
  };

  const setTimePick = (val) => {
    SetSelectPickTime(val);
  };

  const setTimePickDrop = (val) => {
    SetSelectDropTime(val);
  };

  return (
    <React.Fragment>
      <div
        id="inputValues"
        className="w-full flex justify-between items-end relative max-sm:block "
      >
        <div className="max-sm:mb-2">
          <NewTextFieldInput
            value={pickValue}
            handlePickValue={handlePickValue}
          />
        </div>

        <NewDropFieldInput
          value={dropValue}
          handleDropValue={handleDropValue}
        />
        <div className="hidden max-sm:block mt-2">
          <RadioBtnCont />
        </div>
        <div className="cursor-pointer mt-2">
          <NewPickDateTime
            options={options}
            stDate={date.startDate}
            setTimePick={(e) => setTimePick(e)}
            togglePick={setPickSelected}
            toggleDrop={setDropSelected}
          />
        </div>
        <div className="cursor-pointer mt-2">
          <NewDropDateTime
            options={options}
            endDate={date.endDate}
            setTimePickDrop={(e) => setTimePickDrop(e)}
            togglePick={setPickSelected}
            toggleDrop={setDropSelected}
            dropStatus={dropSelected}
          />
        </div>

        {calenderStatus ? (
          <DateRangePicker
            className="absolute  top-[5rem] left-[23rem] max-sm:left-0 max-sm:top-56 w-10 z-40"
            ranges={[date]}
            onChange={handleRangeChange}
            minDate={nextDay}
            months={screenWidth > 640 ? 2 : 1}
            direction="horizontal"
          />
        ) : (
          <></>
        )}

        {/* <Coupon couponValue={couponValue} setCouponvalue={handleCoupon} /> */}
        <div className="flex items-end h-fit mt-2 max-sm:w-full max-sm:mt-8">
          <Button
            variant="contained"
            className="px-8 py-[12px] bg-[#092C85] font-normal text-base max-sm:w-full"
            onClick={handleBookingSubmission}
            style={{
              textTransform: "none", // Set text to non-capitalized
              border: "1.75px solid white", // Add white border
            }}
          >
            Search
          </Button>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%", color: "white" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%", color: "white" }}
          >
            Please enter Correct Pickup and Drop Location!
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

export default NewInputContainer;
