import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function VerticalSliderOne(props) {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="w-full h-fit mt-4 cursor-pointer border  rounded-md" onClick={() => setShowInfo(!showInfo)}>
      <div className="w-full py-2  bg-[#f7f7f7]">
        <div className="w-full flex justify-between items-center px-4">
          <span className="text-sm font-light text-[#0F0A34] ">
            {props.faqObj.question}
          </span>
          {showInfo ? (
            <KeyboardArrowUpIcon
              className="font-[#474747] cursor-pointer"
              
            />
          ) : (
            <KeyboardArrowDownIcon
              className="font-[#474747] cursor-pointer"
            
            />
          )}
        </div>
        {showInfo?<div className="mt-2 w-full text-left font-light text-xs text-[#0F0A34] px-4">
          {props.faqObj.answer}
        </div>:<></>}
      </div>
    </div>
  );
}

export default VerticalSliderOne;
