import React, { useEffect, useState } from "react";
import EquipmentCard from "./EquipmentCard";
import { useSelector } from "react-redux";
import axios from "axios";
import { switchOnLoader, switchOffLoader } from "../../utils/loaderSlice";
import { useDispatch } from "react-redux";
import { addAllEquipments } from "../../utils/insuranceSlice";
import { baseUrl } from "../../config";

function EquipmentCarCont() {
  const dispatch = useDispatch();
  // const equipmentObject = useSelector((store)=>store.extraSlice.extraMainObj[1])
  const [equipmentObject, setEquipmentObject] = useState([]);
  const [babySeatCheck, setBabySeatCheck] = useState(false);
  const [additionalDriverCheck, setAdditionalDriverCheck] = useState(false);

  const extraObj = useSelector((store) => store.extraSlice.allExtraBooleans);

  useEffect(()=>{
    if(extraObj.babySeatBoolean == true){
      setBabySeatCheck(true);
    }
    if(extraObj.additionalDriverBoolean == true){
      setAdditionalDriverCheck (true);
    }
  },[])

  useEffect(() => {
    dispatch(switchOnLoader());
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/extras/getAllExtras`,
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(switchOffLoader());

        setEquipmentObject(response.data.result);
        dispatch(addAllEquipments(response.data.result));
      })
      .catch((error) => {});
  }, []);

  return (
    <React.Fragment>
      <div className="w-full pt-4">
        <span className="font-semibold leading-5">Equipments</span>
        <div className="grid grid-cols-2 gap-4 mt-2 max-sm:grid-cols-1">
          {/* <EquipmentCard equipmentsObj={equipmentsObj.equipmentOne} img={"gps.png"} />
                    <EquipmentCard equipmentsObj={equipmentsObj.equipmentTwo} img={"childSeat.png"}/> */}
          {/* {equipmentObject.map((e) => (
           <EquipmentCard equipmentsObj={e} sectionType={"Equipments"} babySeatCheck={babySeatCheck} setBabySeatCheck={setBabySeatCheck} additionalDriverCheck={additionalDriverCheck} setAdditionalDriverCheck={setAdditionalDriverCheck}/>
          ))} */}

          {equipmentObject.length ? (
            <>
              <EquipmentCard
                equipmentsObj={equipmentObject[0]}
                sectionType={"Equipments"}
                babySeatCheck={babySeatCheck}
                setBabySeatCheck={setBabySeatCheck}
                // additionalDriverCheck={additionalDriverCheck}
                // setAdditionalDriverCheck={setAdditionalDriverCheck}
                indexValue={2}
              />
              <EquipmentCard
                equipmentsObj={equipmentObject[1]}
                sectionType={"Equipments"}
                // babySeatCheck={babySeatCheck}
                // setBabySeatCheck={setBabySeatCheck}
                additionalDriverCheck={additionalDriverCheck}
                setAdditionalDriverCheck={setAdditionalDriverCheck}
                indexValue={3}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default EquipmentCarCont;
