import React, { useEffect, useRef, useState } from "react";
import MyCustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import { Checkbox } from "@mui/material";
import WtiPickUpHubImg from "../../../images/wti_pickup_hub_img.png";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../../config";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import format from "date-fns/format";
import checkedBox from "../bookings/checked_box.svg";
import { calculateDaysInBetween } from "../../../utils/commonFunction/dateTime";
import {
  addModifyReservationDays,
  addModifyTravelItenary,
} from "../../../utils/modifyBookingSlices/modifyBooking";
import { checkIfLocationInDubai } from "../../../utils/commonFunction/checkBounds";
import {
  updateCarDropLocation,
  updateCarPickLocation,
} from "../../../utils/picDropSlices/pickDropSlice";

const SearchEngineModify = React.memo(() => {
  const dispatch = useDispatch();
  const modifyBookingObj = useSelector(
    (store) => store.modifyBookingSlice.modifyDetails
  );
  const [isDifferentLocationActive, setIsActiveDifferentActive] =
    useState(modifyBookingObj?.travelItenary?.dropLocation ? true : false);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [isOpenAutoComplete, setIsOpenAutoComplete] = useState(false);
  let pickDateTime = modifyBookingObj.travelItenary?.pickupTime ? modifyBookingObj.travelItenary?.pickupTime : "10:10"
  let dropDateTime = modifyBookingObj.travelItenary?.dropTime ? modifyBookingObj.travelItenary?.dropTime : "10:10"
  const [startDateHour, setStartDateHour] = useState(pickDateTime ? pickDateTime?.split(':')[0] : "10");
  const [startDateMinute, setStartDateMinute] = useState(pickDateTime ? pickDateTime?.split(':')[1] : "10");
  const [endDateHour, setEndDateHour] = useState(dropDateTime ? dropDateTime?.split(':')[0] : "10");
  const [endDateMinute, setEndDateMinute] = useState(dropDateTime ? dropDateTime?.split(':')[1] : "10");
  const [pickLocation, setPickLocation] = useState(
    modifyBookingObj?.travelItenary?.pickupLocation
  );
  const modifyReservationDays = useSelector(
    (store) => store.modifyBookingSlice.modifyReservationDays
  );
  const [dropLocation, setDropLocation] = useState(
    modifyBookingObj?.travelItenary?.dropLocation
  );
  const isEnroute = modifyBookingObj?.reservationStatus == 'Enroute' ? true : false;
  const [divFocused, setDivFocused] = useState(false);
  const [activePickLocation, setActivePickLocation] = useState(false);
  const [activeDropLocation, setActiveDropLocation] = useState(false);

  const calenderWrapperRef = useRef();
  const autocomplteWrapperRef = useRef();

  const pickDateRange = modifyBookingObj?.travelItenary?.pickupDate;
  const dropDateRange = modifyBookingObj?.travelItenary?.dropDate;

  const [selectDate, setSelectDateRange] = useState({
    startDate: new Date(pickDateRange),
    endDate: new Date(dropDateRange),
    key: "selection",
  });

  const [arr, setArr] = useState([]);
  const [text, setText] = useState("");

  const [pickUpDate, setPickUpDate] = useState({
    startDate: modifyBookingObj?.travelItenary?.pickupDate,
    startTime: modifyBookingObj?.travelItenary?.pickupTime,
    key: "selection",
  });
  const [dropDate, setDropDate] = useState({
    startDate: modifyBookingObj?.travelItenary?.dropDate,
    startTime: modifyBookingObj?.travelItenary?.dropTime,
    key: "selection",
  });

  const mainPickCity =
    "Zabeel Business Centre (Smark 9) - Umm Hurair Road - Dubai - United Arab Emirates";



  useEffect(() => {
    const debounceId = setTimeout(() => {
      if ((pickLocation !== "" || dropLocation !== "") && text) {
        getLocationHandler(text);
      }
    }, 500);
    return () => {
      clearTimeout(debounceId);
    };
  }, [text]);

  useEffect(() => {
    if (selectDate.startDate && selectDate.endDate) {
      const days = calculateDaysInBetween(
        selectDate.startDate,
        selectDate.endDate
      );
      if (days) {
        dispatch(addModifyReservationDays(days));
      }
    }
  }, [selectDate.startDate, selectDate.endDate]);


  useEffect(() => {
    if (pickLocation == "") {
      dispatch(
        updateCarPickLocation({
          location: "",
          locationRate: 0,
        })
      );
    }

    if (dropLocation == "") {
      dispatch(
        updateCarDropLocation({
          location: "",
          locationRate: 0,
        })
      );
    } 
    
    


    if (!isDifferentLocationActive && pickLocation !== "") {
      const fetchData = async () => {
        const checkLocation = await checkIfLocationInDubai(pickLocation);
        const deliveryCharge = await getDeliveryRates(checkLocation);
   
        setDropLocation(pickLocation)
        if(pickLocation == mainPickCity){
          dispatch(
            updateCarDropLocation({
              location: pickLocation,
              locationRate: 0,
            })
          );
        } else {
        dispatch(
          updateCarDropLocation({
            location: pickLocation,
            locationRate: deliveryCharge,
          })
        );
      }
      };
      fetchData();
    }
  }, [pickLocation, dropLocation, isDifferentLocationActive]);

  const getDeliveryRates = async (location) => {
    try {
      const storeData = await storeRateInfo();
      if (storeData) {
          if (location == "within_dubai") {
            return storeData?.within_dubai;
          } else if (location == "within_abudabhi") {
            return storeData?.within_abudabhi;
          } else if (location == "within_sharjah") {
            return storeData?.within_sharjah;
          } else {
            return storeData?.other_locations;
          }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const storeRateInfo = async () => {
    try {
      const reponse = await axios.get(
        `${baseUrl}/app/v1/rates/getDeliveryCollectionRates`
      );
      return reponse.data.result[0];
    } catch (error) {
      console.log(error);
    }
  };

  const setFullPickLocation = async (name) => {
    setPickLocation(name);
    setDivFocused(false);
    setActivePickLocation(false);
    setArr([]);

    try {
      const checkLocation = await checkIfLocationInDubai(name);
      const deliveryCharge = await getDeliveryRates(checkLocation);

      if(name == mainPickCity){
        dispatch(
          updateCarPickLocation({
            location: name,
            locationRate: 0,
          })
        );
      } else {
        dispatch(
          updateCarPickLocation({
            location: name,
            locationRate: Number(deliveryCharge),
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };


  const setFullDropLocation = async (name) => {
    setDropLocation(name);
    setDivFocused(false);
    setActiveDropLocation(false);
    setArr([]);

    try {
      const checkLocation = await checkIfLocationInDubai(name);
      const deliveryCharge = await getDeliveryRates(checkLocation);
      if (name == mainPickCity) {
        dispatch(
          updateCarDropLocation({
            location: name,
            locationRate: 0,
          })
        );
      } else {
        dispatch(
          updateCarDropLocation({
            location: name,
            locationRate: Number(deliveryCharge),
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocationHandler = async (givenLocation) => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/google/:${givenLocation}`,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      };
      if (givenLocation) {
        axios
          .request(config)
          .then((response) => {
            let nArr = [];
            for (let i = 0; i < response.data.predictions.length; i++) {
              let str = response.data.predictions[i].description;
              nArr.push(str);
            }

            nArr.unshift(mainPickCity);
            setArr(nArr);
          })
          .catch((error) => {});
      }
    } catch (error) {
      console.error("Google API Error : " + error);
    }
  };

  const myHandleClickOutSide = (event) => {
    if (
      calenderWrapperRef.current &&
      !calenderWrapperRef.current.contains(event.target)
    ) {
      setIsCalenderOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("dblclick", myHandleClickOutSide, true);
    return () => {
      document.removeEventListener("click", myHandleClickOutSide, false);
    };
  }, []);

  const clickOutSideOfAutoCompleteHandler = (event) => {
    if (
      autocomplteWrapperRef.current &&
      !autocomplteWrapperRef.current.contains(event.target)
    ) {
      setIsOpenAutoComplete(false);
      setActiveDropLocation(false);
      setActivePickLocation(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickOutSideOfAutoCompleteHandler, true);

    return () => {
      document.removeEventListener(
        "click",
        clickOutSideOfAutoCompleteHandler,
        false
      );
    };
  }, []);

  useEffect(() => {
    const travelItenaryObj = {
      pickupLocation: pickLocation,
      pickupDate: selectDate.startDate
        ? format(selectDate.startDate, "MMM,dd,yyyy")
        : "",
      pickupTime: `${startDateHour}:${startDateMinute}`,
      dropLocation: isDifferentLocationActive ? dropLocation : pickLocation,
      dropDate: selectDate.endDate
        ? format(selectDate.endDate, "MMM,dd,yyyy")
        : "",
      dropTime: `${endDateHour}:${endDateMinute}`,
    };
    dispatch(addModifyTravelItenary(travelItenaryObj));
  }, [
    pickLocation,
    dropLocation,
    selectDate.startDate,
    selectDate.endDate,
    startDateHour,
    startDateMinute,
    endDateHour,
    endDateMinute,
    isDifferentLocationActive
  ]);



  return (
    <>
      <div className="w-full flex flex-col gap-2 py-4 px-3 bg-[#fff]  rounded-[12px] relative sm:mt-[0px] mt-[16px]">
        <div className="flex gap-2 items-center">
          <Checkbox
            checked={isDifferentLocationActive}
            onChange={(e) => setIsActiveDifferentActive(e.target.checked)}
          />
          <span className="text-[#898989] text-[16px] font-medium leading-[16px]">
            Return car to a different location
          </span>
        </div>
          <div className="pl-2 text-[#898989] leading-[16px]">Reservation Days: {modifyReservationDays}</div>
        <div className="w-full flex flex-wrap flex-col lg:flex-row gap-3">
          <div className="lg:w-6/12 w-full relative">
            <div
              className={`grid  md:grid-cols-${
                isDifferentLocationActive ? "2" : "1"
              } grid-cols-1  gap-2`}
            >
              <div className=" bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]">
                <div className="rounded-[12px] flex flex-col gap-[4px] relative">
                  <span className="text-[16px] text-[#898989] font-normal">
                    {isDifferentLocationActive
                      ? "Pickup location"
                      : "Pickup & Drop-off location"}
                  </span>
                  <input
                    type="text"
                    className="bg-transparent border-none focus:outline-none font-normal text-[18px]"
                    value={isEnroute ? modifyBookingObj?.travelItenary?.pickupLocation : pickLocation}
                    placeholder="Search here"
                    onChange={(event) => {
                      setPickLocation(event.target.value);
                      setText(event.target.value);
                      setIsOpenAutoComplete(true);
                      setActivePickLocation(true);
                    }}
                    onFocus={() => {
                      setDivFocused(true);
                      setActivePickLocation(true);
                      setActiveDropLocation(false);
                      setIsOpenAutoComplete(true);
                    }}
                  />
                  <RxCross2
                    className="hover:cursor-pointer absolute right-0 text-[24px]"
                    onClick={() => {
                      if(!isEnroute){
                        setPickLocation("");
                        setActivePickLocation(false);
                        setArr([]);
                      }
                    }}
                  />
                </div>
                {arr.length > 1 && divFocused && activePickLocation && (
                  <div
                    className="absolute top-[90px] z-[99] overflow-y-scroll shadow-[0px_3px_30px_0px_#00000029] h-[360px] bg-white py-4 rounded-[12px]"
                    id="search-engine"
                    ref={autocomplteWrapperRef}
                  >
                    {arr.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col py-2 px-3 hover:cursor-pointer w-full hover:bg-[#e7e7e7]"
                          onClick={() => setFullPickLocation(ele)}
                        >
                          <div className="flex items-center  gap-4 w-full">
                            <img
                              src={WtiPickUpHubImg}
                              className="w-[90px] rounded-[12px] shadow-[0px_3px_20px_0px_#55555529]"
                              alt=""
                            />

                            <div className="">
                              <p className="text-[#222222] text-wrap text-[16px] font-normal leading-[24px]">
                                {ele}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {isDifferentLocationActive ? (
                <>
                  <div className=" bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]">
                    <div className="rounded-[12px] flex flex-col gap-[4px] relative">
                      <span className="text-[16px] text-[#898989] font-normal">
                        Drop-off location
                      </span>
                      <input
                        type="text"
                        className="bg-transparent border-none focus:outline-none font-normal text-[18px]"
                        value={dropLocation}
                        placeholder="Search here"
                        onChange={(event) => {
                          setDropLocation(event.target.value);
                          setText(event.target.value);
                          setIsOpenAutoComplete(true);
                          setActiveDropLocation(true);
                        }}
                        onFocus={() => {
                          setDivFocused(true);
                          setActiveDropLocation(true);
                          setActivePickLocation(false);
                          setIsOpenAutoComplete(true);
                        }}
                      />
                      <RxCross2
                        className="hover:cursor-pointer absolute right-0 text-[24px]"
                        onClick={() => {
                          setDropLocation("");
                          setActivePickLocation(false);
                          setArr([]);
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {arr.length > 1 &&
                divFocused &&
                activeDropLocation &&
                isOpenAutoComplete && (
                  <div
                    className="absolute top-[90px] z-[99] overflow-y-scroll shadow-[0px_3px_30px_0px_#00000029] h-[360px] bg-white py-4 rounded-[12px]"
                    id="search-engine"
                    ref={autocomplteWrapperRef}
                  >
                    {arr.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col py-2 px-3 hover:cursor-pointer w-full hover:bg-[#e7e7e7]"
                          onClick={() => setFullDropLocation(ele)}
                        >
                          <div className="flex items-center  gap-4 w-full">
                            <img
                              src={WtiPickUpHubImg}
                              className="w-[90px] rounded-[12px] shadow-[0px_3px_20px_0px_#55555529]"
                              alt=""
                            />

                            <div className="">
                              <p className="text-[#222222] text-wrap text-[16px] font-normal leading-[24px]">
                                {ele}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
          <div className="grow">
            <div className="w-full flex-wrap flex gap-3 relative">
              <div
                className="grow bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]"
                onClick={() => setIsCalenderOpen(!isCalenderOpen)}
              >
                <div className="rounded-[12px] flex flex-col gap-[4px]">
                  <span className="text-[16px] text-[#898989] font-normal">
                    Pickup date & time
                  </span>
                  <div>
                    <span className="font-normal text-[18px] text-[#222222]">
                      {selectDate.startDate
                        ? format(selectDate.startDate, "dd,MMM,yyyy")
                        : ""}
                      {", "}
                    </span>
                    <span className="font-normal text-[16px] text-[#0077B6]">
                      {startDateHour?.length == 1
                        ? `0${startDateHour}`
                        : startDateHour}
                      {" : "}
                      {startDateMinute?.length == 1
                        ? `0${startDateMinute}`
                        : startDateMinute}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="grow bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]"
                onClick={() => setIsCalenderOpen(!isCalenderOpen)}
              >
                <div className="rounded-[12px] flex flex-col gap-[4px]">
                  <span className="text-[16px] text-[#898989] font-normal">
                    Drop-off date & time
                  </span>
                  <div>
                    <span className="font-normal text-[18px] text-[#222222]">
                      {selectDate.endDate
                        ? format(selectDate.endDate, "dd,MMM,yyyy")
                        : ""}
                      {", "}
                    </span>
                    <span className="font-normal text-[16px] text-[#0077B6]">
                      {endDateHour?.length == 1
                        ? `0${endDateHour}`
                        : endDateHour}
                      {" : "}
                      {endDateMinute?.length == 1
                        ? `0${endDateMinute}`
                        : endDateMinute}
                    </span>
                  </div>
                </div>
              </div>
              {isCalenderOpen && (
                <div
                  className="absolute -top-[12px] sm:w-full rounded-[20px] -left-6 z-50"
                  ref={calenderWrapperRef}
                >
                  <div className="bg-[#fff]">
                    <MyCustomDatePicker
                      selectDate={selectDate}
                      setSelectDateRange={setSelectDateRange}
                      isCalenderOpen={isCalenderOpen}
                      setIsCalenderOpen={setIsCalenderOpen}
                      setStartDateHour={setStartDateHour}
                      setStartDateMinute={setStartDateMinute}
                      setEndDateHour={setEndDateHour}
                      setEndDateMinute={setEndDateMinute}
                      pickUpDate={pickUpDate}
                      setPickUpDate={setPickUpDate}
                      dropDate={dropDate}
                      setDropDate={setDropDate}
                      endDateMinute={endDateMinute}
                      endDateHour={endDateHour}
                      startDateMinute={startDateMinute}
                      startDateHour={startDateHour}
                      calenderWrapperRef={calenderWrapperRef}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex">
          <img src={checkedBox} className="px-3"></img>
          <span className="text-[14px] text-[#6A6A6A]">Driver aged 21+</span>
        </div>
      </div>
    </>
  );
});

export default SearchEngineModify;
