import React, { useRef } from "react";
import { Input } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch } from "react-redux";
import { adminUserSave } from "../../utils/loginSlices/loginSlice";
import { useNavigate } from "react-router-dom";
import Form from "./components/BlogUploadForm";
import AllBlogs from "./components/AllBlogs";

function AdminBlog() {
  const [reservationStatusState, setReservationStatusState] =
    React.useState("Favourites");
  const [filterValue, setFilterValue] = React.useState("");
  const [dropdown, setDropdown] = React.useState(false);

  const reservationStatusArr = [
    "Add Blog",
    "All Blogs",
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputSearch = useRef("");

  const handleSearch = () => {
    const data = inputSearch.current.value;
    setFilterValue(data);
  };

  const handleReservationStatus = (value) => {
    setReservationStatusState(value);
  };

  const handleLogout = () => {
    dispatch(adminUserSave(""));
    navigate("/login/admin");
  };

  return (
    <>
      <div
        className={`w-full`}
      >
        <div className="w-full flex items-center justify-between px-[5.27%] py-12 bg-[#0f0a34]">
          <div className="text-3xl font-bold text-white">Blog Admin Page</div>
          <div className="flex justify-between w-[40%]">
            <div className="rounded w-[90%]">
              <Input
                placeholder="Search"
                
                className="bg-white w-full rounded-lg border-none focus-visible:border-none p-2"
                inputRef={inputSearch}
                onChange={handleSearch}
              />
            </div>
            <div
              onClick={handleLogout}
              className="text-white underline cursor-pointer w-[5%] flex items-center justify-center"
            >
              Logout
            </div>
          </div>
        </div>
        <div className="flex max-sm:block bg-[#0f0a34]">
          <div className="w-[15%] max-sm:flex px-4 pb-4 text-white ">
            <div
              onClick={() => {
                handleReservationStatus("Favourites");
                setDropdown(!dropdown);
              }}
              className={`mb-2 flex justify-between items-center text-sm text-center cursor-pointer p-2 rounded-xl hover:shadow-2xl hover:bg-[#edf4fb] hover:text-black ${
                reservationStatusState == "Favourites"
                  ? "shadow-2xl bg-[#edf4fb] text-black"
                  : ""
              }`}
            >
              Blogs{" "}
              <span onClick={() => setDropdown(!dropdown)}>
                {dropdown ? <RemoveIcon /> : <AddIcon />}
              </span>
            </div>
            {dropdown ? (
              reservationStatusArr.map((item) => (
                <div
                  onClick={() => handleReservationStatus("" + item + "")}
                  className={`my-2 ml-3 text-center text-sm cursor-pointer p-2 rounded-xl hover:shadow-2xl hover:bg-[#edf4fb] hover:text-black ${
                    reservationStatusState == item
                      ? "shadow-2xl bg-[#edf4fb] text-black"
                      : ""
                  }`}
                >
                  {item}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <div className="w-[85%] h-[400px] overflow-y-auto rounded-lg p-4 mr-2 mb-4 bg-white">
          {
            reservationStatusState == "All Blogs" ? <><AllBlogs /></> : reservationStatusState == "Add Blog" ? 
                <Form />
          : <></>
          }
           </div>
        </div>
      </div>
    </>
  );
}

export default AdminBlog;
