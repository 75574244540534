import React from "react";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { FormControlLabel, Checkbox } from "@mui/material";
import { removeExtra, addExtra } from "../../utils/extraSlice";
import { useDispatch } from "react-redux";
import {
  addDepositPremiumCheck,
  addExcessInsurance,
  addExtras,
  addFullDepositCheck,
  removeDepositPremiumCheck,
  removeExtras,
  removefullDepositCheck,
} from "../../utils/invoice/invoiceSlice";
import { useLocation } from "react-router-dom";

function EquipmentCard(props) {
  const {
    setDepositcheck,
    equipmentsObj,
    sectionType,
    depositcheck,
    indexValue,
    babySeatCheck,
    setBabySeatCheck,
    additionalDriverCheck,
    setAdditionalDriverCheck,

    currentDepositPremiumState,
    setDepositPremiumState,
    currentFullyDepositState,
    setFullyDepositState,
  } = props;
  //
  //
  //
  const { state } = useLocation();
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  const tNumDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );

  const extraObj = useSelector((store) => store.extraSlice.allExtraBooleans);

  const returnPerfectPrice = (selectedProps) => {

    const calculatedPrice = parseFloat(
      (selectedProps?.equipmentsObj?.price.daily).toFixed(2)
    );

    if (
      calculatedPrice * tNumDays >=
        selectedProps?.equipmentsObj?.price.maximum &&
      selectedProps?.equipmentsObj?.title !== "Additional Driver"
    ) {
      return `${selectedProps?.equipmentsObj?.price.maximum}`;
    } else if (selectedProps?.equipmentsObj?.title === "Additional Driver") {
      return `${calculatedPrice}`;
    } else {
      return `${calculatedPrice * tNumDays}`;
    }
  };

  const calculatePrice = (indexValue,days)=>{
    if(indexValue == 2){
      if(days>=7 && days<=21){
        let actualPrice = props?.equipmentsObj?.price.daily-10;
        let totalPrice = actualPrice*days;
        if(totalPrice<=props?.equipmentsObj?.price.maximum){
          return parseFloat((totalPrice).toFixed(2));
        }else{

          return parseFloat((props?.equipmentsObj?.price.maximum).toFixed(2));
        }
        
      }else if (days>=30){
        let totalMonths = Math.floor(days / 30); // Extract whole months
        let remainingDays = days % 30; 
        let monthsPrice = parseFloat((props?.equipmentsObj?.price.maximum).toFixed(2))*totalMonths;
        let daysPrice = remainingDays*(props?.equipmentsObj?.price.daily-10);
        let total = monthsPrice+daysPrice
        return parseFloat((total).toFixed(2));
      } else {
        if(days>21 && days<30){
        return parseFloat((props?.equipmentsObj?.price.maximum).toFixed(2));
        }else{
          let totalPrice = props?.equipmentsObj?.price.daily*days;
          return parseFloat((totalPrice).toFixed(2));
        }
      
      
      }
    }
    if(indexValue == 3){
      if(days>30 && days<61){
        let daysRemainigFromMonth = days-30;
        let remainingDaysRate = daysRemainigFromMonth*1.3;
        let finalPrice = remainingDaysRate+props?.equipmentsObj?.price.daily;
        return parseFloat((finalPrice).toFixed(2));
      }
      if(days>=30){
        let totalMonths = days/30;
        return (parseFloat((props?.equipmentsObj?.price.daily).toFixed(2))*totalMonths);
      }else{
        return parseFloat((props?.equipmentsObj?.price.daily).toFixed(2));
      }
    }

    if(indexValue == 0 || indexValue == 1){
      return parseFloat((props?.equipmentsObj?.price.daily).toFixed(2));
    }
  }

  const handleEquipment = (insuranceData, extraPrice) => {
    if (insuranceData.sectionType == "Deposit") {
      dispatch(addExcessInsurance(insuranceData.equipmentsObj.price["daily"]));
      if (indexValue == 0) {
        dispatch(addFullDepositCheck());
        dispatch(removeDepositPremiumCheck());
      } else if (indexValue == 1) {
        dispatch(addDepositPremiumCheck());
        dispatch(removefullDepositCheck());
      }
      setDepositcheck(true);
    } else {
      extraPrice = parseFloat(extraPrice);

      let finalPrice = calculatePrice(indexValue ,tNumDays)

      if (indexValue == 2) {
        //remove
        setBabySeatCheck(!babySeatCheck);
        if (babySeatCheck == false && indexValue == 2) {
          dispatch(addExtras(finalPrice));
          dispatch(addExtra(insuranceData.equipmentsObj.id + "E"));
        } else if (babySeatCheck == true && indexValue == 2) {
          dispatch(removeExtras(finalPrice));
          dispatch(removeExtra(insuranceData.equipmentsObj.id + "E"));
        }
      } else {
        setAdditionalDriverCheck(!additionalDriverCheck);
        if (additionalDriverCheck == false && indexValue == 3) {
          dispatch(addExtras(finalPrice));
          dispatch(addExtra(insuranceData.equipmentsObj.id + "E"));
        } else if (additionalDriverCheck == true && indexValue == 3) {
          dispatch(removeExtras(finalPrice));
          dispatch(removeExtra(insuranceData.equipmentsObj.id + "E"));
        }

        // dispatch(addExtras(extraPrice));
        // dispatch(addExtra(insuranceData.equipmentsObj.id + "E"));
      }

      // setCheck(!check);
    }
    // if(currentState){
    //   dispatch(addExcessInsurance())
    // }
    // if (
    //   insuranceData.sectionType == "Deposit" &&
    //   (currentDepositPremiumState == true || currentFullyDepositState == true)
    // ) {
    //   setDepositcheck(true);
    // }
  };

  return (
    <React.Fragment>
      <div
        className={`shadow-xl ${
          depositcheck == "Deposit"
            ? "border-white"
            : depositcheck == false
            ? "border-red-500"
            : ""
        } border rounded-md mb-4 cursor-pointer hover:border hover:border-black bg-white`}
      >
        <div className="icon flex">
          <div className="img flex justify-center items-center  pl-4 pr-4 ">
            <img
              src={require("./" + props?.equipmentsObj?.img + ".svg")}
              alt=""
              className="border rounded-full max-w-sm "
            />
          </div>
          <div className="flex flex-col justify-center items-start pt-6  pb-2 pr-4 pl-4">
            <span className="font-semibold text-sm mb-2">
              {props?.equipmentsObj?.title}
            </span>
            <span className="text-[12px] font-normal leading-4">
              {props?.equipmentsObj?.description}
            </span>
          </div>
        </div>
        <div className="check flex pl-8 pb-2">
          <FormControlLabel
            // The first condition checks if calculatedPrice is greater than or equal to the maximum price (props?.equipmentsObj?.price.maximum). If true, and the equipment title is not "Additional Driver," it returns a formatted string with the maximum price.

            // The second condition checks if the equipment title is "Additional Driver." If true, it returns a formatted string with the calculated price per day for an additional driver.

            // If neither of the above conditions is true, it falls into the else block and returns a formatted string with the calculated price per day for the equipment.

            label={
              <span className="text-sm font-semibold text-[#092C85] leading-4">
                {(() => {
                  
                  if (indexValue === 2 || indexValue === 3 || indexValue == 0 || indexValue == 1) {
                    const calculatedPrice = calculatePrice(indexValue, tNumDays);
                    // return `${currencyName} ${(
                    //       calculatedPrice * currencyPricede
                    //     ).toFixed(2)} - ${tNumDays} DAY`;
                    return `${currencyName} ${Number((calculatedPrice*currencyPrice).toFixed(2))} - ${tNumDays} DAY`;
                  }

            
                  // const calculatedPrice =
                  //   parseFloat((props?.equipmentsObj?.price.daily).toFixed(2)) *
                  //   tNumDays;

                  //   if(calculatedPrice >= props?.equipmentsObj?.price.maximum &&
                  //       props?.equipmentsObj?.title !== "Additional Driver"){

                  //   }

                  // if (
                  //   calculatedPrice >= props?.equipmentsObj?.price.maximum &&
                  //   props?.equipmentsObj?.title !== "Additional Driver"
                  // ) {
                  //   return `${currencyName} ${(
                  //     props?.equipmentsObj?.price.maximum * currencyPrice
                  //   ).toFixed(2)} - ${tNumDays} DAY`;
                  // } else if (
                  //   props?.equipmentsObj?.title === "Additional Driver"
                  // ) {
                  //   return `${currencyName} ${(
                  //     (calculatedPrice / tNumDays) *
                  //     currencyPrice
                  //   ).toFixed(2)} - ${tNumDays}  DAY`;
                  // } else {
                  //   return `${currencyName} ${(
                  //     (calculatedPrice / tNumDays) *
                  //     currencyPrice
                  //   ).toFixed(2)} - ${tNumDays}  DAY`;
                  // }
                  
                })()}

                
              </span>
            }
            control={
              sectionType == "Deposit" ? (
                <Checkbox
                  // checked={
                  //   // count == 0 ? false : (indexValue == 0) ? depositcheck : !depositcheck
                  //   count == 0 ? false : (indexValue == 0) ? depositcheck : !depositcheck
                  // }
                  // onChange={() => {
                  //   let selPrice = returnPerfectPrice(props);
                  //   setCount(count+1)
                  //   selPrice = parseFloat(selPrice);
                  //   handleEquipment(props, selPrice, props.equipmentsObj.type);
                  // }}

                  checked={
                    indexValue == 0
                      ? currentFullyDepositState
                      : indexValue == 1
                      ? currentDepositPremiumState
                      : false
                  }
                  onChange={() => {
                    if (indexValue == 0) {
                      setDepositPremiumState(false);
                      setFullyDepositState(true);
                    } else {
                      setDepositPremiumState(true);
                      setFullyDepositState(false);
                    }

                    let selPrice = returnPerfectPrice(props);
                    selPrice = parseFloat(selPrice);
                    handleEquipment(props, selPrice);
                  }}
                />
              ) : (
                <Checkbox
                  checked={
                    indexValue == 2
                      ? babySeatCheck
                      : indexValue == 3
                      ? additionalDriverCheck
                      : false
                  }
                  onChange={() => {
                    let selPrice = returnPerfectPrice(props);
                    selPrice = parseFloat(selPrice);
                    handleEquipment(props, selPrice);
                  }}
                />
              )
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default EquipmentCard;
