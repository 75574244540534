import { createSlice } from "@reduxjs/toolkit";

const extraSlice = createSlice({
  name: "extraSlice",
  initialState: {
    extraMainObj: [
      [
        {
          id: "1",
          name: "CDW",
          title: "Collision Damage Waiver (CDW)",
          checked: false,
          img: "",
          description:
            "Your solid back-up plan. With CDW, you are protected if your personal insurance or credit card doesn't cover you for losses from a stolen or damaged rental.",
          price: 50,
        },
        {
          id: "2",
          name: "Personal Accident Insurance (PAI)",
          title: "Personal Accident Insurance (PAI)",
          checked: false,
          img: "",
          description:
            "Sleep soundly. With PAI, you're protected from worst-case scenarios after a covered accident.",
          price: 4.08,
        },
      ],
      [
        {
          id: "3",
          name: "GPS Navigation (GPS)",
          title: "GPS Navigation (GPS)",
          checked: false,
          img: "gps.png",
          description:
            "Takes the guesswork out of where you're going, with a GPS Navigation.",
          price: 50,
        },
        {
          id: "4",
          name: "Child Safety Seat",
          title: "Child Safety Seat",
          checked: false,
          img: "childSeat.png",
          description:
            "Keep kids safe when travelling with our child safety seats.",
          price: 4.08,
        },
        {
          id: "5",
          name: "GPS Navigation (GPS)",
          title: "GPS Navigation (GPS)",
          checked: false,
          img: "gps.png",
          description:
            "Takes the guesswork out of where you're going, with a GPS Navigation.",
          price: 50,
        },
        {
          id: "6",
          name: "Child Safety Seat",
          title: "Child Safety Seat",
          checked: false,
          img: "childSeat.png",
          description:
            "Keep kids safe when travelling with our child safety seats.",
          price: 4.08,
        },
      ],
    ],

    extraArr: [],
    totalExtraSelected:[],
    allExtraBooleans:{
      cdwBoolean:false,
      babySeatBoolean:false,
      additionalDriverBoolean:false,
    }
  },
  reducers: {
    // alterAllExtraBooleans:(state,action)=>{

    // }
    removeExtra: (state, action) => {
      let removeIndex = -1;
      for (let i = 0; i < state.extraArr.length; i++) {
        if (state.extraArr[i] == action.payload.id) {
          removeIndex = i;
          break;
        }
      }
      state.extraArr.splice(removeIndex, 1);
      if(action.payload == '01C'){
        state.allExtraBooleans.cdwBoolean = false;
      }else if(action.payload == '03E'){
        state.allExtraBooleans.babySeatBoolean = false
      }else if(action.payload == '05E'){
        state.allExtraBooleans.additionalDriverBoolean = false
      }

    },
    addExtra: (state, action) => {
      state.extraArr.push(action.payload);
      if(action.payload == '01C'){
        state.allExtraBooleans.cdwBoolean = true;
      }else if(action.payload == '03E'){
        state.allExtraBooleans.babySeatBoolean = true;
      }else if(action.payload == '05E'){
        state.allExtraBooleans.additionalDriverBoolean = true;
      }
    },
    emptyExtraArr:(state,action)=>{
      state.extraArr.length = 0;
    },
    addTotalExtras:(state,action)=>{
      state.totalExtraSelected = action.payload;
    },
    removeTotalExtras:(state,action)=>{
      state.totalExtraSelected = [];
    }
  },
});

export const { removeExtra, addExtra,emptyExtraArr,addTotalExtras,removeTotalExtras } = extraSlice.actions;

export default extraSlice.reducer;
