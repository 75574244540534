import React, { useEffect, useState } from "react";
import SubscriptionFilters from "./SubscriptionFilters";
import SubscriptionHeading from "./SubscriptionHeading";
import SubscriptionGrid from "./SubscriptionGrid";
import { useSelector } from "react-redux";
import PopularBrandsSliderWrapper from "./PopularBrandsSliderWrapper";
import { Helmet } from "react-helmet";

function SubscriptionMain() {
  const [isOpenFaq, setIsOpenFaq] = useState(false);

  const subscriptionModal = useSelector(
    (store) => store.modalToggleSlice.subscriptionCarModal
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  if (subscriptionModal && screenWidth < 640) {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Daily, Weekly and Monthly Car Subscription Plans</title>

        <meta
          name="description"
          content="Looking for a car subscription plans in UAE? WTi Car Rental provides the most flexible monthly plans with no deposit. Contact us today for best deals!"
        />
        <meta
          name="keywords"
          content="car subscription service, car subscription uae, monthly car rental, uae car rental plans, car lease in uae, car subscription fleet"
        />

        <meta name="revisit after" content="7 days" />
        <meta name="robots" content="follow, index" />
        <meta name="language" content="English" />
        <meta name="country" content="United Arab Emirates (UAE)" />
        <meta name="copyright" content="WTi Rent A Car LLC" />
        <meta name="publisher" content="wticarrental.ae" />
        <meta name="distribution" content="Global" />

        <link
          rel="canonical"
          href="https://www.wticarrental.ae/car-subscription-fleet"
        />

        <meta
          property="og:title"
          content="Daily, Weekly and Monthly Car Subscription Plans"
        />
        <meta
          property="og:description"
          content="Looking for a car subscription service in UAE? WTi Car Rental provides the most flexible monthly plans with no deposit. Contact us today for best deals!"
        />
        <meta property="og:image" content="blank" />
        <meta
          property="og:url"
          content="https://www.wticarrental.ae/car-subscription-fleet"
        />
      </Helmet>
      <div
        className={`w-full px-[9.27%] max-sm:px-0 bg-[#FFFFFF] ${
          // subscriptionModal ? "max-sm:h-screen max-sm:overflow-y-hidden" : ""
          subscriptionModal ? "max-sm:overflow-y-hidden" : ""
        }`}
      >
        <SubscriptionFilters />
        <SubscriptionHeading />
        <SubscriptionGrid />

        {/* <PopularBrandsSliderWrapper /> */}
      </div>
    </>
  );
}

export default SubscriptionMain;
