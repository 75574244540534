// Replace 'YOUR_API_KEY' with your actual Google Maps API key

import { auth_credentials, baseUrl } from "../../config";

const dubaiBounds = {
  northeast: {
    lat: 25.3585607,
    lng: 55.5645216,
  },
  southwest: {
    lat: 24.7934842,
    lng: 54.89045429999999,
  },
};

const abuDhabiBounds = {
  northeast: {
    lat: 24.621895,
    lng: 54.8509598,
  },
  southwest: {
    lat: 24.1517661,
    lng: 54.2662811,
  },
};

const sharjahBounds = {
  northeast: {
    lat: 25.4325688,
    lng: 55.743465,
  },
  southwest: {
    lat: 25.2073206,
    lng: 55.3490893,
  },
};

export async function checkIfLocationInDubai(location) {
  
  const address = encodeURIComponent(location);
  // // const response = await axios.post('${baseUrl}/app/v1/google/getLatLong', {address:location})

  
  // const response = await fetch(`${apiUrl}?address=${address}&key=${apiKey}`);
  const response = await fetch(`${baseUrl}/app/v1/google/getLatLong`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Basic ${auth_credentials}`
  },
  body: JSON.stringify({ address: address }),
});
  const data = await response.json();

  if (data.status === "OK") {
    const checkLocationLat = data.results[0].geometry.location.lat;
    const checkLocationLng = data.results[0].geometry.location.lng;

    const isWithinDubai =
      checkLocationLat >= dubaiBounds.southwest.lat &&
      checkLocationLat <= dubaiBounds.northeast.lat &&
      checkLocationLng >= dubaiBounds.southwest.lng &&
      checkLocationLng <= dubaiBounds.northeast.lng;

    const isWithinAbuDhabi =
      checkLocationLat >= abuDhabiBounds.southwest.lat &&
      checkLocationLat <= abuDhabiBounds.northeast.lat &&
      checkLocationLng >= abuDhabiBounds.southwest.lng &&
      checkLocationLng <= abuDhabiBounds.northeast.lng;

    const isWithinSharjah =
      checkLocationLat >= sharjahBounds.southwest.lat &&
      checkLocationLat <= sharjahBounds.northeast.lat &&
      checkLocationLng >= sharjahBounds.southwest.lng &&
      checkLocationLng <= sharjahBounds.northeast.lng;

    if (isWithinDubai) {
      // return {
      //   location: "Dubai",
      //   locationRate: 35,
      // };
      return "within_dubai";
    } else if (isWithinAbuDhabi) {
      // return {
      //   location: "AbuDhabi",
      //   locationRate: 150,
      // };
      return "within_abudabhi";
    } else if (isWithinSharjah) {
      // return {
      //   location: "Sharjah",
      //   locationRate: 50,
      // };
      return "within_sharjah";
    } else {
      return {
        location: "Unknown",
        locationRate: 0,
      };
    }
  } else {
    console.error(`Error: ${data.status}`);
    return {
      location: "Error",
      locationRate: 0,
    };
  }
}


// Example usage
// checkIfLocationInDubai('Dubai Mall');
