import React, { useEffect, useId, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { userSchema } from "../../validations/userValidation.js";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { tillFour } from "../../utils/stepperSlice.js";
import { addRes } from "../../utils/ReservationSlices/reservationSlice.js";
import { useNavigate } from "react-router-dom";
import { switchOffLoader, switchOnLoader } from "../../utils/loaderSlice.js";
import { addSessionID } from "../../utils/paymentSession.js";
import { togglePaymentPage } from "../../utils/paymentSession.js";
import { useRef } from "react";

import { setUserId as setUs } from "../../utils/userSlices/userSlice.js";
import { addTotalExtras } from "../../utils/extraSlice.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { allCountries } from "../../utils/countryData/allcountries.js";
import { toggleTermsAndCondition } from "../../utils/ModalSlice/modalToggleSlice.js";
import { switchOffHideHeaderFooter } from "../../utils/ModalSlice/modalToggleSlice.js";
import { addStripePaymentId } from "../../utils/paymentSession.js";
import { baseUrl } from "../../config.js";
import { addFinalAmountForPaymentFailed } from "../../utils/invoice/invoiceSlice.js";

//all stripe imports
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// //component
// import CheckoutForm from "./CheckoutForm.jsx";
// import "./CheckoutForm.css";

// const stripePromise = loadStripe(
//   "pk_test_51O0jq2LW3jzjuzNowUF6BQqr82oYoPNqJA1W5WBjbvFeDkWFhiRVy4EZ3QVcyOhme1oLuzRGgPgllEtQsBjtFaYC00DBmxEhlb"
// );

//end stripe imports
function PaymentFormUser() {
  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const emailInput = useRef(null);
  const phoneInput = useRef(null);
  const addressInput = useRef(null);
  const cityInput = useRef(null);
  const postalInput = useRef(null);
  const countryInput = useRef(null);

  const navigate = useNavigate();
  const [age, setAge] = React.useState("");
  const dispatch = useDispatch();
  // const [userId, setUserId] = useState("");
  const [termsCheck, setTermsCheck] = useState(true);
  const [extraObjects, setExtraObjects] = useState([]);
  const mainExtraArray = useSelector((store) => store.extraSlice.extraMainObj);
  const extraArray = useSelector((store) => store.extraSlice.extraArr);
  const [userVal, setUserVal] = useState(false);
  const [userFirstNameVal, setFirstNameVal] = useState(false);
  const [userLastNameVal, setLastNameVal] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [userAddress, setUserAddress] = useState(false);
  const [userCity, setUserCity] = useState(false);
  const [userCountry, setUserCountry] = useState(false);
  const pickUpDes = useSelector(
    (store) => store.bookingInfoSlice.pickupLocation
  );
  const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);

  const insuranceObject = useSelector(
    (store) => store.insuranceSlice.insuranceArr
  );
  const allEquipmentArray = useSelector(
    (store) => store.insuranceSlice.allEquipments
  );
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (pickUpDes == "" || carObj == {}) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    dispatch(tillFour());
    dispatch(switchOffLoader());
    dispatch(switchOffHideHeaderFooter());
    window.scrollTo({ top: 20, left: 0, behavior: "smooth" });
    // calculateTotalExtraAmount();
    fillAllEquipments();
  }, []);

  //user state
  const firstName = useSelector((store) => store.userSlice.firstName);
  const lastName = useSelector((store) => store.userSlice.lastName);
  const email = useSelector((store) => store.userSlice.emailId);
  const contact = useSelector((store) => store.userSlice.contact);
  const address = useSelector((store) => store.userSlice.address);
  const city = useSelector((store) => store.userSlice.city);
  const country = useSelector((store) => store.userSlice.country);
  const postalCode = useSelector((store) => store.userSlice.postalCode);
  const resStatus = useSelector(
    (store) => store.formControlSlice.reservationType
  );
  const dropLoc = useSelector((store) => store.bookingInfoSlice.dropLocation);
  const pickTime = useSelector((store) => store.bookingInfoSlice.pickupTime);
  const dropTime = useSelector((store) => store.bookingInfoSlice.dropTime);
  const totalDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );

  const payNowStatus = useSelector((store) => store.carDetailSlice.payNow);
  const baseRate = useSelector((store) => store.invoiceSlice.baseRate);
  let carDeliveryCharge = useSelector(
    (store) => store.invoiceSlice.carDeliveryCharge
  );
  const excessInsurancePrice = useSelector(
    (store) => store.invoiceSlice.excessInsurancePrice
  );

  const extrasRate = useSelector((store) => store.invoiceSlice.extras);
  const taxesRate = useSelector((store) => store.invoiceSlice.taxes);
  const totalRate = useSelector((store) => store.invoiceSlice.estimatedTotal);
  const currencySelected = useSelector(
    (store) => store.currencySlice.currentCurr
  );
  const conversionRate = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const pickupMMDDYYYY = useSelector(
    (store) => store.bookingInfoSlice.pickupMMDDYYYY
  );
  const dropMMDDYYYY = useSelector(
    (store) => store.bookingInfoSlice.dropMMDDYYYY
  );
  const resDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );

  const [showPaymentGateway, setShowPaymentGateway] = useState(false);


  //createReservation 
  const deliveryCharge = useSelector(
    (store) => store.pickDropSlice.carDropLocation.locationRate
  );
  const collectionCharge = useSelector(
    (store) => store.pickDropSlice.carPickLocation.locationRate
  );
  const usrId = useSelector((store) => store.userSlice.userId);
  const totalSelectedExtras = useSelector(
    (store) => store.extraSlice.totalExtraSelected
  );

  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  let currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );



  const [number, setNumber] = useState("us");
  const setPhoneNumber = (val) => {
    setNumber(val);
  };

  const openTermsModal = () => {
    dispatch(toggleTermsAndCondition());
  };

  // const appearance = {
  //   theme: "stripe",
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };

  const makeANewCustomer = async () => {
    try {
      let data = JSON.stringify({
        name: firstNameInput.current.value + " " + lastNameInput.current.value,
        phone: number,
        email: emailInput.current.value,
        address: {
          line1: addressInput.current.value,
          postal_code: postalInput.current.value,
          city: cityInput.current.value,
          country: countryInput.current.value,
        },
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: `http://localhost:3000/app/v1/billing/createCustomer`,
        url: `${baseUrl}/app/v1/billing/createCustomer`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const result = await axios.request(config);

      if (result) {
        return result.data.customerID;
      } else {
        console.log("Error fetching customer id");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkoutPage = async (custId, custAmount, custCurr) => {
    let data = JSON.stringify({
      customerId: custId,
      currency: custCurr,
      amount: custAmount,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/billing/checkout`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        const sessionId = response.data.sessionID
        dispatch(addSessionID(sessionId));
        //created session
        //session url
        //session id
        setTimeout(() => {
          dispatch(switchOffLoader());
          window.location = response.data.sessionURL;
        }, 500)
      })
      .catch((error) => {
        console.log("Error in checkout API: ", error);
      });
  };

  const givePrice = async () => {
    try {
      const isValid = await checkValidity();
      if (isValid) {
        dispatch(switchOnLoader());

        const stripeCustomerID = await makeANewCustomer();
        dispatch(addStripePaymentId(stripeCustomerID));
        dispatch(addTotalExtras(extraObjects));

        setUserVal(false);

        let data = JSON.stringify({
          firstName: firstNameInput.current.value,
          lastName: lastNameInput.current.value,
          contact: number,
          country: countryInput.current.value,
          address: addressInput.current.value,
          city: cityInput.current.value,
          postalCode: postalInput.current.value,
          emailID: emailInput.current.value,
          password: "",
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/user/createUser`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then(async(response) => {
            // );
            //

            // setUserId(response.data.userID);
            dispatch(setUs(response.data.userID));
            console.log("user created");
            //used to hide header and footer

            //---
            //between user creation and making reservation we need to make the payment
            // navigate(`/reservation/paymentgateway/${stripeCustomerID}`);

                if(response.data.userID){
                   await createReservation(response.data.userID, stripeCustomerID);
                } else {
                  console.log("app/v1/user/createUser - UserId not found")
                }
          })
          .catch((error) => {
            console.error(error);
            //
          });
      } else {
        setUserVal(true);
        window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
      }
    } catch (error) {
      console.log(error);
    }
  };


  const createReservation = async(userId, stripeCustomerID) => {
  

    let data = JSON.stringify({
      reservation: {
        reservationID: "",
        userID: userId,
        travelItenary: {
          reservationType: resStatus,
          pickupLocation: pickUpDes,
          dropLocation: dropLoc,
          pickupDate: pickupMMDDYYYY,
          pickupTime: pickTime,
          dropDate: dropMMDDYYYY,
          dropTime: dropTime,
          residency: "",
          age: "25",
        },
        car: {
          type: carObj.type,
          typeID: carObj.vehicleID,
          src: carObj.imageName,
          title: carObj.title,
        },
        extras: extraObjects,
        currencyInfo: {
          currency: currencyName,
          currencyRate: currencyPrice,
        },
        payment: "",
        payType: payNowStatus ? "payNow" : "payLater",
        reservationDate: "",
        reservationDays: resDays.toString(),
        reservationStatus: "",
        allocatedCarID: "",
        allocatedDriverID: "",
        promoCode: "",
        isModifiedFlag: false
      },
      invoice: {
        invoiceID: "",
        reservationID: "",
        userID: userId,
        invoiceDate: {
          issueDate: "",
          dueDate: "",
        },
        baseRate: baseRate * totalDays,
        addOnCharges: extrasRate,
        excessInsuranceCharge: excessInsurancePrice,
        deliveryCharge: deliveryCharge,
        collectionCharge: collectionCharge,
        taxes:
          (baseRate * totalDays +
            extrasRate +
            excessInsurancePrice +
            carDeliveryCharge) *
          0.05,
        totalCharges:
          baseRate * totalDays +
          excessInsurancePrice +
          extrasRate +
          carDeliveryCharge +
          (baseRate * totalDays +
            extrasRate +
            excessInsurancePrice +
            carDeliveryCharge) *
            0.05,
        promoCodeDeductions: "",
        chargesAfterPromoCode: "",
        paymentStatus: "",
        paymentMethod: "",
      },
    });
  
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/reservation/createReservation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  
    try {
      axios
        .request(config)
        .then(async(response) => {    

          //calling the checkout function
          let custTotalAmount =
          baseRate * totalDays +
          excessInsurancePrice +
          extrasRate +
          carDeliveryCharge +
          (baseRate * totalDays +
            excessInsurancePrice +
            extrasRate +
            carDeliveryCharge) *
            0.05;



          if (
            response.data.invoiceCreated &&
            response.data.reservationCreated &&
            response.data.reservationID
          ) {
            const resId = response.data.reservationID;

            console.log("Reservation created succesfully!");

            dispatch(addRes(resId));
            dispatch(addFinalAmountForPaymentFailed(custTotalAmount* conversionRate));
            await checkoutPage(stripeCustomerID, custTotalAmount* conversionRate, currencySelected);
          }
        })
        .catch((error) => {
          throw new Error("Error creating reservation: ", error);
        });
    } catch (error) {
      console.log(error);
    }
  };



  
  const checkValidity = async () => {

    let userInfo = {
      firstName: firstNameInput.current.value,
      lastName: lastNameInput.current.value,
      email: emailInput.current.value,
      phoneNumber: number,
      address: addressInput.current.value,
      city: cityInput.current.value,
      country: countryInput.current.value,
      // postalCode: postalInput.current.value,
    };

    const vaildFirstName = await userSchema.isValid(userInfo);
    if (vaildFirstName == true) {
      return true;
    }
    const validFName = await userSchema.fields.firstName.isValid(
      firstNameInput.current.value
    );
    const validLName = await userSchema.fields.lastName.isValid(
      lastNameInput.current.value
    );
    const validEmail = await userSchema.fields.email.isValid(
      emailInput.current.value
    );
    const validAddress = await userSchema.fields.address.isValid(
      addressInput.current.value
    );
    const validcity = await userSchema.fields.city.isValid(
      cityInput.current.value
    );
    const validCountry = await userSchema.fields.country.isValid(
      countryInput.current.value
    );
    //
    if (vaildFirstName == false) {
      if (validFName) {
        setFirstNameVal(false);
      } else {
        setFirstNameVal(true);
      }

      if (validLName) {
        setLastNameVal(false);
      } else {
        setLastNameVal(true);
      }

      if (validEmail) {
        setUserEmail(false);
      } else {
        setUserEmail(true);
      }

      if (validAddress) {
        setUserAddress(false);
      } else {
        setUserAddress(true);
      }

      if (validcity) {
        setUserCity(false);
      } else {
        setUserCity(true);
      }

      if (validCountry) {
        setUserCountry(false);
      } else {
        setUserCountry(true);
      }
      return false;
    }
  };

 
  const fillAllEquipments = () => {
    let newArr = [];

    for (let i = 0; i < extraArray.length; i++) {
      if (extraArray[i].charAt(extraArray[i].length - 1) != "C") {
        for (let j = 0; j < allEquipmentArray.length; j++) {
          if (
            extraArray[i].substring(0, 2) ==
            allEquipmentArray[j].id.substring(0, 2)
          ) {
            newArr.push(allEquipmentArray[j]);
          }
        }
      } else {
        newArr.push(insuranceObject[0]);
      }
    }
    setExtraObjects(newArr);
  };

  // const calculateTotalExtraAmount = () => {
  //   const insuranceArr = mainExtraArray[0];
  //   const equipmentArr = mainExtraArray[1];
  //   //
  //   let newArr = [];
  //   for (let i = 0; i < extraArray.length; i++) {
  //     if (extraArray[i] == "1" || extraArray[i] == "2") {
  //       for (let j = 0; j < insuranceArr.length; j++) {
  //         if (extraArray[i] == insuranceArr[j]["id"]) {
  //           newArr.push(insuranceArr[j]);
  //         }
  //       }
  //     } else {
  //       for (let j = 0; j < equipmentArr.length; j++) {
  //         if (extraArray[i] == equipmentArr[j]["id"]) {
  //           newArr.push(equipmentArr[j]);
  //         }
  //       }
  //     }
  //   }
  //   setExtraObjects(newArr);
  //   //
  // };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  ///on change functions

  return (
    <>
      <div className="px-[10.27%] py-[2.5%]">
        <div className="font-bold text-lg ">Your Information</div>
        <div className="removeBack">
          <div className="flex justify-between w-[70%]  mt-6 max-sm:w-full max-sm:block">
            <div className="w-[49%] max-sm:w-full">
              <TextField
                fullWidth
                error={userFirstNameVal}
                size="small"
                label="First Name"
                variant="filled"
                required={true}
                // value={firstNameInput.current.value}

                inputRef={firstNameInput}
                // onChange={(e) => handleFirstName(e)}
              />
            </div>
            <div className="w-[49%] max-sm:w-full max-sm:mt-2">
              <TextField
                fullWidth
                error={userLastNameVal}
                size="small"
                label="Last Name"
                variant="filled"
                required={true}
                // value={lastName}
                // onChange={(e) => handleLastName(e)}
                inputRef={lastNameInput}
              />
            </div>
          </div>
          <div className="flex justify-between w-[70%] max-sm:w-full max-sm:block mt-6 max-sm:mt-2">
            <div className="w-[49%] max-sm:w-full">
              <TextField
                fullWidth
                error={userEmail}
                required={true}
                size="small"
                label="Email"
                variant="filled"
                // value={email}
                // onChange={(e) => handleEmail(e)}
                inputRef={emailInput}
              />
            </div>
            <div className="w-[49%] max-sm:w-full max-sm:mt-2 flex justify-between border-b border-b-[#8B8B8B]">
              <div className="w-[100%] max-sm:w-[100%] flex flex-col">
                <PhoneInput
                  required={true}
                  country={number}
                  countryCodeEditable={false}
                  value={number}
                  onChange={(value) => setPhoneNumber(value)}
                  //  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between max-sm:w-full max-sm:block w-[70%] mt-6 max-sm:mt-2">
            <div className="w-[49%] max-sm:w-full">
              <TextField
                error={userAddress}
                required={true}
                fullWidth
                size="small"
                label="Address"
                variant="filled"
                // value={address}
                // onChange={(e) => handleAddress(e)}
                inputRef={addressInput}
              />
            </div>
            <div className="w-[49%] max-sm:w-full max-sm:mt-2">
              <TextField
                error={userCity}
                required={true}
                fullWidth
                size="small"
                label="City"
                variant="filled"
                // value={city}
                // onChange={(e) => handleCity(e)}
                inputRef={cityInput}
              />
            </div>
          </div>
          <div className="flex justify-between w-[70%] max-sm:w-full max-sm:block max-sm:mt-2 mt-6">
            <div className="w-[49%] max-sm:w-full">
              <FormControl variant="filled" size="small" fullWidth>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  error={userCountry}
                >
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  error={userCountry}
                  value={countryInput.value}
                  required={true}
                  inputRef={countryInput}
                >
                  {allCountries.map((ele) => (
                    <MenuItem value={ele?.name}>{ele?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-[49%] max-sm:w-full max-sm:mt-2">
              <TextField
                fullWidth
                size="small"
                label="Postal Code"
                variant="filled"
                // value={postalCode}
                // onChange={(e) => handlePostalCode(e)}
                inputRef={postalInput}
              />
            </div>
          </div>
          <div className="max-sm:mt-4">
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label={
                <span className="max-sm:text-[12px]">
                  Send me email promotions and offers.
                </span>
              }
            />
          </div>
        </div>
        <div className="mt-10">
          <div className="font-bold text-lg ">Terms and Conditions</div>
          <div className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsCheck}
                  onChange={() => setTermsCheck(!termsCheck)}
                />
              }
            />

            <span className="max-sm:text-[14px]">
              I have read and accept the{" "}
              <a className="text-blue-950 font-semibold hover:underline hover:cursor-pointer">
                Location Policies
              </a>{" "}
              and{" "}
              <a
                className="text-blue-950 font-semibold hover:underline hover:cursor-pointer"
                onClick={openTermsModal}
              >
                Rental Terms and Conditions.
              </a>
            </span>
          </div>
          <div className="mt-4 max-sm:mb-8">
            <div
              className={`px-8 py-2 text-white max-sm:text-sm max-sm:w-full max-sm:text-center max-sm:mt-6 cursor-pointer ${
                termsCheck ? "bg-[#092C85]" : "bg-slate-500"
              }  hover:animate-pulse w-fit rounded `}
              onClick={termsCheck ? givePrice : console.log()}
              
              // onClick={con}
              // onClick={checkValidity}
            >
              Reserve your car & Pay Now
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentFormUser;
