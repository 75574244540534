import React from "react";
import EditIcon from "../../images/edit_icom.svg";
import BookedCarImg from "../../images/booked_car_img.png";
import ConfirmIcon from "../../images/confirm_icon.svg";
import CancelIcon from "../../images/cancel_icon.svg";
import { useSelector } from "react-redux";
import { baseUrl } from "../../config";

const BookedCarForSubscription = ({ subscriptionData, index }) => {
  const {
    car,
    currencyInfo,
    extras,
    payment,
    subscriptionDate,
    subscriptionID,
    subscriptionMileage,
    subscriptionMonths,
    subscriptionStatus,
    travelItenary,
    userID,
  } = subscriptionData[0];

  const {
    addOnCharges,
    baseRate,
    chargesAfterPromoCode,
    collectionCharge,
    deliveryCharge,
    excessInsuranceCharge,
    paymentMethod,
    promoCodeDeductions,
    subscriptionRecieptID,
    taxes,
    totalCharges,
  } = subscriptionData[1];

  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyRate = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );



  return (
    <>
      <div
        className="flex flex-wrap justify-between items-center w-full my-8 lg:mt-0 mt-5"
        key={index}
      >
        <div className="w-full lg:w-8/12 px-3">
          <div className="bg-[#fff] py-8 px-4 lg:px-6 lg:shadow-[0px_3px_20px_0px_#00000029] lg:rounded-b-[20px] rounded-b-[0] rounded-[20px] ">
            <div className="w-full flex flex-col gap-3">
              <div className="w-full flex justify-between items-center relative">
                <button className=" text-[#fff] absolute -top-[44px] text-[16px] tracking-[1px] font-semibold leading-[20px] bg-[#DCC52A] py-3 px-4 rounded-[12px]">
                  {subscriptionStatus}
                </button>
                <h4 className="text-[#494949] text-[16px] whitespace-nowrap lg:text-[24px] font-bold leading-[24px]">
                  Advance Receipt
                </h4>
                <button className="flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all active:scale-[1.2]">
                  <img src={EditIcon} alt="" />
                  <span className="text-[#fff] text-[16px] font-semibold leading-[20px] lg:block hidden">
                    Modify Details
                  </span>
                </button>
              </div>
              <h3 className="text-[#000000] whitespace-nowrap text-[14px] lg:text-[18px] font-semibold leading">
                Confirmation Number:{" "}
                <span className="text-[#0077B6] pl-1">{subscriptionID}</span>
              </h3>
              <div className="flex justify-between items-center my-3">
                <div className="flex flex-col items-start gap-3">
                  <h4 className="text-[#000] text-[16px] font-bold leading-[20px]">
                    Pick Up/ Delivery Location
                  </h4>
                  <div className="w-[60%] flex items-end">
                    <span className="text-[#474747] text-[14px] text-left font-normal  leading-[20px]">
                      {travelItenary.deliveryLocation}
                    </span>
                  </div>
                  <h4 className="text-[#0193C5] text-[16px] font-bold leading-[16px]">
                    <span>{travelItenary.deliveryDate}</span>
                    <span className="text-[#8B8B8B] pl-2">{travelItenary.deliveryTime}</span>
                  </h4>
                </div>
                <div className="flex flex-col items-center py-4 px-4 relative rounded-[12px] bg-[#e6f4f9]">
                  <span className="text-[#ffffff] py-2 absolute -top-[32px] px-4 rounded-[12px] bg-[#727272] text-[20px] leading-[36px] font-normal">
                    {subscriptionMonths} Months
                  </span>
                  <span className="text-[#0077B6] text-[30px] mt-2 leading-[32px] font-semibold">
                    {subscriptionMileage}
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="grow-[1]">
                  {/* <img src={BookedCarImg} alt="" /> */}
                  <img
                    src={
                      `${baseUrl}/app/v1/aws/getImage/carimages/` +
                      car?.src.split(" ").join("") +
                      ".png"
                    }
                    className="w-[60%]"
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-4 px-1 lg:px-6  grow-[2]">
                  <div className="flex items-baseline gap-2">
                    <h3 className="text-[#494949] whitespace-nowrap  text-[14px] lg:text-[28px] font-bold leading-[16px] lg:leading-[28px]">
                      {car.type}
                    </h3>
                    <button className="py-2 grow-[1] whitespace-nowrap px-4 border border-[#0193C5] text-[#0193C5] text-[14px] leading-[20px] rounded-[12px] lg:hidden">
                      Need Help?
                    </button>
                  </div>
                  <span className="text-[#494949] text-[14px] lg:text-[20px] font-normal leading-[14px] lg:leading-[20px]">
                    {car.src}
                  </span>
                </div>
                <button className="py-2 grow-[1] px-4 border border-[#0193C5] text-[#0193C5] text-[20px] leading-[20px] rounded-[12px] lg:block hidden">
                  Need Help?
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 w-full lg:hidden block ">
          <div className="h-[1px] bg-[#A3A3A3] w-full"></div>
        </div>
        <div className="w-full lg:w-4/12 px-3 lg:-mt-[0] -mt-[0px]">
          <div className="py-6 px-6 bg-[#fff] lg:rounded-t-[20px] rounded-t-[0] rounded-[20px]  lg:shadow-[0px_3px_20px_0px_#00000029]">
            <div className="flex flex-col w-full  ">
                <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#222222] text-[18px] font-semibold leading-[20px]">
                    Booking method:
                    </h4>
                    <h4 className="text-[#0077B6] text-[18px] font-semibold leading-[20px]">
                    PAY LATER
                    </h4>
              </div>

              <div className="flex justify-between items-center w-full mt-0">
                <span className="text-[#5F5F5F] text-[18px] font-semibold leading-[20px]">
                  Payment Status :
                </span>
                <span className=" text-[#0077B6] text-[16px] tracking-[1px] font-semibold leading-[20px] py-3 px-2 rounded-[12px]">
                  {payment}
                </span>
              </div>
              <div className="flex flex-col gap-4">
                <h4 className="text-[#222222] text-[20px] font-semibold leading-[20px]">
                  Included in the rates
                </h4>
                <div className="w-full flex gap-2 justify-between  flex-col">
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[16px] font-semibold leading-[18px]">
                      Base Rate
                    </h4>
                    <h4 className="text-[#222222] text-[16px] font-semibold leading-[18px]">
                    {currencyName} {(baseRate*currencyRate).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[16px] font-semibold leading-[18px]">
                      No-Deposit Premium
                    </h4>
                    <h4 className="text-[#222222] text-[16px] font-semibold leading-[18px]">
                    {currencyName} {(excessInsuranceCharge*currencyRate).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[16px] font-semibold leading-[18px]">
                      Delievery Charge
                    </h4>
                    <h4 className="text-[#222222] text-[16px] font-semibold leading-[18px]">
                    {currencyName} {(deliveryCharge*currencyRate).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[16px] font-semibold leading-[18px]">
                      Collection Charge
                    </h4>
                    <h4 className="text-[#222222] text-[16px] font-semibold leading-[18px]">
                    {currencyName} {(collectionCharge*currencyRate).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[16px] font-semibold leading-[18px]">
                      Extras
                    </h4>
                    <h4 className="text-[#222222] text-[16px] font-semibold leading-[18px]">
                    {currencyName} {(addOnCharges*currencyRate).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[16px] font-semibold leading-[18px]">
                      Taxes & Fees
                    </h4>
                    <h4 className="text-[#222222] text-[16px] font-semibold leading-[18px]">
                      {currencyName} {(taxes*currencyRate).toFixed(2)}
                    </h4>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-2 ">
                  <div className="flex justify-between items-center bg-[#F6F6F6] rounded-[8px] py-2 px-3">
                    <h4 className="text-[#5F5F5F] text-[16px] font-semibold leading-[20px]">
                      Total
                    </h4>
                    <h4 className="text-[#222222] text-[16px] font-semibold leading-[20px]">
                    {currencyName} {(totalCharges*currencyRate).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center gap-2">
                    <button className="flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all active:scale-[1.2]">
                      <img src={ConfirmIcon} alt="" />
                      <span className="text-[#fff] text-[16px] font-semibold leading-[20px]">
                        Confirmation
                      </span>
                    </button>
                    <button className="flex justify-start gap-2 items-center border border-[#0077B6] py-2 px-3 rounded-[12px]">
                      <img src={CancelIcon} alt="" />
                      <span className="text-[#0077B6] text-[16px] font-semibold leading-[20px] ">
                        Cancel
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookedCarForSubscription;
