import React, { useEffect, useState } from "react";
import BookingCard from "./BookingCard";
import CompletedBooking from "./CompletedBooking";
import { useSelector } from "react-redux";
import axios from "axios";
import { switchOnLoader, switchOffLoader } from "../../../utils/loaderSlice";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../config";
import StatusDropDown from "../../CarBooking/StatusDropDown";

function BookingsMain() {
  const dispatch = useDispatch();
  const userID = useSelector((store) => store.userCookie.userId);
  const [profileUpcomingData, setProfileUpcomingData] = useState([]);
  const [profileCompletedData, setProfileCompletedData] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState("Select")
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("Select")
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    dispatch(switchOnLoader());
    let data = JSON.stringify({
      userID: `${userID}`,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/reservation/getReservationsAndInvoice`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        let upComing = [];
        // let completed = [];

        for (let i = 0; i < response.data.result.length; i++) {
          // if (response.data.result[i][0].reservationStatus == "Completed") {
          //   completed.push(response.data.result[i]);
          // } else if (response.data.result[i][0].reservationStatus == "Pending") {
            upComing.push(response.data.result[i]);
          // }
        }


        // setProfileCompletedData(completed);
        if(upComing.length){

        const modifiedData = upComing.filter(arr => {
           return !arr.some(item => item.isModifiedFlag == true);
         });
         setFilteredData(modifiedData)
        }
        setProfileUpcomingData(upComing);


        dispatch(switchOffLoader());
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    if (profileUpcomingData.length) {
      if(selectedBookingStatus === "All"){
        setFilteredData(profileUpcomingData)
      } else {
        let result = profileUpcomingData?.filter((item) => {
          return (
            item[0]?.reservationStatus === selectedBookingStatus?.replaceAll(" ", "") ||
            item[1]?.reservationStatus === selectedBookingStatus?.replaceAll(" ", "")
          );
        });
        setFilteredData(result);
      }
    }

  }, [selectedBookingStatus]);

  useEffect(() => {
    if (profileUpcomingData.length) {
      if(selectedPaymentStatus === "All"){
        setFilteredData(profileUpcomingData)
      } else {
        let result = profileUpcomingData?.filter((item) => {
          return (
            item[0]?.paymentStatus === selectedPaymentStatus?.replaceAll(" ", "") ||
            item[1]?.paymentStatus === selectedPaymentStatus?.replaceAll(" ", "")
          );
        });
        setFilteredData(result);
      }
    }

  }, [selectedPaymentStatus]);

  const [resType, setResType] = useState(0);
  const handleBookings = () => {
    setResType(0);
  };

  const handleAccount = () => {
    setResType(1);
  };

  return (
    <>
      <div className="">
        <div className="flex justify-start mb-12 pt-6">
          {/* <div
            onClick={handleBookings}
            className={` px-[25px] py-[10px] flex justify-center items-center text-xs font-normal cursor-pointer border border-[#092C85] rounded-3xl mr-4 ${
              resType == 0 ? "bg-[#092C85] text-white" : "text-[#092C85]"
            }`}
          >
            <span className="ml-2 font-normal max-sm:text-center max-sm:ml-0">Upcoming Bookings</span>
          </div>
          <div
            onClick={handleAccount}
            className={`px-[25px] py-[10px] flex justify-center items-center text-xs font-normal cursor-pointer border border-[#092C85] rounded-3xl ${
              resType == 1 ? "bg-[#092C85] text-white" : "text-[#092C85]"
            }`}
          >
            <span className="ml-2 font-normal max-sm:text-center max-sm:ml-0">Completed Bookings</span>
          </div> */}

          <div className="flex md:flex-nowrap flex-wrap items-center gap-3 md:w-6/12 w-full px-3">
            <div className="flex flex-col gap-1 w-full">
              <h3 className="text-[#000000] text-[20px] font-normal leading-[32px]">
                Booking Status :
              </h3>
              <StatusDropDown
                selectedStatus={selectedBookingStatus}
                setSelectedStatus={setSelectedBookingStatus}
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <h3 className="text-[#000000] text-[20px] font-normal leading-[32px]">
                Payment Status :
              </h3>
              <StatusDropDown
                selectedStatus={selectedPaymentStatus}
                setSelectedStatus={setSelectedPaymentStatus}
              />
            </div>
          </div>
        </div>
        {filteredData.length
          ? filteredData.map((ele) => <BookingCard profileData={ele} />)
          : ""}
        {/* {
        resType == 1
          ? filteredData.map((ele) => <BookingCard profileData={ele} />)
          : profileCompletedData.map((ele) => (
            <CompletedBooking profileData={ele} />
          ))} */}
      </div>
    </>
  );
}

export default BookingsMain;
