import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "./index.css"
import FeatureVehicleCard from './FeatureVehicleCard';

const FeatureVehicleSlider = () => {
    return (
        <div className='FeatureVehicleSlider'>
            <Swiper
                // slidesPerView={3}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >
                {
                    [1, 2, 3, 4, 5, 6].map((slide, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <FeatureVehicleCard />
                            </SwiperSlide>
                        )
                    })
                }


            </Swiper>
        </div>
    )
}

export default FeatureVehicleSlider
