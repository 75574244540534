import React from "react";
import LocationBtn from "./radiobtns/LocationBtn";
import DriverAge from "./radiobtns/DriverAge";
import NeedDeliveryAndPickup from "./radiobtns/NeedDeliveryAndPickup";




function RadioBtnCont(){
    return(
        <React.Fragment>
            <div id="radioButtonFill" className="w-full  flex  max-sm:flex-col">
                
                <LocationBtn/>
                <div className="max-sm:hidden">
                    <DriverAge/>
                </div>
                {/* <NeedDeliveryAndPickup/> */}
                
            </div>
        </React.Fragment>
    )
}

export default RadioBtnCont