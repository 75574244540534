import React from "react";
import PaymentSuccess from "../reservationConfirmed/PaymentSuccess";
import PaymentFailed from "../reservationConfirmed/PaymentFailed";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { switchOffLoader } from "../../utils/loaderSlice";
import { useDispatch } from "react-redux";

import { addRes } from "../../utils/ReservationSlices/reservationSlice";
import { useParams } from "react-router-dom";

import { switchOffHideHeaderFooter } from "../../utils/ModalSlice/modalToggleSlice";

import { addSubReservationId } from "../../utils/subscriptionSlices/subscriptionTripDetailSlice";
import { baseUrl } from "../../config";

function SubscriptionPaymentMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [extraObjects, setExtraObjects] = useState([]);

  const [showSuccess, setShowSuccess] = useState(null);

  const resStatus = useSelector(
    (store) => store.formControlSlice.reservationType
  );
  

  const excessInsurancePrice = useSelector(
    (store) => store.invoiceSlice.excessInsurancePrice
  );

  const carDeliveryCharge = useSelector(
    (store) => store.invoiceSlice.carDeliveryCharge
  );
  const deliveryCharge = useSelector(
    (store) => store.pickDropSlice.carDropLocation.locationRate
  );
  const collectionCharge = useSelector(
    (store) => store.pickDropSlice.carPickLocation.locationRate
  );
 

  const sessionId = useSelector((store) => store.paymentSession.sessionID);
  const usrId = useSelector((store) => store.userSlice.userId);
 

  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  let currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );

  const subscriptionTripDetails = useSelector(state => state.subscriptionTripDetailSlice);

  // Now, you can access individual properties
  const { subDeliveryLocation, subDeliveryDate, subDeliveryTime, subFirstName, subLastName, subPhone, subEmail, subAddress, subCity, subCountry } = subscriptionTripDetails;

  const subscriptionObject = useSelector(
    (store) => store.subscriptionSliceMain.subscriptionCarObj
  );
  const totalBaseRate = useSelector(
    (store) => store.subscriptionInvoiceSlice.subscriptionBaseRate
  );
  const totalExtrasRate = useSelector(
    (store) => store.subscriptionInvoiceSlice.subscriptionExtrasTotal
  );
  const subscriptionTenureNumber = useSelector(
    (store) => store.subscriptionSliceMain.tenureNumber
  );

  const extraArray = useSelector((store)=>store.subscriptionInvoiceSlice.allAddOnNames);

  useEffect(()=>{
    if(Object.keys(subscriptionObject).length==0){
        navigate('/')
        
    }else{
        paymentSuccessOrFailureChecker();
    }
    
  },[])



  const paymentSuccessOrFailureChecker = () => {
    try {

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/billing/subscriptionPaymentStatus/${sessionId}/${usrId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .request(config)
        .then((response) => {
         if(response.data.paymentStatus=="paid"){
            sendReservation();
         }else{
            navigate('/subscription/paymentstatus/paymentincomplete');
         }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendReservation = async () => {
    let data = JSON.stringify({
        "subscription": {
          "subscriptionID": "",
          "userID": usrId,
          "travelItenary": {
            "deliveryLocation": subDeliveryLocation,
            "deliveryDate": subDeliveryDate.toString(),
            "deliveryTime": subDeliveryTime
          },
          "car": {
            "type": subscriptionObject.type,
            "typeID": subscriptionObject.vehicleID,
            "src": subscriptionObject.imageName,
            "title": subscriptionObject.title
          },
          "extras": extraArray,
          "currencyInfo": {
            "currency": currencyName,
            "currencyRate": currencyPrice
          },
          "payment": "Completed",
          "subscriptionDate": subDeliveryDate.toString(),
          "subscriptionMonths": subscriptionTenureNumber,
          "subscriptionMileage": "2500 km/month",
          "subscriptionStatus": "Pending",
          "allocatedCarID": "",
          "allocatedDriverID": "",
          "promoCode": "",
          "finalInvoice": false
        },
        "invoice": {
          "subscriptionRecieptID": "",
          "subscriptionID": "",
          "userID": "",
          "baseRate": totalBaseRate,
          "excessInsuranceCharge": 0,
          "deliveryCharge": deliveryCharge,
          "collectionCharge": 0,
          "addOnCharges": totalExtrasRate,
          "taxes": (totalBaseRate+totalExtrasRate+deliveryCharge)*0.05,
          "totalCharges": (totalBaseRate+totalExtrasRate+deliveryCharge)*1.05,
          "promoCodeDeductions": null,
          "chargesAfterPromoCode": 0,
          "paymentStatus": "payNow",
          "paymentMethod": "card"
        }
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/subscription/createSubscription`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        dispatch(addSubReservationId(response.data.subscriptionID));
        navigate('/subscription/paymentstatus/paymentcomplete');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="w-full pl-[10.27%] pr-[10.27%] max-sm:px-[4%] bg-[#EBEBEB] pt-6 pb-6 flex justify-center">
      {showSuccess == null ? (
        <>
          <div className="flex justify-center items-center mb-3">
            <div className="bg-red-white flex justify-center flex-col items-center px-40 py-16 p-4 rounded shadow-lg text-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-700"></div>
              <div className="mt-4 text-xl font-semibold text-gray-700">
                Processing Payment . . .
              </div>
            </div>
          </div>
        </>
      ) : showSuccess == true ? (
        <PaymentSuccess />
      ) : (
        <PaymentFailed />
      )}
    </div>
  );
}

export default SubscriptionPaymentMain;
