import React from 'react'
import { useNavigate } from 'react-router-dom';

function ServicesHome() {
    const navigate = useNavigate();
    const data = [
        {
          image: "service1.jpg",
          title: "Self Drive",
          content: " Explore amazing self drive cars in Dubai like Nissan Altima, Toyota Camry, etc.",
          alt:"Self Drive Cars Dubai",
          navigateto:"/self-drive-car-rental"
        },
        // {
        //   image: "service2.jpg",
        //   title: "Chauffeur Drive",
        //   content: "Travel in Style and Comfort with Our Premier Chauffeur-Driven Service.",
        //   alt:"Chauffeur-Driven Service Dubai",
        //   navigateto:"/chauffeur-service",

        // },
        // {
        //   image: "service3.jpg",
        //   title: "Airport Transfers",
        //   content: " Enjoy hassle-free travel with our Airport Transfer Services in Dubai.",
        //   alt:"Airport Tranfers Dubai",
        //   navigateto:"/airport-transfer-service",
        // },
    ];

    
  return (
    <div className='w-full pl-[10.27%] pr-[10.27%] flex flex-col items-center justify-start my-10'>
        <div><h2 className='font-bold text-3xl text-[#0F0A34] mb-4'>Our Car Rental Solutions</h2></div>
        <div className="flex justify-center items-center ">
          <div className="flex justify-center max-sm:grid-cols-1 w-full max-sm:gap-y-6  ">
            {data.map((items, index) => (
              <div key={index} className=" md:w-8/12  w-full rounded-t-lg  m-2 bg-white  shadow-2xl cursor-pointer hover:ring-2"
               
              // onClick={()=>items.title=="Self-Drive"?navigate("/car-rental"):navigate("/")}
              onClick={() =>navigate(`${items.navigateto}`)} >
               <div>
                  <img
                    src={"../images/" + items.image}
                    alt={items.alt}
                    className=" w-full rounded-t-lg"
                    loading="lazy"
                  />
                </div>
                <div >
                  <h2 className="font-bold text-[20px] pt-6  text-center ">
                    {items.title} 
                  </h2>
                </div>
                  <p className=" text-[15px] py-3 px-10 text-center ">
                    {items.content}
                  </p>
              </div>
            ))}
          </div>
        </div>
    </div>
  )
}



export default ServicesHome