import React from "react";
import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  selectIndia,
  selectUae,
  selectUsa,
} from "../../../utils/currencySlice";
import {
  inrToAed,
  inrToUsd,
  aedToInr,
  aedToUsd,
  usdToAed,
  usdToInr,
} from "../../../utils/invoice/invoiceSlice";
import { toggleLogin } from "../../../utils/loginSlices/loginSlice";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Cookies from "js-cookie";
import { removeCookie, removeUserCookieInfo } from "../../../utils/userCookie";
import ProfileDropDown from "./ProfileDropDown";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import { changeCurrency } from "../../../utils/currencySlice";
import axios from "axios";


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CurrencyModal from "../../currencyModal/Modal";
import { useNavigate } from "react-router-dom";

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { baseUrl } from "../../../config";

function MenuOptions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookieStatus = useSelector((store) => store.userCookie.cookieExist);
  const fName = useSelector((store) => store.userCookie.firstName);
  const[profileModal,setProfileModal] = useState(false);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Currency', 'Intelligent Mobility'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => { index == 0 ? navigate('/currencysearch') : navigate('/corporate-mobility')}}>
              <ListItemIcon>
              {index == 0 ? <><AttachMoneyIcon /></> : <><DirectionsCarIcon /></>}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );


  // End Hamburger code 
  const basrR = useSelector((store) => store.invoiceSlice.baseRate);
  const extr = useSelector((store) => store.invoiceSlice.extras);
  const tx = useSelector((store) => store.invoiceSlice.taxes);
  const estTtl = useSelector((store) => store.invoiceSlice.estimatedTotal);
  const currArr = useSelector((store) => store.currencySlice.currencyArr);

  useEffect(() => {
    //check for login
    let cook = Cookies.get("jwtToken");
    //
    if (cook == undefined) {
      dispatch(removeCookie());
      dispatch(removeUserCookieInfo());
    }
  }, []);


  let curMoney = useSelector(
    (store) => store.currencySlice.currentCurr
  );

  const [currencyModal, setCurrencyModal] = useState(false);
  const [change, setChange] = useState(false);
  const handleChangeEnter = () => {
    setChange(true);
  };
  const handleChangeLeave = () => {
    setChange(false);
  };

  const toggleModal = () => {
    setCurrencyModal(!currencyModal);
  };

  const moveToMobility= ()=>{
    navigate('/corporate-mobility');
  }

  // const handleCloseModal = () => {
  //   setCurrencyModal(false);
  // }

  // const payInr = () => {
  //   if (curMoney.localeCompare("AED") == 0) {
  //     // let bsr  = currencyChange("AED","INR",basrR);
  //     dispatch(aedToInr());
  //   } else if (curMoney.localeCompare("USD") == 0) {
  //     dispatch(usdToInr());
  //   }
  //   dispatch(selectIndia());
  //   setCurrencyModal(!currencyModal);
  // };

  // const payAed = () => {
  //   if (curMoney.localeCompare("INR") == 0) {
  //     dispatch(inrToAed());
  //   } else if (curMoney.localeCompare("USD") == 0) {
  //     dispatch(usdToAed());
  //   }
  //   dispatch(selectUae());
  //   setCurrencyModal(!currencyModal);
  // };
  // const payUsd = () => {
  //   if (curMoney.localeCompare("INR") == 0) {
  //     dispatch(inrToUsd());
  //   } else if (curMoney.localeCompare("AED") == 0) {
  //     dispatch(aedToUsd());
  //   }
  //   dispatch(selectUsa());
  //   setCurrencyModal(!currencyModal);
  // };

  const openLogin = () => {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(toggleLogin());
    // document.documentElement.style.overflow = "hidden";
  };

  const getCurrValue = async (curr) => {
    
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url:  `${baseUrl}/app/v1/currency/${curr}`,
      };
  
      const response = await axios.request(config);
      let data = response.data;
      return data; // Return the data
    } catch (error) {
      console.error(error);
      throw error; // You can choose to throw the error or handle it differently
    }
  };

  const payCheck = async (value) => {
    try{
      let res = await getCurrValue(value);
      let curObj = {
        currentCurr: value,
        currentCurrRate:parseFloat(res.data),
      };
      dispatch(changeCurrency(curObj));

    }catch(error){
      console.log(error);
    }

  };

  return (
    <React.Fragment>
      <div className="h-fit w-fit  flex flex-row-reverse justify-end items-center">
        <div className="hidden max-sm:block absolute right-0">
        <React.Fragment key={"right"}>
          <Button onClick={toggleDrawer("right", true)}><MenuIcon /></Button>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </React.Fragment>
    </div>
        {cookieStatus ? (
          <div
            className="flex items-center justify-between max-sm:mr-3 border border-[#0F0A34] py-1 px-3 rounded-full relative cursor-pointer"
            onClick={() => setProfileModal(!profileModal)}
          >
            <PersonOutlineOutlinedIcon className="mr-2" />
            <span className="mr-4 text-sm font-normal leading-4">{fName}</span>
            {profileModal ? (
              <KeyboardArrowUpSharpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}

            {profileModal ? <ProfileDropDown /> : <></>}
          </div>
        ) : (
          <button
          className="border rounded-full py-[8px] px-[25px] max-sm:mr-4 flex justify-center items-center font-light text-sm leading-4 text-white border-[#0F0A34] bg-[#0F0A34] cursor-pointer hover:bg-[#0F0A34] hover:text-white max-sm:p-0 max-sm:border-none max-sm:bg-transparent max-sm:text-[#0F0A34]"
            onClick={openLogin}
          >
            Log In / Sign Up
          </button>
        )}

        <div
          className="flex items-center justify-between border border-[#0F0A34] py-1 px-3 rounded-full cursor-pointer mr-6 max-sm:hidden"
          
        >
          {/* <img src={"../" + curMoney+"FL" + ".svg"} alt="" /> */}
          <span onClick={toggleModal} className="font-normal font-md">{curMoney}</span>
          {/* <KeyboardArrowDownIcon /> */}
          {currencyModal ? (
            <div>
               <div className="absolute bg-slate-500 opacity-50 top-0 left-0 w-full h-screen">
        
              </div>
            <div className="absolute top-0 h-screen flex flex-col">
                <CurrencyModal close={toggleModal} setCurrency={payCheck}/>
            </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <span
          className="font-medium text-sm mr-6 leading-4 text-[#0F0A34] cursor-pointer rounded-sm bg-white   hover:text-[#0F0A34] max-sm:hidden"
          onMouseEnter={handleChangeEnter}
          onMouseLeave={handleChangeLeave}
          onClick={moveToMobility}
        >
          {change ? "WTi For Business" : "Intelligent-Mobility"}
        </span>
      </div>
    </React.Fragment>
  );
}

export default MenuOptions;
