import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { switchOffLoader, switchOnLoader } from "../../utils/loaderSlice";
import { useSelector } from "react-redux";
import { Alert, Checkbox, Snackbar } from "@mui/material";
import PAndC from "./PAndC_New";
import EquipmentCarCont from "./EquipmentCardContNew";
import Deposit from "./DepositNew";
import { addCalculatedExtras, addModifiedPrice, addModifiedPriceWithoutCurrency, addModifyInvoiceObj, finalChargeWithCurrency, updateModifyFlag } from "../../utils/modifyBookingSlices/modifyBooking";
import axios from "axios";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { addSessionID, addStripePaymentId } from "../../utils/paymentSession";
import { baseUrl } from "../../config";
import { addCalculatedTax } from "../../utils/invoice/invoiceSlice";


const ExtraMain = ({vehicleData, allExtras}) =>{
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const reservationId = useSelector((store)=>store.reservationSlice.resId);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [text, setText] = React.useState("");
  const [vehicleObject, setVehicleObject] = React.useState(false);
  const modifyBookingObj = useSelector((store) => store.modifyBookingSlice.modifyDetails);
  const modifyReservationDays = useSelector((store) => store.modifyBookingSlice.modifyReservationDays);
  const modifiedPrice = useSelector((store) => store.modifyBookingSlice.modifiedPrice);
  const modifiedPriceWithoutCurrency = useSelector((store) => store.modifyBookingSlice.modifiedPriceWithoutCurrency);
  const calculatedExtras = useSelector((store) => store.modifyBookingSlice.calculatedExtras);
  const travelItenaryObj = useSelector((store) => store.modifyBookingSlice.travelItenaryObj);
  const modifyCarObj = useSelector((store) => store.modifyBookingSlice.modifyCarObj);
  const modifyBasePrice = useSelector((store) => store.modifyBookingSlice.modifyBasePrice);
  const finalExtrasArr = useSelector((store) => store.modifyBookingSlice.finalExtrasArr);
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const emailID = useSelector(
    (store) => store.loginSlice.emailID
  );
  const userId = useSelector((store) => store.userSlice.userId);
  const carId = useSelector((store) => store.modifyBookingSlice.carId);
  const firstName = useSelector((store) => store.userSlice.firstName);
  const lastName = useSelector((store) => store.userSlice.lastName);
  const additionalDriverPrice = useSelector((store) => store.modifyBookingSlice.additionalDriverPrice);
  const cdwPrice = useSelector((store) => store.modifyBookingSlice.cdwPrice);
  const babySeatPrice = useSelector((store) => store.modifyBookingSlice.babySeatPrice);
  const modifyExcessInsurance = useSelector((store) => store.modifyBookingSlice.modifyExcessInsurance);
  const carPickLocation = useSelector(
    (store) => store.pickDropSlice.carPickLocation.location
  );
  const carDropLocation = useSelector(
    (store) => store.pickDropSlice.carDropLocation.location
  );
  const carPickLocationRate = useSelector(
    (store) => store.pickDropSlice.carPickLocation.locationRate
  );
  const carDropLocationRate = useSelector(
    (store) => store.pickDropSlice.carDropLocation.locationRate
  );
  const calculatedTax = useSelector(
    (store) => store.invoiceSlice.calculatedTax
  );
  const [success, setSuccess] = useState(false);
  const [amount, setAmount] = useState(0)
  const [locationNotSelected, setLocationNotSelected] = useState(false)
  const [sameDateError, setSameDateError] = useState(false)
  const [userData, setUserData] = useState({})
  const [apiData, setApiData] = useState({})
  const [InvoiceObj, setInvoiceObj] = useState({})
  const [finalPayableAmount, setFinalPayableAmount] = useState(Number((Number(modifyBookingObj.totalCharges*currencyPrice)-Number(modifiedPrice)).toFixed(2)))
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
  if(vehicleData.length){
    const vehicleObj = vehicleData.find((obj) => {
      return obj.vehicleID == carId
    });

    if (vehicleObj) {
      setVehicleObject(vehicleObj);
    }
  }
  }, [modifyReservationDays, carId, vehicleData?.length]);






  useEffect(() => {
    const extrasFinal =
      Number(additionalDriverPrice * currencyPrice) +
      Number(cdwPrice * currencyPrice) +
      Number(babySeatPrice * currencyPrice);

    const extrasFinalWithoutCurrency =
      Number(additionalDriverPrice) +
      Number(cdwPrice) +
      Number(babySeatPrice);

    const finalprice = Number(
      (
        Number(modifyBasePrice * currencyPrice) +
        Number(extrasFinal) +
        Number(modifyExcessInsurance * currencyPrice) +
        Number(carPickLocationRate *currencyPrice) +
        Number(carDropLocationRate * currencyPrice)
      ).toFixed(2)
    );
 

    const finalpriceWithoutCurrency = Number(
      (
        Number(modifyBasePrice) +
        Number(extrasFinalWithoutCurrency) +
        Number(modifyExcessInsurance) +
        Number(carPickLocationRate) +
        Number(carDropLocationRate)
      ).toFixed(2)
    );

    const tax = finalprice * 0.05
    const taxWithoutCurrency = finalpriceWithoutCurrency * 0.05

    const modifiedPriceWithoutCurrency = Number((finalpriceWithoutCurrency + taxWithoutCurrency).toFixed(2));

    const modifiedPriceWithCurrency = Number((finalprice + tax).toFixed(2));
   
    dispatch(addCalculatedExtras(Number(extrasFinalWithoutCurrency)));
    dispatch(addCalculatedTax(taxWithoutCurrency));
    dispatch(addModifiedPrice(modifiedPriceWithCurrency));
    dispatch(addModifiedPriceWithoutCurrency(modifiedPriceWithoutCurrency));


    // console.log('finalExtrasArr', finalExtrasArr)
    // console.log("additionalDriverPrice", Number(additionalDriverPrice * currencyPrice))
    // console.log("cdwPrice", Number(cdwPrice * currencyPrice))
    // console.log("babySeatPrice", Number(babySeatPrice * currencyPrice))
    // console.log("modifyBasePrice", Number(modifyBasePrice * currencyPrice)); 
    // console.log("modifyExcessInsurance", Number(modifyExcessInsurance * currencyPrice) ); 
    // console.log("carPickLocationRate", Number(carPickLocationRate) *currencyPrice ); 
    // console.log("carDropLocationRate", Number(carDropLocationRate) *currencyPrice ); 
    // console.log("extrasFinal", Number(extrasFinal));
    // console.log("modifiedPriceWithCurrency", modifiedPriceWithCurrency);


    const invoiceObj = {
      baseRate: modifyBasePrice,
      excessInsuranceCharge: modifyExcessInsurance,
      deliveryCharge: carPickLocationRate,
      collectionCharge: carDropLocationRate,
      addOnCharges: extrasFinalWithoutCurrency,
      taxes: taxWithoutCurrency,
      totalCharges: modifiedPriceWithoutCurrency,
      promoCodeDeductions: 0,
      chargesAfterPromoCode: modifiedPriceWithoutCurrency,
      payType: modifyBookingObj.payType,
      paymentStatus: modifyBookingObj.payment,
      paymentMethod: modifyBookingObj.paymentMethod,
    }

    setInvoiceObj(invoiceObj)


    const data = {
      modifiedPriceWithoutCurrency: modifiedPriceWithoutCurrency,
      finalExtrasArr: finalExtrasArr,
      taxWithoutCurrency: taxWithoutCurrency
    };

    setApiData(data)

  }, [
    modifyBasePrice,
    modifyExcessInsurance,
    currencyPrice,
    additionalDriverPrice,
    cdwPrice,
    babySeatPrice,
    modifyReservationDays,
    carPickLocationRate,
    carDropLocationRate
  ]);
  
  useEffect(() => {

    // console.log('modifyBookingObj.totalCharges', modifyBookingObj.totalCharges* currencyPrice)
    setFinalPayableAmount(
      Number(
        (
          modifyBookingObj.totalCharges * currencyPrice -
          modifiedPriceWithoutCurrency * currencyPrice
        ).toFixed(2)
      )
    );
  }, [modifiedPriceWithoutCurrency, modifyReservationDays, currencyName]);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setLocationNotSelected(false)
    setSameDateError(false)
  };

  const getUserDetails = async() => {
    try {

      let data = JSON.stringify({
        userID: userId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/user/getUserDetails`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setUserData(response?.data?.result);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error While calling the API : " + error);
    }
  }

  const makeANewCustomer = async () => {
    try {
      let data = JSON.stringify({
        name: userData?.firstName + " " + userData?.lastName,
        phone: userData?.contact,
        email: userData?.emailID,
        address: {
          line1: userData?.address,
          postal_code: userData?.postalCode,
          city: userData?.city,
          country: userData?.country,
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/billing/createCustomer`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const result = await axios.request(config);

      if (result.data.customerID) {
        return result.data.customerID;
      } else {
        console.log("Error fetching customer id");
      }
    } catch (error) {
      console.log("Error making stripe customer: ", error);
    }
  };

  const checkoutPage = async (custId, custAmount, custCurr) => {
    let data = JSON.stringify({
      customerId: custId,
      amount: custAmount,
      currency: custCurr,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/billing/checkout`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        dispatch(addSessionID(response.data.sessionID));
        //created session
        //session url
        //session id
        setTimeout(() => {
          dispatch(switchOffLoader());
          window.location = response.data.sessionURL;
        }, 500)
      })
      .catch((error) => {
        console.log("Error in checkout API: ", error);
      });
  };

  const sendMailOnRefund = async () => {
    const data = JSON.stringify({
      name: firstName+ " "+ lastName,
      amount: amount,
      currencyName: currencyName,
      currencyRate: currencyPrice,
      reservationID: reservationId,
      emailId: emailID,
      travelItenary: travelItenaryObj,
      car: modifyCarObj,
      invoice: InvoiceObj,
      reservationDays: modifyReservationDays,
      extras: apiData.finalExtrasArr
    });
    

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/reservation/sendRefundMail`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    await axios.request(config)
    .then((response) => {
      console.log('Refund mail sent successfully: ', JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const modifyReservationApi = async () => {


    let data = {
      reservationID: reservationId,
      userID: userId,
      totalCharges: apiData.modifiedPriceWithoutCurrency,
      travelItenary: travelItenaryObj,
      car: {
        type: modifyCarObj.type,
        typeID: modifyCarObj.typeID,
        src: modifyCarObj.src,
        title: modifyCarObj.title,
      },
      extras: apiData.finalExtrasArr,
      reservationDays: modifyReservationDays,
      tax: apiData.taxWithoutCurrency,
      invoice: InvoiceObj,
      advanceInvoiceID: modifyBookingObj.advanceInvoiceID,
      modifiedInvoiceID: modifyBookingObj.modifiedInvoiceID,
      modifiedReservationID: modifyBookingObj.modifiedReservationID,
      paymentStatus: "Refund",
      currencyInfo: { currency: currencyName, currencyRate: currencyPrice }
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: `http://localhost:3000/app/v1/reservation/modifyReservation`,
      url: `${baseUrl}/app/v1/reservation/modifyReservation`,
      data: data
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data) {
          if(response.data.reservationUpdated == true) {
            setTimeout(async() => {
              await sendMailOnRefund()
              dispatch(switchOffLoader())
              setSuccess(true)

              setTimeout(() => {
                navigate("/")
              }, 1500)

            }, 500)
          } else {
            setOpen(true)
          }
        }
      })
      .catch((error) => {
        console.log("Error from modifyReservation: ", error.message);
      });
  }

  const handlePayment = async() => {
    handleDialogClose()
    dispatch(switchOnLoader())
    try {
    if (text === "Refund") {

      setTimeout(async() => {
        await modifyReservationApi();
      }, 500)

    } else {
      await getUserDetails();
      const stripeCustomerID = await makeANewCustomer();
      dispatch(addStripePaymentId(stripeCustomerID));
      dispatch(updateModifyFlag(true));
      await checkoutPage(stripeCustomerID, Math.abs(amount), currencyName);
    }
    } catch (error) {
      console.log(error)
    }
  };

  const moveToPayment = () => {

    if(carPickLocation == "" || carDropLocation == "") {
      setLocationNotSelected(true)
    } else if(travelItenaryObj?.pickupDate == travelItenaryObj?.dropDate) {
      setSameDateError(true)
    } else {

      dispatch(addModifyInvoiceObj(InvoiceObj))
  
      if(finalPayableAmount > 0){      
        // initiate refund
        setText("Refund")
        setAmount(finalPayableAmount)
        setOpenDialog(true);
  
      } else if(finalPayableAmount === 0){
        setOpen(true) // show error
      } else {
  
        // open payment gateway
        setText("Charge")
        setAmount(finalPayableAmount)
        dispatch(finalChargeWithCurrency(Math.abs(finalPayableAmount)))
        setOpenDialog(true);
  
      }
    }

  };

  return (
    <React.Fragment>
      <div className="flex w-full justify-between items-center flex-wrap mt-6">
        <div className="w-full mb-3">
          <h3 className="text-[32px] ms-3 text-[#000000] font-semibold leading-[32px]">
            Choose Extras
          </h3>
        </div>
        <div className=" flex w-full justify-between flex-wrap">
          <div className="pl-4">
            {vehicleObject && <PAndC vehicleObject={vehicleObject} />}
            {allExtras.length && <EquipmentCarCont extras={allExtras} />}
            {vehicleObject && <Deposit carObj={vehicleObject} />}
          </div>
          <div className="flex justify-end items-center mt-8 mb-8 pl-4">
            <div
              className="w-fit max-sm:w-full max-sm:text-center rounded px-8 py-2 text-white cursor-pointer bg-[#0077B6]"
              onClick={moveToPayment}
            >
              {parseInt(finalPayableAmount) > 0 ? (
                <div>
                  {`Modify & Get Refund of `}
                  <span className="underline">
                    {Math.abs(finalPayableAmount)} {currencyName}
                  </span>
                </div>
              ) : parseInt(finalPayableAmount) < 0 ? (
                <div>
                  {`Modify & Pay `}
                  <span className="underline">
                    {Math.abs(finalPayableAmount)} {currencyName}
                  </span>
                </div>
              ) : (
                <div>Modify & Book Now</div>
              )}
            </div>
          </div>
        </div>

        {/* On click payment open dialog if refund */}
        <>
          <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Are you sure you want to modify booking?
            </DialogTitle>
            <DialogContent>
              {text === "Refund" ? (
                <DialogContentText>
                  On continue you will get refund of <b>{currencyName}{" "}
                  {Math.abs(amount)}</b>
                </DialogContentText>
              ) : (
                <DialogContentText>
                  On continue you have to pay <b>{currencyName}{" "}
                  {Math.abs(amount)}</b>
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} autoFocus>
                Cancel
              </Button>
              <Button autoFocus onClick={handlePayment}>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </>
        <Snackbar open={success} autoHideDuration={3000}>
          <Alert variant="filled" severity="success">
            Your booking modified succesfully.
          </Alert>
        </Snackbar>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Oops! It seems you have not modified booking yet.
          </Alert>
        </Snackbar>
        <Snackbar
          open={locationNotSelected}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Oops! It seems you forgot to select a pick or drop location.
          </Alert>
        </Snackbar>
        <Snackbar
          open={sameDateError}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Oops! It seems you have same selected pick and drop dates.
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}

export default ExtraMain;
