import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import VehicleTypeImg from "../../../images/vehicle_type_img.svg";
import ArrowDownImg from "../../../images/arrow_drop_down_img.png";
import ArrowBackImg from "../../../images/arrow_back_icon.svg";
import ReloadIcon from "../../../images/reload_icon.svg";
import "./Booking.css";
import SearchEngineModify from "./SearchEngineModify";
import VehicleCard from "./VehicleCard";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { switchOnLoader } from "../../../utils/loaderSlice";
import ExtraMain from "../../extras/ExtraMainNew";
import CarFeaturesModal from "../../carSelectType/horizontalStepper/CarFeaturesModal";
import { addInsuranceArr } from "../../../utils/insuranceSlice";
import { addCarId, addModifyBasePrice, addModifyCarDetail, addModifyExcessInsurance, addModifyExtras, addModifyFullDeposit, addModifyNoDeposit, emptyModifyExtras, removeModifyExtras, toggleAdditionalDriver, toggleAdditionalDriverCheck, toggleBabySeat, toggleBabySeatCheck, toggleCDW, toggleCDWCheck } from "../../../utils/modifyBookingSlices/modifyBooking";
import { addExcessInsurance } from "../../../utils/invoice/invoiceSlice";
import { updateCarDropLocation, updateCarPickLocation } from "../../../utils/picDropSlices/pickDropSlice";



const calculatePrice = (indexValue, days, equipmentsObj)=>{


  if(indexValue == 2){
    if(days>=7 && days<=21){
      let actualPrice = equipmentsObj?.price.daily-10;
      let totalPrice = actualPrice*days;
      if (totalPrice <= equipmentsObj?.price.maximum) {
        return Number(totalPrice.toFixed(2));
      } else {
        return Number((equipmentsObj?.price.maximum).toFixed(2));
      }
      
    }else if (days>=30){
      let totalMonths = Math.floor(days / 30); // Extract whole months
      let remainingDays = days % 30; 
      let monthsPrice = Number((equipmentsObj?.price.maximum).toFixed(2))*totalMonths;
      let daysPrice = remainingDays*(equipmentsObj?.price.daily-10);
      let total = monthsPrice+daysPrice
      return Number((total).toFixed(2));
    } else {
      if(days>21 && days<30){
      return Number((equipmentsObj?.price.maximum).toFixed(2));
      }else{
        let totalPrice = equipmentsObj?.price.daily*days;
        return Number((totalPrice).toFixed(2));
      }      }
  }
  if(indexValue == 3){
    if(days>30 && days<61){
      let daysRemainigFromMonth = days-30;
      let remainingDaysRate = daysRemainigFromMonth*1.3;
      let finalPrice = remainingDaysRate+equipmentsObj?.price.daily;
      return Number((finalPrice).toFixed(2));
    }
    if(days>=30){
      let totalMonths = days/30;
      return (Number((equipmentsObj?.price.daily).toFixed(2))*totalMonths);
    }else{
      return Number((equipmentsObj?.price.daily).toFixed(2));
    }
  }
}


const ModifyBooking = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modifyBookingObj = useSelector(
    (store) => store.modifyBookingSlice.modifyDetails
  );
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);

  const modifyReservationDays = useSelector(
    (store) => store.modifyBookingSlice.modifyReservationDays
  );
  const cdwCheck = useSelector((store) => store.modifyBookingSlice.cdwCheck);
  const carId = useSelector((store) => store.modifyBookingSlice.carId);
  const fullDepositCheck = useSelector((store) => store.invoiceSlice.depositChargesBoolean.fullDepositCheck);
  const depositPremiumCheck = useSelector((store) => store.invoiceSlice.depositChargesBoolean.depositPremiumCheck);

  const carPickLocationRate = useSelector(
    (store) => store.pickDropSlice.carPickLocation.locationRate
  );
  const carDropLocationRate = useSelector(
    (store) => store.pickDropSlice.carDropLocation.locationRate
  );
  const modifyBasePrice = useSelector((store) => store.modifyBookingSlice.modifyBasePrice);
  const modifyExcessInsurance = useSelector((store) => store.modifyBookingSlice.modifyExcessInsurance);

  const calculatedTax = useSelector(
    (store) => store.invoiceSlice.calculatedTax
  );
  const calculatedExtras = useSelector((store) => store.modifyBookingSlice.calculatedExtras);
  const modifiedPriceWithoutCurrency = useSelector((store) => store.modifyBookingSlice.modifiedPriceWithoutCurrency);

  const [typeID, setTypeID] = useState(modifyBookingObj?.car?.typeID)

  const [minRange, setMinRange] = React.useState(0);
  const [maxRange, setMaxRange] = React.useState(currencyName == "INR" ? 5000*modifyReservationDays : 500*modifyReservationDays);
  // const [barLimit, setBarLimit] = React.useState({l1: 1000, l2:8000});
  const [value, setValue] = React.useState([minRange, maxRange]);
  const [vehicleData, setVehicleData] = useState([]);
  const [vehicleCardData, setVehicleCardData] = useState([]);
  const [limitedCard, setLimitedCard] = useState(9);
  const [allExtras, setAllExtras] = useState([]);
// car details modal status
const detailModalStatus = useSelector(
  (store) => store.modalToggleSlice.carDetail
);

  useEffect(() => {
    if(currencyName == "AED"){
      setMaxRange(500*modifyReservationDays)      
      setValue([0,500*modifyReservationDays])
    } else if(currencyName == "INR"){
      setMaxRange(5000*modifyReservationDays)
      setValue([0,5000*modifyReservationDays])
    }
  }, [currencyName, modifyReservationDays]);

  useEffect(() => {
    dispatch(switchOnLoader());
    
    const carDetail = {
      type: modifyBookingObj.car.type,
      typeID: modifyBookingObj.car.typeID,
      src: modifyBookingObj.car.src,
      title: modifyBookingObj.car.title,
      basePrice: modifyBookingObj.baseRate
    };

    dispatch(addCarId(modifyBookingObj.car.typeID))
    dispatch(addModifyCarDetail(carDetail))
    getAllExtras();
  }, []);


  useEffect(() => {

    dispatch(addExcessInsurance(modifyBookingObj.excessInsuranceCharge))
    
    if (modifyBookingObj.extras.length) {
      modifyBookingObj.extras?.forEach((obj) => {
        dispatch(addModifyExtras(obj))
      })
      const cdw = modifyBookingObj.extras.find((ele) => {
        return ele.id == "01";
      });
      if (cdw) {
        const firstPriceKey = Object.keys(cdw.price)[0];
        const firstPriceValue = cdw.price[firstPriceKey];

        const cdwObj = {
          cdwPrice: firstPriceValue? parseFloat(firstPriceValue*modifyBookingObj.reservationDays) : 0,
          cdwState: true,
        };
        dispatch(toggleCDW(cdwObj));
        dispatch(toggleCDWCheck(true));
      } else {
        const cdwObj = {
          cdwPrice: 0,
          cdwState: false,
        };
        dispatch(toggleCDW(cdwObj));
        dispatch(toggleCDWCheck(false));
        dispatch(removeModifyExtras("01"));
      }

      const driver = modifyBookingObj.extras.find((ele) => {
        return ele.id == "05";
      });
      
      if (driver) {
        const driverPrice = calculatePrice(3, modifyBookingObj.reservationDays, driver)
        const additionalDriverObj = {
          additionalDriverPrice: driverPrice? Number(driverPrice) : 0,
          additionalDriverState: true,
        };
        dispatch(toggleAdditionalDriver(additionalDriverObj));
        dispatch(toggleAdditionalDriverCheck(true));
      } else {
        const additionalDriverObj = {
          additionalDriverPrice: 0,
          additionalDriverState: false,
        };
        dispatch(toggleAdditionalDriver(additionalDriverObj));
        dispatch(toggleAdditionalDriverCheck(false));
        dispatch(removeModifyExtras("05"));
      }

      const babySeat = modifyBookingObj.extras.find((ele) => {
        return ele.id == "03";
      });
      if (babySeat) {
        
        const babySeatPrice = calculatePrice(2, modifyBookingObj.reservationDays, babySeat)
        const babySeatObj = {
          babySeatPrice: babySeatPrice? Number(babySeatPrice) : 0,
          babySeatState: true,
        };
        dispatch(toggleBabySeat(babySeatObj));
        dispatch(toggleBabySeatCheck(true));
      } else {
        const babySeatObj = {
          babySeatPrice: 0,
          babySeatState: false,
        };
        dispatch(toggleBabySeat(babySeatObj));
        dispatch(toggleBabySeatCheck(false));
        dispatch(removeModifyExtras("03"));
      }
    } else {
        // const cdwObj = {
        //   cdwPrice: 0,
        //   cdwState: false,
        // };
        // dispatch(toggleCDW(cdwObj));
        // dispatch(toggleCDWCheck(false));
        const babySeatObj = {
          babySeatPrice: 0,
          babySeatState: false,
        };
        dispatch(toggleBabySeat(babySeatObj));
        dispatch(toggleBabySeatCheck(false));
        
        const additionalDriverObj = {
          additionalDriverPrice: 0,
          additionalDriverState: false,
        };
        dispatch(toggleAdditionalDriver(additionalDriverObj));
        dispatch(toggleAdditionalDriverCheck(false));
        dispatch(emptyModifyExtras());
      }
      dispatch(
        updateCarPickLocation({
          location: modifyBookingObj.travelItenary?.pickupLocation,
          locationRate: modifyBookingObj.deliveryCharge ? modifyBookingObj.deliveryCharge : 0,
        })
      );
      dispatch(
        updateCarDropLocation({
          location: modifyBookingObj.travelItenary?.dropLocation,
          locationRate: modifyBookingObj.collectionCharge ? modifyBookingObj.collectionCharge : 0,
        })
      );
  }, [])



  useEffect(() => {

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/vehicles/getSelfVehicles/${modifyReservationDays}`,
    };
   
      axios
        .request(config)
        .then((response) => {
          if (response.data.result.length) {

            const vehicleObj = response.data.result?.find((obj) => {
              return obj.vehicleID == carId
            });

            if(modifyBookingObj?.reservationStatus == 'Enroute'){
              setVehicleData([vehicleObj]);
              setVehicleCardData([vehicleObj])
            } else {
              let resArr = response?.data?.result
              const index = resArr?.findIndex((item) => {
                return item.vehicleID == carId;
              });
              if (index !== -1) {
                const [matchedItem] = resArr?.splice(index, 1);
                resArr?.unshift(matchedItem);
              }
              setVehicleData(resArr);
              setVehicleCardData(resArr)
            }

        
            if (vehicleObj) {

              let payNowPriceActual = 0;
              let percentageHikePayNowActual = 0;

              if (vehicleObj?.payNowPrice?.daily) {
                payNowPriceActual = vehicleObj?.payNowPrice?.daily;
                percentageHikePayNowActual = vehicleObj?.percentageHikePayNow?.daily;
              } else if (vehicleObj?.payNowPrice?.weekly) {
                payNowPriceActual = vehicleObj?.payNowPrice.weekly;
                percentageHikePayNowActual = vehicleObj?.percentageHikePayNow?.weekly;
              } else if (vehicleObj?.payNowPrice?.monthly) {
                payNowPriceActual = vehicleObj?.payNowPrice.monthly;
                percentageHikePayNowActual = vehicleObj?.percentageHikePayNow.monthly;
              }

              const basePrice =
                (payNowPriceActual +
                  (payNowPriceActual * percentageHikePayNowActual) / 100) *
                modifyReservationDays;


              const nodeposit =
                vehicleObj?.excessInsuranceChargePremiumPercentage
                  ?.threeDaysOrLess ||
                vehicleObj?.excessInsuranceChargePremiumPercentage
                  ?.weeklyOrMore ||
                vehicleObj?.excessInsuranceChargePremiumPercentage
                  ?.monthlyOrLess ||
                vehicleObj?.excessInsuranceChargePremiumPercentage
                  ?.moreThanMonth;

                  
                  if(cdwCheck){
                    
                    const firstPriceKey = Object.keys(vehicleObj.insuranceAndDamageCovers[0].price)[0];
                    const firstPriceValue = vehicleObj.insuranceAndDamageCovers[0].price[firstPriceKey];
                    const cdwObj = {
                      cdwPrice: Number(firstPriceValue*modifyReservationDays),
                      cdwState: true,
                    };
                    
                    dispatch(toggleCDW(cdwObj));
                  }

                  if (depositPremiumCheck) {
                    dispatch(
                      addModifyExcessInsurance(nodeposit)
                    );
                  } 
                  if (fullDepositCheck) {
                    dispatch(
                      addModifyExcessInsurance(vehicleObj.excessInsuranceCharge)
                    );
                  }
              dispatch(addModifyNoDeposit(nodeposit));
              dispatch(addModifyFullDeposit(vehicleObj.excessInsuranceCharge));
              dispatch(addInsuranceArr(vehicleObj.insuranceAndDamageCovers));
              dispatch(addModifyBasePrice(basePrice));
              if(cdwCheck){
                dispatch(addModifyExtras(vehicleObj.insuranceAndDamageCovers[0]));
              }
            }
          }
        })
        .catch((error) => {
          console.log(
            "Error from api - /app/v1/vehicles/getAllSelfVehicles: ",
            error.message
          );
        });
    
  }, [modifyReservationDays, carId]);

  

  const handleFilterChange = (event, newValue) => {
    event.preventDefault()
    try {

      setValue(newValue);

      if (vehicleData.length) {
        const filteredItems = vehicleData?.filter((item) => {
          const { payNowPrice, percentageHikePayNow } = item;

          let payNowPriceActual = 0;
          let percentageHikePayNowActual = 0;
          if (payNowPrice?.daily) {
            payNowPriceActual = payNowPrice?.daily;
            percentageHikePayNowActual = percentageHikePayNow?.daily;
          } else if (payNowPrice?.weekly) {
            payNowPriceActual = payNowPrice.weekly;
            percentageHikePayNowActual = percentageHikePayNow?.weekly;
          } else if (payNowPrice?.monthly) {
            payNowPriceActual = payNowPrice.monthly;
            percentageHikePayNowActual = percentageHikePayNow.monthly;
          }

          let total =
            (payNowPriceActual +
              (payNowPriceActual * percentageHikePayNowActual) / 100) *
            modifyReservationDays *
            currencyPrice;

          return total?.toFixed(2) >= newValue[0] && total?.toFixed(2) <= newValue[1]
       
        });
        setVehicleCardData(filteredItems);
      }
  } catch (error) {
    console.log("Error from filter: ", error.message)
  }
  };

  const getAllExtras = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/extras/getAllExtras`,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.result.length) {
          setAllExtras(response.data.result);
        }
      })
      .catch((error) => {
        console.log("Error from getAllExtras: ", error.message);
      });
  };



  return (
    <div className=" h-full px-3 bg-[#efefef] pb-10 pt-2">
      <div className="xl:w-[1200px] w-full mx-auto mt-[50px]">
        <div className="flex flex-col gap-3 w-full">
          <div className="flex flex-col items-start gap-6 w-full">
            <button
              className="flex gap-2 items-center text-[#0193C5] font-medium bg-[#fff] py-2 px-4 rounded-[4px] text-[18px] shadow-[0px_3px_12px_0px_#0000001F] leading-[16px]"
              onClick={() => navigate(-1)}
            >
              <img src={ArrowBackImg} className="h-[24px]" alt="" /> Back
            </button>
            <SearchEngineModify />
          </div>
          {/* <div className='flex mt-12 flex-wrap'> */}
          <div className="grid lg:grid-cols-[24%_76%] grid-cols-1 mt-8">
            <div className="w-full pb-4">
              <div className="lg:flex md:w-[100%]  flex-col gap-6 w-full bg-[#fff] rounded-[20px] shadow-[0px_3px_30px_0px_#00000029] p-3 sticky top-[70px] ">
                <div className="border-b border-[#EFEFEF] py-3">
                  <h3 className="text-[#0077B6] text-center text-[20px] font-semibold leading-[20px]">
                    Filters
                  </h3>
                </div>
                <div>
                  <h4 className="text-[18px] mb-2 text-[#222222] font-medium leading-[18px]">
                    Price Range
                  </h4>
                  <div className="price-range-wrapper px-2">
                    <Slider
                      value={value}
                      onChange={handleFilterChange}
                      valueLabelDisplay="auto"
                      step={currencyName == 'AED' ? 100 : 1000}
                      min={minRange}
                      max={maxRange}
                    />
                    <div className="flex justify-between items-center">
                      <div className="bg-[#FFFFFF] py-2 px-4 shadow-[0px_3px_12px_0px_#0000001F]">
                        <span className="text-[#5F5F5F] text-[16px] font-normal leading-[16px]">
                          {value[0]}
                        </span>
                      </div>
                      <span className="text-[#5F5F5F] text-[32px] font-semibold leading-[16px]">
                        -
                      </span>
                      <div className="bg-[#FFFFFF] py-2 px-4 shadow-[0px_3px_12px_0px_#0000001F]">
                        <span className="text-[#5F5F5F] text-[16px] font-normal leading-[16px]">
                          {value[1]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <h4 className="text-[18px] mb-4 text-[#222222] font-medium leading-[20px]">
                    Vehicle Body Type
                  </h4>
                  <div className="grid grid-cols-2 gap-4">
                    {[1, 2, 3, 4].map((type, index) => {
                      return (
                        <div
                          className="flex flex-col items-center hover:border-[#0077B6] hover:cursor-pointer gap-3 py-5 border-[2px]  border-[#7e7e7ec4] rounded-[4px]"
                          key={index}
                        >
                          <img src={VehicleTypeImg} alt="" />
                          <span className="text-[14px] text-[#222222] font-medium leading-[14px]">
                            Convertible
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div> */}
                {/* <div>
                  <button className="border-none flex justify-between items-center ps-3 text-[#5F5F5F] w-full py-2 text-center text-[16px] font-normal leading-[16px] bg-[#fff] shadow-[0px_3px_4px_0px_#0000001F] rounded-[4px]">
                    All Transmission <img src={ArrowDownImg} alt="" />
                  </button>
                  <div className="w-full mt-2">
                    <button className="border-none flex justify-between items-start ps-3 text-[#5F5F5F] w-full py-1">
                      All Transmission
                    </button>
                    <button className="border-none flex justify-between items-start ps-3 text-[#5F5F5F] w-full py-1">
                      All Transmission
                    </button>
                    <button className="border-none flex justify-between items-start ps-3 text-[#5F5F5F] w-full py-1">
                      All Transmission
                    </button>
                    <button className="border-none flex justify-between items-start ps-3 text-[#5F5F5F] w-full py-1">
                      All Transmission
                    </button>
                  </div>
                </div> */}
                <button
                  className="border-none text-[#FFFFFF] py-2 px-1 mt-2 mb-3 text-center text-[16px] lg:text-[18px] font-normal leading-[20px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8] rounded-[4px]"
                  onClick={() => {
                    setValue([minRange, maxRange]);
                    setVehicleCardData(vehicleData);
                  }}
                >
                  Reset Filter
                </button>
              </div>


          <div className="w-full sticky top-[350px] px-0 lg:-mt-[0] mt-[4px] h-auto pt-4">
          <div className=" py-6 px-4 bg-[#fff] lg:rounded-t-[20px] rounded-t-[0] rounded-[20px]  lg:shadow-[0px_3px_20px_0px_#00000029] h-full">
            <div className="flex flex-col gap-1 w-full  ">
              <div className="flex flex-col gap-1 mt-1">
                <h4 className="text-[#222222] text-[16px] font-medium leading-[24px]">
                  Included in the rates
                </h4>
                <div className="w-full flex gap-0 justify-between  flex-col">
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Base Rate
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (modifyBasePrice * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Deposit Premium
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (modifyExcessInsurance*currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Delievery Charge
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (carPickLocationRate * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Collection Charge
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (carDropLocationRate * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Extras
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (calculatedExtras * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Taxes
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {" "}
                      {currencyName + " " + (calculatedTax * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-3 ">
                  <div className="flex justify-between items-center bg-[#F6F6F6] rounded-[8px] py-2 px-3">
                    <h4 className="text-[#222] tracking-[1px] text-[14px] font-normal leading-[24px]">
                      Total
                    </h4>
                    <h4 className="text-[#0088c0] text-[14px] font-semibold leading-[24px]">
                      {currencyName} {(modifiedPriceWithoutCurrency * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





              {/* <div className="block lg:hidden">
                <div>
                  <div className="flex flex-col gap-6 w-full  border-[1px] border-[#C1C1C1] bg-[#eeeeee]  rounded-[20px] shadow-[0px_3px_30px_0px_#00000029] p-3 sticky top-[70px]">
                    <div className="w-full px-2 py-2">
                      <h4 className="text-[16px] mb-2 text-[#222222] font-medium leading-[18px]">
                        Price Range
                      </h4>
                      <div className="price-range-wrapper">
                        <Slider
                          value={value}
                          onChange={handleFilterChange}
                          valueLabelDisplay="auto"
                        />
                        <div className="flex justify-between items-center">
                          <div className="bg-[#FFFFFF] py-1 px-4 rounded-[4px] shadow-[0px_3px_12px_0px_#0000001F]">
                            <span className="text-[#5F5F5F] text-[16px] font-normal leading-[16px]">
                              00000sxss
                            </span>
                          </div>
                          <span className="text-[#5F5F5F] text-[32px] font-semibold leading-[16px]">
                            -
                          </span>
                          <div className="bg-[#FFFFFF] py-1 px-4 rounded-[4px] shadow-[0px_3px_12px_0px_#0000001F]">
                            <span className="text-[#5F5F5F] text-[16px] font-normal leading-[16px]">
                              99999
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
              {/* <div className="flex gap-3 justify-between flex-wrap items-center my-6">
                    <div className="w-full">
                      <button className="border-[2px] border-[#CECECE] flex justify-between items-center ps-3 text-[#5F5F5F] w-full py-1 text-center text-[16px] font-normal leading-[16px] bg-transparent  rounded-[12px] whitespace-nowrap">
                        Vehicle Body Type <img src={ArrowDownImg} alt="" />
                      </button>
                    </div>
                    <div className="w-full">
                      <button className="border-[2px] border-[#CECECE] flex justify-between items-center ps-3 text-[#5F5F5F] w-full py-1 text-center text-[16px] font-normal leading-[16px] bg-transparent  rounded-[12px] whitespace-nowrap">
                        All Transmission <img src={ArrowDownImg} alt="" />
                      </button>
                    </div>
                  </div> */}
              {/* </div>
              </div> */}
            </div>

            {vehicleCardData?.length ? (
              <div className="">
                {/* <div className='flex justify-center items-center flex-wrap px-3'> */}
                <div className="grid w-full lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                  {vehicleCardData?.slice(0, limitedCard).map(
                    (vehicleObj, index) => {
                      return (
                        <div key={index}>
                          <VehicleCard
                            vehicleObj={vehicleObj}
                            index={index}
                            typeID={typeID}
                            setTypeID={setTypeID}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
                {vehicleCardData?.length > 9 && (
                  <div className="flex justify-center">
                    <button
                      className="flex gap-2 items-center text-[#0193C5] mx-auto font-medium bg-transparent py-2 px-4 rounded-[4px] text-[18px] underline leading-[16px]"
                      onClick={() => {
                        setLimitedCard(
                          limitedCard === 9 ? vehicleCardData?.length : 9
                        );
                      }}
                    >
                      {limitedCard === 9 ? "Show More" : "Show Less"}
                      <img className="h-[24px]" src={ReloadIcon} alt="" />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {vehicleData.length && allExtras.length && (
            <ExtraMain
              vehicleData={vehicleData}
              allExtras={allExtras}
            />
          )}
          
        {detailModalStatus ? <CarFeaturesModal /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default ModifyBooking;
