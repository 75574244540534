import React from 'react'

function FleetHeading() {
  return (
    <div className='w-full  pl-[10.27%] pr-[10.27%] flex justify-center pt-6 pb-2'>
        <span className=' font-bold text-2xl text-[#0F0A34]'><h2>Elevate your journey with our cutting-edge fleet!</h2></span>
    </div>
  )
}

export default FleetHeading;