// import React from 'react';
// import FeatureVehicleCarImg from '../../images/feature_vehicle_img1.png';
// import VehicleTypeIcon from '../../images/vehicle_type_icon.png';
// import VehicleAcIcon from '../../images/vehicle_ac_icon.png';
// import VehicleSeatCapacityIcon from '../../images/vehicle_seat_capacity_icon.png';


import FeatureVehicleCarImg from "../../images/feature_vehicle_img1.png";
import VehicleTypeIcon from '../../images/vehicle_type_icon.png';
import VehicleAcIcon from '../../images/vehicle_ac_icon.png';
import VehicleSeatCapacityIcon from '../../images/vehicle_seat_capacity_icon.png';


const vehicleFeatureArr = [
    {
        icon: VehicleTypeIcon,
        text: "Sedan"
    },
    {
        icon: VehicleAcIcon,
        text: "Ac"
    },
    {
        icon: VehicleTypeIcon,
        text: "SUV"
    },
    {
        icon: VehicleSeatCapacityIcon,
        text: "4 Seater"
    },
]
const FeatureVehicleCard = () => {
    return (
        <div className='card-wrapper w-full bg-[#ffffff]  rounded-[12px] overflow-hidden'>
            <div className='h-[148%] w-[160%] absolute rotate-[351deg] -bottom-[82%] -left-[30%] bg-gradient-to-r from-[#03045E] to-[#00D5FF]'></div>
            <div className='card flex flex-col items-center gap-10 p-4 relative z-[10]'>
                {/* ======Car Name ======== */}
                <div className='w-full'>
                    <div className='flex gap-2'>
                        <h3>Kaiyi X3 Pro  </h3>
                        <span>|</span>
                        <h3>2024</h3>
                    </div>
                    <div className='w-[130px] h-[5px] rounded-[8px] bg-gradient-to-r from-[#03045E] to-[#00D5FF] mt-[16px]'>
                    </div>
                    <div className='w-[80px] h-[5px] rounded-[8px] bg-gradient-to-r from-[#03045E] to-[#00D5FF] mt-[8px]'>
                    </div>
                </div>
                <div>
                    <img src={FeatureVehicleCarImg} alt="" />
                </div>
                <div className='flex gap-2 items-center w-full'>
                    {
                        vehicleFeatureArr.map((el, index) => {
                            return (
                                <div className='grow backdrop-filter backdrop-blur-[12px] rounded-[8px] bg-[#FFFFFF33] shadow-[0px_3px_12px_0px_#0000001F]' key={index}>
                                    <div className='flex flex-col items-center py-1'>
                                        <img src={el.icon} alt="" className='w-[40px] h-[40px]' />
                                        <span className='text-center text-[#fff] font-medium'>{el.text}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <button className='w-full bg-white text-[#0077B6] text-[24px] font-semibold py-1 rounded-[12px]'>Book Now</button>

            </div>

        </div>
    )
}

export default FeatureVehicleCard
