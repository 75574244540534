import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { switchOffLoader, switchOnLoader } from "../../utils/loaderSlice";
import axios from "axios";
import EquipmentCard from "./EquipmentCard";
import { addAllEquipments } from "../../utils/insuranceSlice";
import { ChecklistRtlRounded } from "@mui/icons-material";
import { baseUrl } from "../../config";

const Deposit = ({
  carObj,
  setDepositcheck,
  depositcheck,
  count,
  setCount,
}) => {
  const dispatch = useDispatch();
  // const equipmentObject = useSelector((store)=>store.extraSlice.extraMainObj[1])
  const invoiceObj = useSelector((store) => store.invoiceSlice.depositChargesBoolean);
  const [depositObject, setDeposittObject] = useState([]);
  const [selectedDepositType, setSelectedDopsitType] = useState(null);
  const [checkToggle, setCheckToggle] = useState(null);

  //   const [depositData, setDepositData] = useState([]);




  const [fullyDeposit, setFullyDeposit] = useState(false);
  const [premiumDeposit, setPremiumDeposit] = useState(false);

  const toggleCheck = () => {
    setCheckToggle(!checkToggle);
  };

  const checkTrue = () => {
    setCheckToggle(true);
  };

  const depositData = [
    {

      id: "03",
      name: "Full Deposit (Refundable)",
      type: "fully_deposit",
      title: "Full Deposit (Refundable)",
      checked: false,
      img: "babySeat",
      description:
        "Reserve now with a full deposit, refunded upon return.",
      price: {
        daily: carObj["excessInsuranceCharge"],
      },
    },
    {
      id: "05",
      name: "No Deposit Premium",
      type: "deposit_premium",
      title: "No Deposit Premium",
      checked: false,
      img: "additionalDriver",
      description:
        "Skip the deposit, choose our convenient premium option.",
      price: {
        daily:
          carObj?.excessInsuranceChargePremiumPercentage?.threeDaysOrLess ||
          carObj?.excessInsuranceChargePremiumPercentage?.weeklyOrMore ||
          carObj?.excessInsuranceChargePremiumPercentage?.monthlyOrLess ||
          carObj?.excessInsuranceChargePremiumPercentage?.moreThanMonth,
      },
    },
  ];



  useEffect(() => {
    if (invoiceObj.fullDepositCheck == true) {
      setFullyDeposit(true);
    }
    if (invoiceObj.depositPremiumCheck == true) {
      setPremiumDeposit(true);
    }
  }, [])

  useEffect(() => {
    dispatch(switchOnLoader());
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/extras/getAllExtras`,
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(switchOffLoader());

        setDeposittObject(response.data.result);
        dispatch(addAllEquipments(response.data.result));
      })
      .catch((error) => { });
  }, []);
  return (
    <>
      <div className="w-full pt-4">
        <span className="font-semibold leading-5">Deposit</span>
        <div className="grid grid-cols-2 gap-4 mt-2 max-sm:grid-cols-1">
          {/* <EquipmentCard equipmentsObj={equipmentsObj.equipmentOne} img={"gps.png"} />
                    <EquipmentCard equipmentsObj={equipmentsObj.equipmentTwo} img={"childSeat.png"}/> */}
          {/* {depositData?.map((e, index) => (
            <EquipmentCard
              equipmentsObj={e}
              count={count}
              setCount={setCount}
              depositData={depositData}
              setDepositcheck={setDepositcheck}
              depositcheck={depositcheck}
              sectionType={"Deposit"}
              indexValue={index}
            />
          ))} */}

          <EquipmentCard
            equipmentsObj={depositData[0]}
            currentFullyDepositState={fullyDeposit}
            setFullyDepositState={setFullyDeposit}
            currentDepositPremiumState={premiumDeposit}
            setDepositPremiumState={setPremiumDeposit}

            setDepositcheck={setDepositcheck}
            depositcheck={depositcheck}
            sectionType={"Deposit"}
            indexValue={0}
          />
          <EquipmentCard
            equipmentsObj={depositData[1]}


            currentDepositPremiumState={premiumDeposit}
            setDepositPremiumState={setPremiumDeposit}
            currentFullyDepositState={fullyDeposit}
            setFullyDepositState={setFullyDeposit}


            setDepositcheck={setDepositcheck}
            depositcheck={depositcheck}
            sectionType={"Deposit"}
            indexValue={1}
          />
        </div>
      </div>
    </>
  );
};

export default Deposit;
