import React from "react";
import PAndCCard from "./PAndCCard";
import { useSelector } from "react-redux";
import { useState,useMemo } from "react";
import { useEffect } from "react";

function PAndC(){
    const [extraObject,setExtraObject] = useState([]);
    const [cdwCheck, setCdwCheck] = useState(false);
    const insuranceArr = useSelector((store)=>store.insuranceSlice.insuranceArr);
   
    const totalDays = useSelector(
        (store) => store.bookingInfoSlice.totalNumberOfDays
      );

      
    useEffect(()=>{

        let depositProtectionObj = {
            checked: true,
            description: "A minimal fee substituting conventional security deposits in car rentals, offering our customers affordability, ease, and a hassle-free experience",
            id: "00",
            img: "",
            name: "No-Deposit Premium",
            price: {
              daily: 40,
              _id: '655c56aa8055c9126e2d4a57'
            },
            title: "No-Deposit Premium"
          };
      
        // setExtraObject(insuranceArr);
        setExtraObject([]);
        // setExtraObject((prevExtraObject) => [...prevExtraObject, depositProtectionObj,insuranceArr[0]]);
        setExtraObject((prevExtraObject) => [...prevExtraObject,insuranceArr[0]]);
        // setExtraObject(insuranceArr);
    }, [])
  
    
   
   
  
    return(
        <React.Fragment>
            <div className="w-full pt-4">
                <span className="font-semibold leading-5">Protection & Coverages</span>
                <div className="grid grid-cols-2 gap-4 mt-2 max-sm:grid-cols-1">
                    {/* <PAndCCard  equipmentsObj={equipmentsObj.equipmentOne}/>
                    <PAndCCard  equipmentsObj={equipmentsObj.equipmentTwo}/> */}
                    {extraObject.map((e)=><PAndCCard equipmentsObj={e} cdwCheck={cdwCheck} setCdwCheck={setCdwCheck}/>)}
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default PAndC;