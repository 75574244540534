import React from "react";
import FaqForm from "./FaqForm";
import AdditionalInfo from "./AdditionalInfo";


function Faq(){
    return(
        <React.Fragment>
            <div className="w-full pl-[10.27%] pr-[10.27%]  flex justify-between items-start ">
                <FaqForm/>
                {/* <AdditionalInfo/> */}
            </div>


        </React.Fragment>
    )
}

export default Faq;