import React from 'react'
import QoutesImg from "../../images/qoutes_img.png"
import ClientImg from "../../images/client_img.png"
import StarImg from "../../images/star_img.png"
import ClientWaveImg from "../../images/client_wave_img.png"
import "./index.css"



const TestimonialCard = () => {
    return (
        <div className='w-full p-4'>
            <div className='bg-white w-full  rounded-[12px]'>

            <div className='flex gap-2 flex-col p-4 '>
                <img src={QoutesImg} className='w-[30px] h-[25px]' alt="" />
                <div className=''>
                    <p className='text-[#636363] text-[16px] reading-[24px] font-normal'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                </div>
            </div>
            <div className='py-4 bg-img client-wave-img rounded-b-[12px] bg-client-wave-image bg-image'>
                <div className='flex flex-col items-center gap-3'>
                    <div>
                        <img src={ClientImg} alt="" />
                    </div>
                    <div className='flex flex-col items-center gap-1'>
                        <h4 className='text-[#ffffff] text-[24px] leading-[24px] font-normal'>Harsh </h4>
                        <h5 className='text-[#B8B8B8] text-[16px] leading-[24px] font-normal'>CEO, Wallmart</h5>
                        <div className='flex justify-center items-center gap-[4px] mb-2'>
                            <img src={StarImg} alt="" />
                            <img src={StarImg} alt="" />
                            <img src={StarImg} alt="" />
                            <img src={StarImg} alt="" />
                            <img src={StarImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default TestimonialCard
