import React from 'react';
import header from "./aboutBanner.png";

function FaqHeading() {
  return (
    <div className="w-full h-[250px] bg-[#322F51] flex flex-col justify-end items-center  pl-[10.27%] pr-[10.27%] relative">
      <img
        src={header}
        alt=""
        className="absolute object-fill object-right-bottom w-full h-full "
      />
      <span className="font-bold text-white text-center text-3xl leading-8 bottom-28  absolute">
      Learn More About Renting a Car With WTi
      </span>
     
    </div>
  )
}

export default FaqHeading