import React, { useRef } from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import axios from "axios";
import { dateConvertForMUI, dateConvertForUser } from "./utils/commonFunction";
import TextField from "@mui/material/TextField";
import { Input } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import UploadIcon from '@mui/icons-material/Upload';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DocumentUpload from "./components/DocumentUpload";
import FinalInvoice from "./components/FinalInvoice";
import { TimeToLeave } from "@mui/icons-material";
import CarEditGrid from "./components/CarEditGrid";
import { useDispatch } from "react-redux";
import { adminUserSave } from "../../utils/loginSlices/loginSlice";
import { useNavigate } from "react-router-dom";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CreateBooking from "./components/CreateBooking";
import { baseUrl } from "../../config";

const options = [
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const reservationStatusArr = [
  "Pending",
  "Enroute",
  "Completed",
  "Noshow",
  "Overdue",
  "Cancelled",
];

function AdminMain(props) {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [filterValue, setFilterValue] = React.useState("");
  const [reservationStatusState, setReservationStatusState] =
    React.useState("All");
  const [selectViewRow, setSelectView] = React.useState({});
  const [dropdown, setDropdown] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputSearch = useRef("");

  const filteredRows = React.useMemo(() => {
    return rows.filter((row) => {
      const reservationID = row.reservationID;
      const rowStatus = row.reservationStatus;

      // Check if the status filter is "All" or if the row matches the status filter
      const statusMatches =
        reservationStatusState === "All" ||
        rowStatus === reservationStatusState;

      // Check if the filterValue is empty or if the reservationID includes the filterValue
      const filterMatches =
        filterValue === "" ||
        reservationID.toLowerCase().includes(filterValue.toLowerCase());

      // Return the row if both status and filter conditions are met
      return statusMatches && filterMatches;
    });
  }, [filterValue, reservationStatusState, rows]);

  const handleSearch = () => {
    const data = inputSearch.current.value;
    setFilterValue(data);
  };

  const apiCall = async () => {
    try {
      let response = await axios.get(
        `${baseUrl}/app/v1/reservation/getReservations`
      );
      setRows(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    apiCall();
  }, [rowModesModel]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleViewTable = (id) => () => {
    const selectedRow = rows.find((row) => row._id === id);
    setSelectView(selectedRow);
    props.setModal(true);
  };

  const handleCloseModal = () => {
    props.setModal(false);
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow };
    try {
      let response = await axios.put(
        `${baseUrl}/app/v1/reservation/updateReservation`,
        updatedRow
      );
      if (response.data.reservationUpdated) {
        setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
        return updatedRow;
      } else {
        alert("There was an error while updating the Booking!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleReservationStatus = (value) => {
    setReservationStatusState(value);
  };

  function getDaysInBetween(startDateStr, endDateStr){
    // Parse the start and end dates from their string representations
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;

    // Calculate the difference in days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Round to the nearest whole number to get the number of days
    return Math.round(daysDifference);
  }

  const columns = [
    { field: "reservationID", headerName: "Reservation ID", width: 160 },
    {
      field: "pickupLocation",
      headerName: "Pick Up Location",
      width: 170,
      editable: true,
      valueGetter: (params) => params.row.travelItenary.pickupLocation,
      valueSetter: (params) => {
        params.row.travelItenary.pickupLocation = params.value;
        return params.row;
      },
    },
    {
      field: "dropLocation",
      headerName: "Drop Location",
      width: 170,
      editable: true,
      valueGetter: (params) => params.row.travelItenary.dropLocation,
      valueSetter: (params) => {
        params.row.travelItenary.dropLocation = params.value;
        return params.row;
      },
    },
    {
      field: "pickupDate",
      headerName: "Pick Date",
      type: "date",
      width: 120,
      editable: true,
      valueFormatter: (params) => {
        // Convert the date value to the desired format (e.g., Oct,05,2023)
        const date = new Date(params.value);
        const options = { year: "numeric", month: "short", day: "2-digit" };
        return date.toLocaleDateString("en-US", options);
      },
      valueGetter: (params) =>
        dateConvertForMUI(params.row.travelItenary.pickupDate),
      valueSetter: (params) => {
        params.row.travelItenary.pickupDate = dateConvertForUser(params.value);
        return params.row;
      },
    },
    {
      field: "dropDate",
      headerName: "Drop Date",
      type: "date",
      width: 120,
      editable: true,
      valueFormatter: (params) => {
        // Convert the date value to the desired format (e.g., Oct,05,2023)
        const date = new Date(params.value);
        const options = { year: "numeric", month: "short", day: "2-digit" };
        return date.toLocaleDateString("en-US", options);
      },
      valueGetter: (params) =>
        dateConvertForMUI(params.row.travelItenary.dropDate),
      valueSetter: (params) => {
        params.row.travelItenary.dropDate = dateConvertForUser(params.value);
        return params.row;
      },
    },
    {
      field: "pickupTime",
      headerName: "Pick Up Time",
      width: 90,
      editable: true,
      type: "singleSelect",
      valueOptions: options,
      valueGetter: (params) => params.row.travelItenary.pickupTime,
      valueSetter: (params) => {
        params.row.travelItenary.pickupTime = params.value;
        return params.row;
      },
    },
    {
      field: "dropTime",
      headerName: "Drop Time",
      width: 90,
      editable: true,
      type: "singleSelect",
      valueOptions: options,
      valueGetter: (params) => params.row.travelItenary.dropTime,
      valueSetter: (params) => {
        params.row.travelItenary.dropTime = params.value;
        return params.row;
      },
    },
    {
      field: "reservationStatus",
      headerName: "Reservation Status",
      width: 100,
      type: "singleSelect",
      valueOptions: reservationStatusArr,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            onClick={handleViewTable(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleLogout = () => {
    dispatch(adminUserSave(''));
    navigate('/login/admin');
  }
  return (
    <>
      <div className={`w-full ${props.modal ? " overflow-hidden" : ""}`}>
        <div className="w-full flex items-center justify-between px-[5.27%] py-12 bg-[#0f0a34]">
          <div className="text-3xl font-bold text-white">Admin Page</div>
          <div className="flex justify-between w-[40%]">
          <div className="rounded w-[90%]">
            <Input
              placeholder="Search"
              className="bg-white w-full rounded-lg border-none focus-visible:border-none p-2"
              inputRef={inputSearch}
              onChange={handleSearch}
            />
          </div>
          <div onClick={handleLogout} className="text-white underline cursor-pointer w-[5%] flex items-center justify-center">
          Logout
          </div>
          </div>
        </div>
        <div className="flex max-sm:block bg-[#0f0a34]">
          <div className="w-[15%] max-sm:flex px-4 pb-4 text-white ">
          <div
              onClick={() => {
                handleReservationStatus("create");
            }}
              className={`mb-2 flex justify-between items-center text-sm text-center cursor-pointer p-2 rounded-xl hover:shadow-2xl hover:bg-[#edf4fb] hover:text-black ${
                reservationStatusState == "create"
                  ? "shadow-2xl bg-[#edf4fb] text-black"
                  : ""
              }`}
            >
              Create Booking <span><NoteAddIcon /></span>
            </div>
            <div
              onClick={() => {
                handleReservationStatus("All");
                setDropdown(!dropdown);
            }}
              className={`mb-2 flex justify-between items-center text-sm text-center cursor-pointer p-2 rounded-xl hover:shadow-2xl hover:bg-[#edf4fb] hover:text-black ${
                (reservationStatusState != "document" && reservationStatusState != "invoice" && reservationStatusState != "cargrid" && reservationStatusState != "create")
                  ? "shadow-2xl bg-[#edf4fb] text-black"
                  : ""
              }`}
            >
              Bookings <span onClick={() => setDropdown(!dropdown)}>{dropdown ?  <RemoveIcon /> : <AddIcon />}</span>
            </div>
            {
              dropdown ? 
            reservationStatusArr.map((item) => (
              <div
                onClick={() => handleReservationStatus("" + item + "")}
                className={`my-2 ml-3 text-center text-sm cursor-pointer p-2 rounded-xl hover:shadow-2xl hover:bg-[#edf4fb] hover:text-black ${
                  reservationStatusState == item
                    ? "shadow-2xl bg-[#edf4fb] text-black"
                    : ""
                }`}
              >
                {item}
              </div>
            )) : <></>
            }
            {/* <div
              onClick={() => {
                handleReservationStatus("document");
            }}
              className={`mb-2 flex justify-between items-center text-sm text-center cursor-pointer p-2 rounded-xl hover:shadow-2xl hover:bg-[#edf4fb] hover:text-black ${
                reservationStatusState == "document"
                  ? "shadow-2xl bg-[#edf4fb] text-black"
                  : ""
              }`}
            >
              Document Upload <span><UploadIcon /></span>
            </div> */}
            <div
              onClick={() => {
                handleReservationStatus("invoice");
            }}
              className={`mb-2 flex justify-between items-center text-sm text-center cursor-pointer p-2 rounded-xl hover:shadow-2xl hover:bg-[#edf4fb] hover:text-black ${
                reservationStatusState == "invoice"
                  ? "shadow-2xl bg-[#edf4fb] text-black"
                  : ""
              }`}
            >
              Final Invoice <span><ReceiptIcon /></span>
            </div>
            <div
              onClick={() => {
                handleReservationStatus("cargrid");
            }}
              className={`mb-2 flex justify-between items-center text-sm text-center cursor-pointer p-2 rounded-xl hover:shadow-2xl hover:bg-[#edf4fb] hover:text-black ${
                reservationStatusState == "cargrid"
                  ? "shadow-2xl bg-[#edf4fb] text-black"
                  : ""
              }`}
            >
              Cars Edit <span><TimeToLeave /></span>
            </div>
          </div>
          <div className="w-[85%] h-[450px] overflow-y-auto rounded-lg p-4 mr-2 mb-4 bg-white">
            {
              (reservationStatusState != "document" && reservationStatusState != "invoice" && reservationStatusState != "cargrid" && reservationStatusState != "create") ? 
              <Box
              sx={{
                height: 400,
                width: "100%",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
                borderRadius: 10,
              }}
            >
              <DataGrid
                rows={filteredRows}
                columns={columns}
                getRowId={(row) => row._id}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(error) =>
                  console.log(error + "  rowUpdateError")
                }
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 10, 20]}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
               : 
               reservationStatusState == "document" ? <><DocumentUpload /></> : reservationStatusState == "cargrid" ? <><CarEditGrid  /></> : reservationStatusState == "invoice" ? <><FinalInvoice /></> : <><CreateBooking /></>
            }
            
          </div>
        </div>
      </div>
      {props.modal ? (
        <div className="modal">
          <div className="absolute bg-slate-500 opacity-50 top-0 left-0 w-full h-screen">
            
          </div>
          <div className="absolute top-0 h-screen flex flex-col">
            <div className="flex justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-[60%] my-auto mx-auto ">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                    <h3 className="text-2xl font=semibold">View Booking</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => handleCloseModal()}
                    >
                      <CloseIcon className="text-white text-lg bg-slate-600 rounded-full" />
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    {/* start  */}
                    <div className="w-full mt-2 block h-[400px] overflow-y-auto">
                      <div className="w-full">
                        <div className="bg-[#EBEBEB] w-full p-4  rounded mb-4 flex justify-between">
                          <div>
                            <div className="font-bold text-black text-base">
                              Pick-Up
                            </div>
                            <div className="text-[#2F2F2F] mt-1 text-sm font-normal">
                              {selectViewRow?.travelItenary?.pickupLocation}
                            </div>
                            <div className="text-[#092C85] mt-1 text-sm font-semibold">
                              {selectViewRow?.travelItenary?.pickupDate}
                            </div>
                          </div>
                          <div className="p-4">
                            <div className="text-center text-xs mb-1 inline-flex justify-center items-center w-full">
                              <span className="max-sm:block">-----</span>
                              {getDaysInBetween(selectViewRow?.travelItenary?.pickupDate,selectViewRow?.travelItenary?.dropDate) + 1} Days
                              <span className="max-sm:block">-----</span>
                            </div>
                          </div>
                          <div>
                            <div className="font-bold text-black">Drop-off</div>
                            <div className="text-[#2F2F2F] mt-1 text-sm font-normal">
                              {selectViewRow?.travelItenary?.dropLocation}
                          </div>
                            <div className="text-[#092C85] mt-1 text-sm font-semibold">
                              {selectViewRow?.travelItenary?.dropDate}
                            </div>
                          </div>
                        </div>
                        <div className="bg-white w-full pt-4 pl-4 pr-4  rounded block border-2 border-[#EBEBEB]">
                          <div className="flex">
                            <div className="w-full flex items-center">
                              <img
                                src={(selectViewRow?.car?.src) ? process.env.PUBLIC_URL +
                                  "/images/" + selectViewRow?.car?.src.split(" ").join("") + ".png" : ""}
                                alt=""
                                onError={(e) => {
                                  e.target.src = "../static/staticcarimage.png";
                                }}
                                className="object-cover"
                              />
                            </div>
                            <div className="w-full py-2 pb-4">
                              <div className="font-bold text-base">
                                {selectViewRow?.car?.type}
                              </div>
                              <div className="mt-1 text-sm">{selectViewRow?.car?.title}</div>
                              
                            </div>
                          </div>
                        </div>
                        {selectViewRow.extras.length > 0 ? 
                        <>
                        <div className="text-2xl font-bold mt-4 mb-4">
                          Extras 
                        </div>
                        {selectViewRow.extras.map((item, index) => (
                          <>
                            <div className="bg-[#EBEBEB] w-full p-4  rounded mb-4 flex justify-between">
                          <div>
                            {index + 1}{". "}{item.name}
                          </div>
                        </div>
                          </>
                        ))
                        }
                        </> 
                        : 
                        <></>}
                      </div>
                    </div>
                    {/* end  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AdminMain;
