import React from "react";
import PopularBrandsSlider from "./PopularBrandsSlider";

const PopularBrandsSliderWrapper = () => {
  return (
    <>
      <div className="my-10">
        <div className="flex flex-col">
          <h2 className="text-[30px] md:text-[34px] font-bold my-5 text-center text-[#0F0A34]">
            Popular Brands
          </h2>
        </div>
        <PopularBrandsSlider />
      </div>
    </>
  );
};

export default PopularBrandsSliderWrapper;
