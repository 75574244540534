import React from "react";
import PAndCCardNew from "./PAndCCardNew";

function PAndC({vehicleObject}) {

  return (
    <React.Fragment>
      <div className="w-full pt-4">
        <span className="font-semibold leading-5">Protection & Coverages</span>
        <div className="grid grid-cols-2 gap-4 mt-2 max-sm:grid-cols-1">
                    <PAndCCardNew
                      vehicleObject={vehicleObject}
                      // equipmentsObj={ele}
                    />
        </div>
      </div>
    </React.Fragment>
  );
}

export default PAndC;
