import React, { useEffect, useRef, useState } from 'react';
import DropdownImg from "../../images/arrow_drop_down_img.png"

const StatusDropDown = ({selectedStatus, setSelectedStatus}) => {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const dropDownOpenRef = useRef()


    const filterArr = [
        {
            text: "All",
            value: "All"
        },
        {
            text: "Pending",
            value: "Pending"
        },
        {
            text: "Enroute",
            value: "Enroute"
        },
        {
            text: "No Show",
            value: "NoShow"
        },
        {
            text: "Cancelled",
            value: "Cancelled"
        },
        {
            text: "Overdue",
            value: "Overdue"
        },
        {
            text: "Completed",
            value: "Completed"
        },
    ]

    const clickOutSideDropDown = (event) => {
        if (dropDownOpenRef.current && !dropDownOpenRef.current.contains(event.target)) {
            setIsDropDownOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", clickOutSideDropDown, true)

        return () => {
            document.removeEventListener("click", clickOutSideDropDown, false)
        }
    }, [])



    return (
        <div className='md:w-10/12 w-full'>
            <div className='flex flex-col gap-1 w-full relative items-center'>
                <div className='flex items-center py-1 px-3 justify-between border border-[#C2C0C0] rounded-[8px] hover:cursor-pointer w-full' onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
                    <span className='text-[#7E7E7E] text-[18px] font-normal leading-[20px] '>{selectedStatus ? selectedStatus : "Select"}</span>
                    <img src={DropdownImg} alt="" />
                </div>
                <div className='w-full'>
                    {
                        isDropDownOpen && (
                            <div className='flex flex-col h-[220px] w-full left-0 absolute top-[56px] overflow-y-scroll items-center bg-[#fff] py-2 ustify-between border border-[#C2C0C0] rounded-[8px] hover:cursor-pointer z-10' ref={dropDownOpenRef}>
                                {
                                    filterArr.map((status, index) => {
                                        return (

                                            <div className='block w-full py-2 px-3 hover:bg-[#f7f7f7] bg-[#ffffff] h-[40px]' onClick={() => {
                                                setSelectedStatus(status.value)
                                                setIsDropDownOpen(false)
                                            }} key={index}>
                                                <span className='text-[14px] text-[#1a1a1a] font-medium'>{status.text}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default StatusDropDown
