import React from "react";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addDepositPremiumCheck,
  addFullDepositCheck,
  removeDepositPremiumCheck,
  removefullDepositCheck,
} from "../../utils/invoice/invoiceSlice";
import { addModifyExcessInsurance, addModifyExtra, addModifyExtras, removeModifyExtra, removeModifyExtras, toggleAdditionalDriver, toggleBabySeat } from "../../utils/modifyBookingSlices/modifyBooking";

function EquipmentCard(props) {
  const {
    equipmentsObj,
    sectionType,
    indexValue,
    babySeatCheck,
    setBabySeatCheck,
    additionalDriverCheck,
    setAdditionalDriverCheck,
    currentDepositPremiumState,
    setDepositPremiumState,
    currentFullyDepositState,
    setFullyDepositState,
  } = props;


  const dispatch = useDispatch();
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const modifyReservationDays = useSelector(
    (store) => store.modifyBookingSlice.modifyReservationDays
  );


  useEffect(() => {
    if(babySeatCheck){
      const babySeatPrice = calculatePrice(2)
      
      const babySeatObj = {
        babySeatPrice: Number(Number(babySeatPrice).toFixed(2)),
        babySeatState: true
      }
  
      dispatch(toggleBabySeat(babySeatObj));
  
    }
    
    if(additionalDriverCheck){
      const additionalDriverPrice = calculatePrice(3)
      const additionalDriverObj = {
        additionalDriverPrice: Number(Number(additionalDriverPrice).toFixed(2)),
        additionalDriverState: true,
      }
      dispatch(toggleAdditionalDriver(additionalDriverObj));
    }

  }, [modifyReservationDays])




  
  const returnPerfectPrice = () => {

    const days = modifyReservationDays;
    const calculatedPriceExtra = Number(equipmentsObj?.price.daily).toFixed(2);

    if (
      calculatedPriceExtra * days >=
        equipmentsObj?.price.maximum &&
      equipmentsObj?.title !== "Additional Driver"
    ) {




      return `${equipmentsObj?.price.maximum}`;
    } else if (equipmentsObj?.title === "Additional Driver") {
      return `${calculatedPriceExtra}`;
    } else {
      return `${calculatedPriceExtra * days}`;
    }
  };

  const calculatePrice = (indexValue)=>{

    const days = modifyReservationDays;

    if(indexValue == 2){
      if(days>=7 && days<=21){
        let actualPrice = equipmentsObj?.price.daily-10;
        let totalPrice = actualPrice*days;
        if (totalPrice <= equipmentsObj?.price.maximum) {
          return Number(totalPrice.toFixed(2));
        } else {
          return Number((equipmentsObj?.price.maximum).toFixed(2));
        }
        
      }else if (days>=30){
        let totalMonths = Math.floor(days / 30); // Extract whole months
        let remainingDays = days % 30; 
        let monthsPrice = Number((equipmentsObj?.price.maximum).toFixed(2))*totalMonths;
        let daysPrice = remainingDays*(equipmentsObj?.price.daily-10);
        let total = monthsPrice+daysPrice
        return Number((total).toFixed(2));
      } else {
        if(days>21 && days<30){
        return Number((equipmentsObj?.price.maximum).toFixed(2));
        }else{
          let totalPrice = equipmentsObj?.price.daily*days;
          return Number((totalPrice).toFixed(2));
        }      }
    }
    if(indexValue == 3){
      if(days>30 && days<61){
        let daysRemainigFromMonth = days-30;
        let remainingDaysRate = daysRemainigFromMonth*1.3;
        let finalPrice = remainingDaysRate+equipmentsObj?.price.daily;
        return Number((finalPrice).toFixed(2));
      }
      if(days>=30){
        let totalMonths = days/30;
        return (Number((equipmentsObj?.price.daily).toFixed(2))*totalMonths);
      }else{
        return Number((equipmentsObj?.price.daily).toFixed(2));
      }
    }

    if(indexValue == 0 || indexValue == 1){
      return Number((equipmentsObj?.price.daily).toFixed(2));
    }
  }


  const handleEquipment = (extraPrice) => {


    if (sectionType == "Deposit") {
      dispatch(addModifyExcessInsurance(equipmentsObj.price["daily"]));

      if (indexValue == 0) {
        dispatch(addFullDepositCheck());
        dispatch(removeDepositPremiumCheck());
      } else if (indexValue == 1) {
        dispatch(addDepositPremiumCheck());
        dispatch(removefullDepositCheck());
      }

    } else {
      extraPrice = parseFloat(extraPrice);

      let finalPrice = calculatePrice(indexValue)
      if (indexValue == 2) {
        setBabySeatCheck(!babySeatCheck);
        if (babySeatCheck == false && indexValue == 2) {

          const babySeatObj = {
            babySeatPrice: parseFloat(finalPrice).toFixed(2),
            babySeatState: true
          }

          dispatch(toggleBabySeat(babySeatObj));
          dispatch(addModifyExtras(equipmentsObj));
          // dispatch(addModifyExtra(insuranceData.equipmentsObj.id + "E"));
        } else if (babySeatCheck == true && indexValue == 2) {

          const babySeatObj = {
            babySeatPrice: 0,
            babySeatState: false
          }

          dispatch(toggleBabySeat(babySeatObj));
          dispatch(removeModifyExtras(equipmentsObj.id));
          // dispatch(removeModifyExtra(insuranceData.equipmentsObj.id + "E"));
        }
      } else {
        setAdditionalDriverCheck(!additionalDriverCheck);
        if (additionalDriverCheck == false && indexValue == 3) {

          const additionalDriverObj = {
            additionalDriverPrice: parseFloat(finalPrice).toFixed(2),
            additionalDriverState: true,
          }
          dispatch(toggleAdditionalDriver(additionalDriverObj));
          dispatch(addModifyExtras(equipmentsObj));
          // dispatch(addModifyExtra(insuranceData.equipmentsObj.id + "E"));
        } else if (additionalDriverCheck == true && indexValue == 3) {

          const additionalDriverObj = {
            additionalDriverPrice: 0,
            additionalDriverState: false,
          }
          dispatch(toggleAdditionalDriver(additionalDriverObj));
          dispatch(removeModifyExtras(equipmentsObj.id));
          // dispatch(removeModifyExtra(insuranceData.equipmentsObj.id + "E"));
        }
      }
    }
  };


  return (
    <React.Fragment>
      <div
      // removed class 
      // ${
      //   depositcheck == "Deposit"
      //     ? "border-white"
      //     : depositcheck == false
      //     ? "border-orange-500"
      //     : ""
      // }
      // ${babySeatCheck ? "border-orange-500" : additionalDriverCheck ? "border-orange-500" : ""}
      
        className={`shadow-xl 
          border rounded-md mb-4 cursor-pointer hover:border hover:border-black bg-white`}
      >
        <div className="icon flex">
          <div className="img flex justify-center items-center  pl-4 pr-4 ">
            <img
              src={require("./" + equipmentsObj?.img + ".svg")}
              alt=""
              className="border rounded-full max-w-sm "
            />
          </div>
          <div className="flex flex-col justify-center items-start pt-6  pb-2 pr-4 pl-4">
            <span className="font-semibold text-sm mb-2">
              {equipmentsObj?.title}
            </span>
            <span className="text-[12px] font-normal leading-4 text-[#6A6A6A]">
              {equipmentsObj?.description}
            </span>
          </div>
        </div>
        <div className="check flex pl-8 pb-2">
          <FormControlLabel
            // The first condition checks if calculatedPrice is greater than or equal to the maximum price (equipmentsObj?.price.maximum). If true, and the equipment title is not "Additional Driver," it returns a formatted string with the maximum price.

            // The second condition checks if the equipment title is "Additional Driver." If true, it returns a formatted string with the calculated price per day for an additional driver.

            // If neither of the above conditions is true, it falls into the else block and returns a formatted string with the calculated price per day for the equipment.

            label={
              <span className="text-sm font-semibold text-[#0077B6] leading-4">
                {(() => {
                  
                  if (indexValue === 2 || indexValue === 3 || indexValue == 0 || indexValue == 1) {
                    const calculatedPrice = calculatePrice(indexValue);
                    return `${currencyName} ${(calculatedPrice*currencyPrice).toFixed(2)} - ${modifyReservationDays} DAY`;
                  }
                })()}
              </span>
            }
            control={
              sectionType == "Deposit" ? (
                <Checkbox
                  checked={
                    indexValue == 0  ? currentFullyDepositState 
                    :
                     indexValue == 1 ? currentDepositPremiumState
                    :false
                  }
                  onChange={() => {
                    if (indexValue == 0) {
                      setDepositPremiumState(false);
                      setFullyDepositState(true);
                    } else {
                      setDepositPremiumState(true);
                      setFullyDepositState(false);
                    }

                    let selPrice = returnPerfectPrice();
                    handleEquipment(parseFloat(selPrice));
                  }}
                />
              ) : (
                <Checkbox
                  checked={
                    indexValue == 2
                      ? babySeatCheck
                      : indexValue == 3
                      ? additionalDriverCheck
                      : false
                  }
                  onChange={() => {
                    let selPrice = returnPerfectPrice(props);
                    handleEquipment(props, selPrice);
                  }}
                />
              )
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default EquipmentCard;
