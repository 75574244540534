import React, { useEffect, useState } from 'react'
import CarBookingStatus from './CarBookingStatus'
import BookedCarForSubscription from './BookedCarForSubscription'
import { useDispatch, useSelector } from 'react-redux';
import { switchOffLoader, switchOnLoader } from '../../utils/loaderSlice';
import { baseUrl } from '../../config';
import axios from 'axios';
import StatusDropDown from './StatusDropDown';

const CarBookingForSubscription = () => {

    const dispatch = useDispatch();
    const userID = useSelector((store) => store.userCookie.userId);
    const [profileUpcomingData, setProfileUpcomingData] = useState([]);
    const [filteredData, setFilteredData] = useState([])
    const [selectedBookingStatus, setSelectedBookingStatus] = useState("Select")
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("Select")


    useEffect(() => {

        if (profileUpcomingData.length) {
          if(selectedBookingStatus === "All"){
            setFilteredData(profileUpcomingData)
          } else {
            let result = profileUpcomingData?.filter((item) => {
              return (
                item[0]?.subscriptionStatus === selectedBookingStatus?.replaceAll(" ", "") ||
                item[1]?.subscriptionStatus === selectedBookingStatus?.replaceAll(" ", "")
              );
            });
            setFilteredData(result);
          }
        }
    
      }, [selectedBookingStatus]);
    
      useEffect(() => {
        if (profileUpcomingData.length) {
          if(selectedPaymentStatus === "All"){
            setFilteredData(profileUpcomingData)
          } else {
            let result = profileUpcomingData?.filter((item) => {
              return (
                item[0]?.payment === selectedPaymentStatus?.replaceAll(" ", "") ||
                item[1]?.payment === selectedPaymentStatus?.replaceAll(" ", "")
              );
            });
            setFilteredData(result);
          }
        }
    
      }, [selectedPaymentStatus]);

    useEffect(() => {
        dispatch(switchOnLoader());
        let data = JSON.stringify({
          userID: "US2986397905",
        //   userID: `${userID}`,
        });
    
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/subscription/getSubscriptionAndInvoice`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        axios
          .request(config)
          .then((response) => {

    
            if (response.data.result.length) {
                setFilteredData(response.data.result)
            }
            setProfileUpcomingData(response.data.result);
    
            // if(upComing.length){
    
            // const modifiedData = upComing.filter(arr => {
            //    return !arr.some(item => item.isModifiedFlag == true);
            //  });
             
            // }
    
    
            dispatch(switchOffLoader());
          })
          .catch((error) => {
            console.log('Error from modify subscription: ',error);
          });
      }, []);


    return (
        <div className="w-full xl:w-[1200px] mx-auto h-full" key={1}>
          {/* <CarBookingStatus /> */}
          <div className='grow-[2] w-full md:w-7/12 py-6'>
            <div className='flex flex-col items-start gap-8 w-full'>
                <div className='flex items-start gap-3 px-3'>
                    <button className='flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] shadow-[0px_3px_8px_0px_#00000033] py-3 px-6 rounded-[12px] '>
                        <span className='text-[#fff] text-[20px] font-medium tracking-[1px] leading-[20px]'>Bookings</span>
                    </button>
                    <button className='flex justify-start gap-2 items-center bg-[#fff] py-3 px-6 rounded-[12px]'>
                        <span className='text-[#1d1d1d] text-[20px] font-medium tracking-[1px] leading-[20px]'>Accounts</span>
                    </button>
                </div>
                <div className='flex md:flex-nowrap flex-wrap items-center gap-6 w-full px-3'>
                    <div className='flex flex-col gap-1 w-full'>
                        <h3 className='text-[#000000] text-[20px] font-normal leading-[32px]'>Booking Status :</h3>
                        <StatusDropDown selectedStatus={selectedBookingStatus} setSelectedStatus={setSelectedBookingStatus}/>
                    </div>
                    <div className='flex flex-col gap-1 w-full'>
                        <h3 className='text-[#000000] text-[20px] font-normal leading-[32px]'>Payment Status :</h3>
                        <StatusDropDown selectedStatus={selectedPaymentStatus} setSelectedStatus={setSelectedPaymentStatus}/>
                    </div>
                </div>
            </div>
        </div>
          {filteredData.length
            ? filteredData.map((ele, index) => (
                <BookedCarForSubscription subscriptionData={ele} index={index}/>
              ))
            : ""}
        </div>
    );
}

export default CarBookingForSubscription
