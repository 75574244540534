import React from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { toggleCancelModal } from "../../../utils/ModalSlice/modalToggleSlice";
import { useDispatch } from "react-redux";
import { fillSelectedCancelCard } from "../../../utils/ModalSlice/modalToggleSlice";
import axios from "axios";
import { switchOnLoader, switchOffLoader } from "../../../utils/loaderSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { baseUrl } from "../../../config";

function BookingCard(props) {

  const dispatch = useDispatch();

  const openCancelConfirmationModal = (resId) => {
    dispatch(fillSelectedCancelCard(resId));
    dispatch(toggleCancelModal());
  };

  //pending --> yellow
  //enroute --> blue
  //complete --> green
  //no show --> orange
  //cancelled --> red
  //overdue --> purple

  const printConfirmation = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(switchOnLoader());

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/user/printConfirmation/${props.profileData[0]?.reservationID}`,
      responseType: "blob",
    };

    let response = await axios.request(config);
    if (response) {
      //
      //   const content = response.headers['application/pdf'];
      // download(response.data, "Hello.pdf", content)
      window.open(URL.createObjectURL(response.data));
      dispatch(switchOffLoader());
    } else {
    }
  };

  return (
    <>
      <div className="mt-12 max-sm:mt-4 max-sm:pt-6 max-sm:pb-6 max-sm:border-b  max-sm:border-slate-500 relative ">
        <div
          className={` ml-4 w-52 top-[-1.5rem] max-sm:top-[5px] rounded-md h-12 border-slate-500 border-[1.5px] bg-white absolute flex justify-center items-center`}
        >
          <span className=" text-black font-semibold flex justify-start  w-full px-[1rem] items-center text-xl">
            <CheckCircleIcon className=" text-[#59B259] text-3xl mr-4" />
            Completed
          </span>
        </div>
        <div
          className={`flex border border-slate-300 rounded max-sm:block max-sm:w-full ${
            props?.profileData[0]?.finalInvoice == true
              ? "h-[24rem]"
              : "h-[21rem]"
          }  max-sm:h-auto`}
        >
          <div className="w-[60%] max-sm:w-full border-r border-slate-300 p-4 relative">
            <div className=" pt-4 max-sm:w-full max-sm:flex max-sm:justify-center ">
              {props?.profileData[0]?.finalInvoice == true ? (
                <span className="pl-4 font-semibold text-xl">
                  Final Receipt
                </span>
              ) : (
                <span className="pl-4 font-semibold text-xl">
                  Advance Receipt
                </span>
              )}
            </div>
            <div className="confirmation pt-2 pl-4 pr-4 max-sm:bg-[#EBEBEB] max-sm:pb-4 max-sm:text-center max-sm:rounded">
              <span>Confirmation number:</span>
              <br className="max-sm:block hidden" />
              <span className=" uppercase text-blue-700 ml-2 max-sm:ml-0">
                {props?.profileData[0]?.reservationID}
              </span>
            </div>
            <div className="pickUpDateAndDropDate mt-4">
              <div className="bg-white w-full pt-4 pl-4 pr-4  rounded flex justify-between mb-4 max-sm:block">
                <div>
                  <div className="font-bold text-black text-base">Pick-Up</div>
                  <div className="text-[#2F2F2F] mt-2 text-sm font-normal">
                    {props.profileData[0]?.travelItenary?.pickupLocation}
                  </div>
                  <div className="text-[#092C85] mt-2 text-sm font-semibold">
                    {props.profileData[0]?.travelItenary?.pickupDate +
                      ", " +
                      props.profileData[0]?.travelItenary?.pickupTime}
                  </div>
                </div>
                <div className="p-10 max-sm:w-full">
                  <div className="text-center text-xs mb-2 max-sm:inline-flex max-sm:w-full max-sm:justify-center">
                    <span className="hidden max-sm:block">-----</span>
                    {props.profileData[0]?.reservationDays} Days
                    <span className="hidden max-sm:block">-----</span>
                  </div>
                  <div className="w-[70px] border border-dashed relative max-sm:hidden">
                    <div className="absolute top-[-13px] right-[-6px]">
                      <ArrowForwardIosIcon className="text-[#555555]" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="font-bold text-black">Drop-off</div>
                  <div className="text-[#2F2F2F] mt-2 text-sm font-normal">
                    {props.profileData[0]?.travelItenary?.dropLocation}
                  </div>
                  <div className="text-[#092C85] mt-2 text-sm font-semibold">
                    {props.profileData[0]?.travelItenary?.dropDate +
                      ", " +
                      props.profileData[0]?.travelItenary?.dropTime}
                  </div>
                </div>
              </div>
            </div>
            <div className="carSelected">
              <div className="flex justify-between max-sm:block">
                <div className="w-[20%] max-sm:w-full flex items-center">
                  <img
                    src={
                      `${baseUrl}/app/v1/aws/getImage/carimages/` +
                      props.profileData[0]?.car?.src.split(" ").join("") +
                      ".png"
                    }
                    alt=""
                    className="object-cover max-sm:w-full max-sm:h-auto"
                    onError={(e) => {
                      e.target.src = "../static/staticcarimage.png";
                    }}
                  />
                </div>
                <div className="w-[75%] py-2 pb-4 max-sm:w-full">
                  <div className="font-bold text-base">
                    {props.profileData[0]?.car?.type}
                  </div>
                  <div className="mt-1 text-sm">
                    {props.profileData[0]?.car?.title}
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonNeedHelp absolute bottom-10 right-10 max-sm:static max-sm:flex max-sm:justify-end ">
              <div
                className={`w-fit px-[25px] py-[10px] flex justify-center items-center text-xs font-normal cursor-pointer border border-[#092C85] rounded mr-4 text-[#092C85] `}
              >
                Need Help?
              </div>
            </div>
          </div>
          <div className="w-[40%] max-sm:w-full p-4 relative ">
            <div className="bg-white h-auto pt-4 pl-4 pr-4 rounded w-[100%]">
              <div className="font-bold text-base">
                Payment method :{" "}
                {props.profileData[0]?.payType === "payNow"
                  ? "PAY NOW"
                  : "PAY LATER"}
              </div>
              <div className="text-sm mt-6">Included in the rates</div>
              <div className="flex justify-between text-xs mt-5">
                <div>Base Rate</div>
                <div>
                  {props.profileData[0]?.currencyInfo?.currency +
                    " " +
                    (
                      props.profileData[1]?.baseRate *
                      props.profileData[0]?.currencyInfo?.currencyRate
                    ).toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between text-xs mt-1">
                <div>No-Deposit Premium</div>
                <div>
                  {props.profileData[0]?.currencyInfo?.currency +
                    " " +
                    (
                      props.profileData[1]?.excessInsuranceCharge *
                      props.profileData[0]?.currencyInfo?.currencyRate
                    ).toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between text-xs mt-1">
                <div>Delivery charge</div>
                <div>
                  {props.profileData[0]?.currencyInfo?.currency +
                    " " +
                    (
                      props.profileData[1]?.deliveryCharge *
                      props.profileData[0]?.currencyInfo?.currencyRate
                    ).toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between text-xs mt-1">
                <div>Collection charge</div>
                <div>
                  {props.profileData[0]?.currencyInfo?.currency +
                    " " +
                    (
                      props.profileData[1]?.collectionCharge *
                      props.profileData[0]?.currencyInfo?.currencyRate
                    ).toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between mt-1 text-xs">
                <div>Extras</div>
                <div>
                  {props.profileData[0]?.currencyInfo?.currency +
                    " " +
                    (
                      props.profileData[1]?.addOnCharges *
                      props.profileData[0]?.currencyInfo?.currencyRate
                    ).toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between mt-1 text-xs">
                <div className="text-blue-400">Taxes & Fees</div>
                <div>
                  {props.profileData[0]?.currencyInfo?.currency +
                    " " +
                    (
                      props.profileData[1]?.taxes *
                      props.profileData[0]?.currencyInfo?.currencyRate
                    ).toFixed(2)}
                </div>
              </div>
              {props?.profileData[0]?.finalInvoice == true ? (
                <div className="flex justify-between mt-1 text-xs">
                  <div className="text-red-400" title="fines + saliks">
                    Additional Charges
                  </div>
                  <div>
                    {props.profileData[0]?.currencyInfo?.currency +
                      " " +
                      (
                        props.profileData[1]?.taxes *
                        props.profileData[0]?.currencyInfo?.currencyRate
                      ).toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="flex justify-between mt-1 font-bold text-xs">
                <div>Estimated Total</div>
                <div>
                  {props?.profileData[0]?.finalInvoice == true
                    ? props.profileData[0]?.currencyInfo?.currency +
                      " " +
                      (
                        props.profileData[1]?.totalCharges *
                        props.profileData[0]?.currencyInfo?.currencyRate
                      ).toFixed(2)
                    : props.profileData[0]?.currencyInfo?.currency +
                      " " +
                      (
                        props.profileData[1]?.totalCharges *
                        props.profileData[0]?.currencyInfo?.currencyRate
                      ).toFixed(2)}
                </div>
              </div>
              {props?.profileData[0]?.finalInvoice == true ? (
                <div
                  className="flex justify-between mt-1 font-bold text-xs"
                  title="Advance amount"
                >
                  <div>Total amount paid</div>
                  <div>
                    {props.profileData[0]?.currencyInfo?.currency +
                      " " +
                      (
                        props.profileData[1]?.amountPaid *
                        props.profileData[0]?.currencyInfo?.currencyRate
                      ).toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {props?.profileData[0]?.finalInvoice == true ? (
                <div
                  className="flex justify-between mt-1 font-bold text-xs"
                  title="Final amount - Advance amount"
                >
                  <div>Total amount payable</div>
                  <div>
                    {props.profileData[0]?.currencyInfo?.currency +
                      " " +
                      (
                        props.profileData[1]?.amountDue *
                        props.profileData[0]?.currencyInfo?.currencyRate
                      ).toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="absolute my-4 flex w-full justify-around px-4 max-sm:static max-sm:mt-6 ">
              <div
                // onClick={handleCancelReservation}
                className={` px-[25px] py-[10px] max-sm:px-3 max-sm:py-1 flex justify-center items-center text-xs font-normal cursor-pointer border border-[#092C85] rounded bg-[#092C85] text-white max-sm:text-center`}
              >
                <span
                  className="ml-2 font-normal  max-sm:ml-0"
                  onClick={() => printConfirmation()}
                >
                  Print Confirmation
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingCard;
