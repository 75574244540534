import React, { useState } from 'react'
import FilterIcon from "../../images/filter_icon.svg"
import "./index.css"
import StatusDropDown from './StatusDropDown'

const CarBookingStatus = () => {
    const [selectedStatusTab, setSelectedStatusTab] = useState(0)
    
    const [selectedBookingStatus, setSelectedBookingStatus] = useState("Select")
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("Select")


    const filterArr = [
        {
            text: "All",
            value: "all"
        },
        {
            text: "Pending",
            value: "pending"
        },
        {
            text: "In Progress",
            value: "in progress"
        },
        {
            text: "No Show",
            value: "no show"
        },
        {
            text: "Cancelled",
            value: "cancelled"
        },
        {
            text: "Overdue",
            value: "overdue"
        },
    ]


    return (
        <div className='grow-[2] w-full md:w-7/12 py-6'>
            <div className='flex flex-col items-start gap-8 w-full'>
                <div className='flex items-start gap-3 px-3'>
                    <button className='flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] shadow-[0px_3px_8px_0px_#00000033] py-3 px-6 rounded-[12px] '>
                        <span className='text-[#fff] text-[20px] font-medium tracking-[1px] leading-[20px]'>Bookings</span>
                    </button>
                    <button className='flex justify-start gap-2 items-center bg-[#fff] py-3 px-6 rounded-[12px]'>
                        <span className='text-[#1d1d1d] text-[20px] font-medium tracking-[1px] leading-[20px]'>Accounts</span>
                    </button>
                </div>
                <div className='flex md:flex-nowrap flex-wrap items-center gap-6 w-full px-3'>
                    <div className='flex flex-col gap-1 w-full'>
                        <h3 className='text-[#000000] text-[20px] font-normal leading-[32px]'>Booking Status :</h3>
                        <StatusDropDown selectedStatus={selectedBookingStatus} setSelectedStatus={setSelectedBookingStatus}/>
                    </div>
                    <div className='flex flex-col gap-1 w-full'>
                        <h3 className='text-[#000000] text-[20px] font-normal leading-[32px]'>Payment Status :</h3>
                        <StatusDropDown selectedStatus={selectedPaymentStatus} setSelectedStatus={setSelectedPaymentStatus}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarBookingStatus
