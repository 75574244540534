import React, { useEffect, useState } from "react";
import EquipmentCard from "./EquipmentCardNew";
import { useSelector } from "react-redux";
import axios from "axios";
import { switchOnLoader, switchOffLoader } from "../../utils/loaderSlice";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../config";
import { toggleAdditionalDriver, toggleBabySeat } from "../../utils/modifyBookingSlices/modifyBooking";

function EquipmentCarCont({extras}) {
  const dispatch = useDispatch();
  const additionalDriverChecked = useSelector((store) => store.modifyBookingSlice.additionalDriverCheck)
  const modifyBookingObj = useSelector((store) => store.modifyBookingSlice.modifyBookingObj)
  const babySeatChecked = useSelector((store) => store.modifyBookingSlice.babySeatCheck)
  const [equipmentObject, setEquipmentObject] = useState([]);
  const [babySeatCheck, setBabySeatCheck] = useState(false);
  const [additionalDriverCheck, setAdditionalDriverCheck] = useState(false);


  useEffect(() => {
      if(additionalDriverChecked){
        setAdditionalDriverCheck(true);
      } 
      if(babySeatChecked){
        setBabySeatCheck(true);
      }
  }, []);

  useEffect(() => {
    if(extras.length){
      dispatch(switchOffLoader());
      setEquipmentObject(extras);
    }
  }, [extras?.length]);

  return (
    <React.Fragment>
      <div className="w-full pt-4">
        <span className="font-semibold leading-5">Equipments</span>
        <div className="grid grid-cols-2 gap-4 mt-2 max-sm:grid-cols-1">

          {equipmentObject.length ? (
            <>
              <EquipmentCard
                equipmentsObj={equipmentObject[0]}
                sectionType={"Equipments"}
                babySeatCheck={babySeatCheck}
                setBabySeatCheck={setBabySeatCheck}
                indexValue={2}
              />
              <EquipmentCard
                equipmentsObj={equipmentObject[1]}
                sectionType={"Equipments"}
                additionalDriverCheck={additionalDriverCheck}
                setAdditionalDriverCheck={setAdditionalDriverCheck}
                indexValue={3}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default EquipmentCarCont;
