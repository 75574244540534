import React from "react";

import EventIcon from "@mui/icons-material/Event";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import format from "date-fns/format";

import { useDispatch } from "react-redux";
import { toggleCalender } from "../../../utils/BookingPageSlices/formControlSlice";
import { closeCalender } from "../../../utils/BookingPageSlices/formControlSlice";


function DropDateTime(props) {


  const dispatch = useDispatch();
  const handleCalenderModal = ()=>{
    dispatch(toggleCalender());
  }

  const closeCal = ()=>{
    if(!props.dropStatus){
      dispatch(closeCalender());
    }
    
  }
  const handlePickDropToggle = ()=>{
    props.togglePick(false);
    props.toggleDrop(true);
  }
  return (
    <React.Fragment>
           <div className="w-fit h-fit mr-4 flex max-sm:w-full max-sm:justify-between">
       <div className="mr-4 max-sm:w-[60%]">
       <div className="flex justify-start items-center mb-2">
          <span className="text-xs font-thin leading-4">Drop Date</span>
        </div>
        <div className="removeBack flex" onClick={handleCalenderModal}>
          
          <div className="w-[122px] h-[51px] max-sm:w-full bg-[#ebebeb] rounded flex justify-center max-sm:justify-start max-sm:p-2 items-center " onClick={handlePickDropToggle}>
            <EventIcon className="fill-[#8495C2] mr-1" />
            <span className="text-sm font-medium text-black">{format(props.endDate, "MMM,dd,yyyy")}</span>
          </div>
        </div>
       </div>
       <div className="max-sm:w-[40%]">
       <div className="flex justify-start items-center mb-2">
          <span className="text-xs font-thin leading-4">Drop Time</span>
        </div>
        <div className="removeBack flex">
        
          <div className="w-[100px] h-[51px] max-sm:w-full bg-[#ebebeb] rounded flex justify-start items-center p-2" onClick={closeCal}>
            <QueryBuilderIcon className="fill-[#8495C2] mr-2" />
            <select name="" className="bg-[#ebebeb] w-[70%] text-black" id="" onChange={(e)=>props.setTimePickDrop(e.target.value)}>
            {props.options.map((option) => (
              <option value={option.value} selected={option.value=="10:00"}>{option.label}</option>
            ))}
            </select>
          </div>
        </div>
       </div>
      </div>
    </React.Fragment>
  );
}
export default DropDateTime;