import React from "react";
import VerticalSliderOne from "./VerticalSliderOne";
import { firstQuestionList, secondQuestionList } from "./faqData";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeWords } from "../../utils/utilityFunctions/capitalizeFirstLetter";
import { replaceKeywordInArray } from "../../utils/utilityFunctions/replaceKeywordInArray";

function FaqForm() {
  const { city } = useParams();
  let capitalizedCity = "";
  let location = "";
  if (city) {
    location = city?.replaceAll("-", " ");
    capitalizedCity = capitalizeWords(location);
  } else {
    capitalizedCity = 'the UAE'
  }

// Modify the array with the dynamic value
const modifiedFirstQuestionList = replaceKeywordInArray(firstQuestionList, capitalizedCity);
const modifiedSecondQuestionList = replaceKeywordInArray(secondQuestionList, capitalizedCity);

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className=" w-[100%]  rounded-md  p-4">
        <h3 className="font-bold text-xl py-6 w-full flex justify-center items-center text-[#0F0A34] ">
          Frequently Asked Questions
        </h3>
        <div className="w-full flex justify-between items-start max-sm:block">
          <div className="w-[48%] max-sm:w-full">
            {modifiedFirstQuestionList.map((ele, i) => (
              <VerticalSliderOne faqObj={ele} key={i}/>
            ))}
          </div>
          <div className="w-[48%] max-sm:w-full">
            {modifiedSecondQuestionList.map((ele, i) => (
              <VerticalSliderOne faqObj={ele} key={i}/>
            ))}
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center py-6">
          <span className=" pb-4 w-full flex justify-center items-center text-[#0F0A34] ">
            Know more about renting a car with WTi Car Rental
          </span>
          <span
            className="font-normal font-md text-lg  lg:w-4/12 md:w-6/12 flex justify-center items-center text-[#0F0A34]  cursor-pointer border border-[#0F0A34] rounded-full px-1 py-1  hover:bg-[#0F0A34] hover:text-white max-sm:w-[100%]"
            onClick={() => navigate("/faqs")}
          >
            View All FAQs
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FaqForm;
