import React, { useEffect } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import InputsContainer from "../../bookingPanel/InputsContainer";
import RadioBtnCont from "../../bookingPanel/RadioBtnCont";
import { toggleUpdateItinerary } from "../../../utils/ModalSlice/modalToggleSlice";
import { getCarApiCall } from "../../getCarsApi";


function ModifyItinerary(props) {
 

  const dispatch = useDispatch();
  const resType = useSelector(
    (store) => store.formControlSlice.reservationType
  );

  const closeItiModal = () => {
    dispatch(toggleUpdateItinerary());
    document.documentElement.style.overflow = "visible";
  };
  return (
    <>
      <div className="absolute top-0 left-0 h-screen w-full flex justify-center transition ease-in-out delay-150">
        <div className="absolute w-full left-0 h-full bg-slate-800 opacity-60 z-10"></div>
        <div className="border mt-20 h-fit w-[90%] bg-white p-6 rounded-xl z-30">
          <div className="flex justify-between">
            <div className="text-lg font-bold ">Modify Itinerary</div>
            <div
              className="p-1 bg-slate-300 flex justify-center items-center rounded-full cursor-pointer"
              onClick={closeItiModal}
            >
              <CloseOutlined />
            </div>
          </div>
          <div className="w-full text-black">
            <InputsContainer />
            {resType == 0 ? (
              <RadioBtnCont />
            ) : (
              <>
                <div className=" invisible">
                  <RadioBtnCont />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ModifyItinerary;
